import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { getToken } from "../auth/selectors";
import {
  getCompanies,
  createCompany,
  updateCompany,
  deleteCompany,
  changeStatusCompany,
} from "./actions";
import { getFormCompanies } from "./selectors";
const { REACT_APP_API_BASE_URL } = process.env;

function* getCompaniesSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(getCompanies.request());
    const response = yield call(
      callApiAxios,
      "GET",
      "companies",
      tokenAccess,
      {},
      {}
    );
    yield put(getCompanies.success(response));
  } catch (e) {
    yield put(
      getCompanies.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getCompanies.fulfill());
  }
}

function* createCompanySaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(createCompany.request());
    const form = yield select(getFormCompanies);
    const body = {
      documentType: form.documentType,
      document: form.document,
      name: form.name,
      description: form.description,
      phone: form.phone,
      cellPhone: form.cellPhone,
      cityId: form.cityId,
      address: form.address,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      "companies",
      tokenAccess,
      {},
      body
    );

    let hasNewImages = form.files.length > 0;
    const data = new FormData();
    for (let index = 0; index < form.files.length; index++) {
      const file = form.files[index];
      data.append("files[]", file, file["name"]);
    }
    if (hasNewImages) {
      const responseImages = yield call(
        fetch,
        `${REACT_APP_API_BASE_URL}/api/companies/${response.data.id}/setImagesCompany`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${tokenAccess}`,
          },
          body: data,
        }
      );
    }

    yield put(createCompany.success(response));
  } catch (e) {
    yield put(
      createCompany.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(createCompany.fulfill());
  }
}

function* updateCompanySaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(updateCompany.request());
    const form = yield select(getFormCompanies);
    const body = {
      id: form.id,
      documentType: form.documentType,
      document: form.document,
      name: form.name,
      description: form.description,
      phone: form.phone,
      cellPhone: form.cellPhone,
      cityId: form.cityId,
      address: form.address,
    };
    const response = yield call(
      callApiAxios,
      "PUT",
      `companies/${body.id}`,
      tokenAccess,
      {},
      body
    );

    let hasNewImages = form.files.length > 0;
    const data = new FormData();
    for (let index = 0; index < form.files.length; index++) {
      const file = form.files[index];
      data.append("files[]", file, file["name"]);
    }
    if (hasNewImages) {
      const responseImages = yield call(
        fetch,
        `${REACT_APP_API_BASE_URL}/api/companies/${form.id}/setImagesCompany`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${tokenAccess}`,
          },
          body: data,
        }
      );
    }

    yield put(updateCompany.success(response));
  } catch (e) {
    yield put(
      updateCompany.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(updateCompany.fulfill());
  }
}

function* deleteCompanySaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(deleteCompany.request());
    const response = yield call(
      callApiAxios,
      "DELETE",
      `companies/${payload.id}`,
      tokenAccess,
      {},
      {}
    );
    yield put(deleteCompany.success(response));
  } catch (e) {
    yield put(
      deleteCompany.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(deleteCompany.fulfill());
  }
}

function* changeStatusCompanySaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(changeStatusCompany.request());
    const response = yield call(
      callApiAxios,
      "PATCH",
      `companies/${payload.id}/changeStatus/${payload.path}`,
      tokenAccess,
      {},
      {}
    );
    yield put(changeStatusCompany.success(response));
  } catch (e) {
    yield put(
      changeStatusCompany.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(changeStatusCompany.fulfill());
  }
}

export default function* companiesSaga() {
  yield takeEvery(getCompanies.TRIGGER, getCompaniesSaga);
  yield takeEvery(createCompany.TRIGGER, createCompanySaga);
  yield takeEvery(updateCompany.TRIGGER, updateCompanySaga);
  yield takeEvery(deleteCompany.TRIGGER, deleteCompanySaga);
  yield takeEvery(changeStatusCompany.TRIGGER, changeStatusCompanySaga);
  yield takeEvery(createCompany.SUCCESS, getCompaniesSaga);
  yield takeEvery(updateCompany.SUCCESS, getCompaniesSaga);
  yield takeEvery(deleteCompany.SUCCESS, getCompaniesSaga);
  yield takeEvery(changeStatusCompany.SUCCESS, getCompaniesSaga);
}
