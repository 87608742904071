import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { getToken } from "../auth/selectors";
import { setInitialImages, getInitialImages } from "./actions";
import { getFormSetup } from "./selectors";
const { REACT_APP_API_BASE_URL } = process.env;

function* getInitialImagesSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(getInitialImages.request());
    const response = yield call(
      callApiAxios,
      "GET",
      "files/getInitialImages",
      {},
      {},
      {}
    );
    yield put(getInitialImages.success(response));
  } catch (e) {
    yield put(
      getInitialImages.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getInitialImages.fulfill());
  }
}
function* setInitialImagesSaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(setInitialImages.request());
    let hasNewImages = payload.newImages.length > 0;
    let hasRemoveImages = payload.removeImages.length > 0;
    const data = new FormData();
    for (let index = 0; index < payload.newImages.length; index++) {
      const file = payload.newImages[index];
      data.append("files[]", file, file["name"]);
    }
    if (hasNewImages) {
      const responseImages = yield call(
        fetch,
        `${REACT_APP_API_BASE_URL}/api/files/setInitialImages`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${tokenAccess}`,
          },
          body: data,
        }
      );
    }
    if (hasRemoveImages) {
      const body = { list: payload.removeImages };
      const response2 = yield call(
        callApiAxios,
        "PATCH",
        `files/removeInitialImages`,
        tokenAccess,
        {},
        body
      );
    }

    yield put(setInitialImages.success());
  } catch (e) {
    yield put(
      setInitialImages.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(setInitialImages.fulfill());
  }
}

export default function* setupSaga() {
  yield takeEvery(setInitialImages.TRIGGER, setInitialImagesSaga);
  yield takeEvery(getInitialImages.TRIGGER, getInitialImagesSaga);
  yield takeEvery(setInitialImages.SUCCESS, getInitialImagesSaga);
}
