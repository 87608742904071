import * as type from "./types";
import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";

export const getOfferers = createRoutine(type.GET_OFFERERS);
export const createOfferer = createRoutine(type.CREATE_OFFERER);
export const changeFormOfferer = createAction(type.CHANGE_FORM_OFFERER)
export const getOfferersByStatus = createAction(type.GET_OFFERERS_BY_STATUS);
export const clearResponseOfferers = createAction(type.CLEAR_RESPONSE_OFFERERS);
export const getOfferersByType = createAction(type.GET_OFFERERS_BY_TYPE);
export const updateOfferer = createRoutine(type.UPDATE_OFFERER);
export const deleteOfferer = createRoutine(type.DELETE_OFFERER);
export const changeStatusOfferer = createRoutine(type.CHANGE_STATUS_OFFERER);
export const getPendingOfferers = createRoutine(type.GET_PENDING_OFFERERS);
export const setOfferersFilter = createAction(type.SET_OFFERERS_FILTER);
export const loadFormOfferer = createAction(type.LOAD_FORM_OFFERER);
export const resetFormOfferer = createAction(type.RESET_FORM_OFFERER);
export const setModalOfferersConfig = createAction(type.SET_MODAL_OFFERERS_CONFIG);