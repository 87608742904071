import draftToHtml from "draftjs-to-html";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUserApp } from "../../../../redux/modules/app/selectors";
import { setSelectedAuctionId } from "../../../../redux/modules/auctions/actions";
import { getCurrentAuctionByIdLoaded } from "../../../../redux/modules/auctions/selectors";
import { getIsAuth } from "../../../../redux/modules/auth/selectors";
import BackButton from "../../../ui/BackButton";
import CommonCarousel from "../../../ui/CommonCarousel";
import CommonPanel from "../../../ui/CommonPanel";
import BoxDetailSale from "../BoxDetailSale";
import TableOffers from "../OfferingProcess/TableOffers";
const { REACT_APP_ASSETS_URL } = process.env;

const DetailSale = () => {
  const isAuth = useSelector(getIsAuth);
  const user = useSelector(getUserApp);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const activeAuction = useSelector((state) =>
    getCurrentAuctionByIdLoaded(state, id, user)
  );
  useEffect(() => {
    dispatch(setSelectedAuctionId(id));
  }, [id]);

  useEffect(() => {
    if (!activeAuction) {
      navigate("/sales/grid");
    }
  }, [activeAuction]);

  const buildDescription = (description) => {
    const html = draftToHtml(description);
    return (
      <div
        className="col-md-12 p-5"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></div>
    );
  };

  const buildOffers = (offers) => {
    var grouped = offers.reduce((g, item) => {
      if (g == null) {
        g = [];
      }
      if (g.length == 0) {
        let newObj = {
          id: item.id,
          createdAt: item.createdAt,
          offererUserName: item.offererUserName,
          comissionPrice: item.comissionPrice,
          offerPrice: item.offerPrice,
          items: [],
        };
        newObj.items.push(item);
        g.push(newObj);
      } else {
        let oldObj = g.find((x) => x.offererUserName == item.offererUserName);
        if (oldObj != null) {
          oldObj.items.push(item);
        } else {
          let newObj = {
            id: item.id,
            createdAt: item.createdAt,
            offererUserName: item.offererUserName,
            comissionPrice: item.comissionPrice,
            offerPrice: item.offerPrice,
            items: [],
          };
          newObj.items.push(item);
          g.push(newObj);
        }
      }
      return g;
    }, []);

    return grouped;
  };

  return activeAuction ? (
    <CommonPanel
      style={{
        margin: !isAuth ? "100px 20px !important" : "20px",
      }}
      title={
        <span
          className="font-bold"
          style={{ color: "#005493", fontSize: "2rem" }}
        >
          {activeAuction.name}
        </span>
      }
      startButtons={
        <BackButton
          title="Atrás"
          to={!isAuth ? "/view-auctions" : "/auctions/grid"}
        />
      }
      endButtons={
        <a
          href={activeAuction.requirementsLink}
          target="_blank"
          style={{
            borderRadius: "6px",
            padding: "10px",
            color: "white",
            background: "#005493",
            cursor: "pointer",
          }}
        >
          VER CONDICIONES DE VENTA DIRECTA
        </a>
      }
    >
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-7">
          {activeAuction.images && activeAuction.images.length > 0 ? (
            <CommonCarousel
              carouselType="auctionDetail"
              items={activeAuction.images}
            />
          ) : (
            <img
              className="card-img-top"
              src={`${REACT_APP_ASSETS_URL}/${activeAuction.urlImage}`}
              alt=""
            />
          )}
        </div>

        <div className="col-sm-12 col-md-12 col-lg-5">
          {activeAuction && <BoxDetailSale user={user} item={activeAuction} />}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 p-5 border-1 rounded shadow-sm">
          <div className=" w-full text-black-50 font-semibold text-center border-b">
            INFORMACIÓN DETALLADA
          </div>
          <div className="row pt-2">
            {activeAuction.description
              ? buildDescription(activeAuction.description)
              : "No tiene información detallada"}
          </div>
        </div>
      </div>
      {user && (
        <div className="row">
          <div className="col-md-12 p-5 border-1 rounded shadow-sm">
            <div className="row pt-2">
              {activeAuction.offers && activeAuction.offers.length > 0 ? (
                <>
                  {user.userType === "admin" && (
                    <div>
                      <div className=" w-full text-black-50 font-semibold text-center border-b">
                        INFORMACIÓN OFERTAS CONSOLIDADA
                      </div>
                      <TableOffers
                        isDetailed={false}
                        columns={[
                          "Fecha",
                          "Oferente",
                          "Num Ofertas",
                          "Comision",
                          "Max Oferta",
                        ]}
                        items={buildOffers(activeAuction.offers)}
                      />
                    </div>
                  )}
                  <div>
                    <div className=" w-full text-black-50 font-semibold text-center border-b">
                      INFORMACIÓN OFERTAS DETALLADA
                    </div>
                    <TableOffers
                      isDetailed={true}
                      columns={["Fecha", "Oferente", "Comision", "Oferta"]}
                      items={
                        user.userType === "admin"
                          ? activeAuction.offers
                          : activeAuction.offers.filter(
                              (x) => x.offererUserName === user.userName
                            )
                      }
                    />
                  </div>
                </>
              ) : (
                "No tiene ofertas"
              )}
            </div>
          </div>
        </div>
      )}
    </CommonPanel>
  ) : (
    <></>
  );
};

export default DetailSale;
