import { call, put, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import {
  getCountries,
  getDepartmentsByCountry,
  getCitiesByDepartment,
} from "./actions";

function* getCountriesSaga() {
  try {
    yield put(getCountries.request());
    const response = yield call(
      callApiAxios,
      "GET",
      "locations/getCountries",
      null,
      {},
      {}
    );
    yield put(getCountries.success(response));
  } catch (e) {
    yield put(
      getCountries.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getCountries.fulfill());
  }
}

function* getDepartmentsByCountrySaga({ payload }) {
  try {
    yield put(getDepartmentsByCountry.request());
    if (payload) {
      const response = yield call(
        callApiAxios,
        "GET",
        `locations/getDepartmentsByCountryId/${payload}`,
        null,
        {},
        {}
      );
      yield put(getDepartmentsByCountry.success(response));
    }
  } catch (e) {
    yield put(
      getDepartmentsByCountry.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getDepartmentsByCountry.fulfill());
  }
}

function* getCitiesByDepartmentSaga({ payload }) {
  try {
    yield put(getCitiesByDepartment.request());
    if (payload) {
    const response = yield call(
      callApiAxios,
      "GET",
      `locations/getCitiesByDepartmentId/${payload}`,
      null,
      {},
      {}
    );
    yield put(getCitiesByDepartment.success(response));
    }
  } catch (e) {
    yield put(
      getCitiesByDepartment.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getCitiesByDepartment.fulfill());
  }
}

export default function* locationsSaga() {
  yield takeEvery(getCountries.TRIGGER, getCountriesSaga);
  yield takeEvery(getDepartmentsByCountry.TRIGGER, getDepartmentsByCountrySaga);
  yield takeEvery(getCitiesByDepartment.TRIGGER, getCitiesByDepartmentSaga);
}
