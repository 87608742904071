import React from "react";

export function CurrencyIcon(props) {
  const classCurrencyIcon = {
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5px",
  };
  return (
    <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M0.750 12.000 A11.250 11.250 0 1 0 23.250 12.000 A11.250 11.250 0 1 0 0.750 12.000 Z"
          style={classCurrencyIcon}
        ></path>
        <path
          d="M9.75,14.25A2.25,2.25,0,1,0,12,12a2.25,2.25,0,1,1,2.25-2.25"
          style={classCurrencyIcon}
        ></path>
        <path d="M12 6L12 7.5" style={classCurrencyIcon}></path>
        <path d="M12 16.5L12 18" style={classCurrencyIcon}></path>
      </g>
    </svg>
  );
}
