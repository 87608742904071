import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUserApp } from "../../../redux/modules/app/selectors";
import { setMenuHeader } from "../../../redux/modules/layout/actions";
import { getMainMenuItems } from "../../../redux/modules/layout/selectors";
import Header from "./Header";
const classLayoutBase = {
  with: "100%",
  display: "flex",
  flexDirection: "column",
};

const Layout = ({ variant, children }) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const user = useSelector(getUserApp);
  const menuItems = useSelector(getMainMenuItems);

  useEffect(() => {
    if (user) {
      dispatch(setMenuHeader(user));
    }
  }, [user]);

  return (
    <>
      <Header menuLinks={user ? menuItems : []} user={user}></Header>
      <main className="container-full" style={{ paddingTop: "70px" }}>
        {children}
      </main>
    </>
  );
};
export default Layout;
