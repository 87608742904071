import React from "react";

export function ActiveIcon(props) {
  const classActiveIcon = {
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "3px",
  };
  return (
    <svg {...props} viewBox="0 0 45.01 48">
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path style={classActiveIcon} d="M22.5,46.5V15" />
          <path style={classActiveIcon} d="M30,22.5,22.5,15,15,22.5" />
          <path style={classActiveIcon} d="M31.7,41.39A21,21,0,1,0,12.61,41" />
        </g>
      </g>
    </svg>
  );
}
