import React from "react";

export function InactiveIcon(props) {
  const classInactiveIcon = {
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "3px",
  };
  return (
    <svg {...props} viewBox="0 0 45.01 48">
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path style={classInactiveIcon} d="M22.13,1.5,22.62,33" />
          <path style={classInactiveIcon} d="M15,25.62,22.62,33,30,25.38" />
          <path style={classInactiveIcon} d="M13,6.76a21,21,0,1,0,19.08,0" />
        </g>
      </g>
    </svg>
  );
}
