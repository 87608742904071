import React from "react";
import CommonTable from "../../ui/CommonTable";
import { StatusBadge } from "../../ui/CommonBadges";
import CommonOptionsRowTable from "../../ui/CommonOptionsRowTable";

const TableCategories = ({ columns, items, onHandleClickRow }) => {
  const buildItems = (_items) => {
    return (
      _items &&
      _items.length > 0 &&
      _items.map((item) => (
        <tr>
          <td>{item.code}</td>
          <td>{item.name}</td>
         <td>
                <StatusBadge status={item.status} />
              </td>
              <td>
                <CommonOptionsRowTable
                  key={item.id}
                  item={item}
                  onHandleClickRow={onHandleClickRow}              
                ></CommonOptionsRowTable>
              </td>
        </tr>
      ))
    );
  };
  return (
    <div className="p-3 rounded">
      <div className="flex flex-wrap">
        <CommonTable
          columns={columns}
          items={buildItems(items)}
          onHandleClickRow={onHandleClickRow}
        ></CommonTable>
      </div>
    </div>
  );
};

export default TableCategories;
