import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  uploadAssociatedAuctionFiles,
  changeStatusAssociatedAuction,
  getAssociatedAuctionPending,
} from "../../../redux/modules/associated-auctions/actions";
import { getAssociatedAuctionsPendingLoaded } from "../../../redux/modules/associated-auctions/selectors";
import {
  setModalAuctionsConfig,
  setPendingOfferersFilter,
} from "../../../redux/modules/auctions/actions";
import { getModalAuctionsConfig } from "../../../redux/modules/auctions/selectors";
import { CountBadge, StatusBadge } from "../../ui/CommonBadges";
import CommonButtonIcon from "../../ui/CommonButtonIcon";
import CommonModal from "../../ui/CommonModal";
import CommonOptionsRowTable from "../../ui/CommonOptionsRowTable";
import CommonPanel from "../../ui/CommonPanel";
import CommonTable from "../../ui/CommonTable";
import { AttachIcon } from "../../ui/custom-icons";
import SearchBar from "../../ui/SearchBar";
import UploadFilesModal from "../../ui/UploadFilesModal";

const TableOffererRequests = () => {
  const dispatch = useDispatch();
  const associatedAuctionsPending = useSelector(getAssociatedAuctionsPendingLoaded);
  const modalConfig = useSelector(getModalAuctionsConfig);
  const [modalConfigFiles, setModalConfigFiles] = useState({
    show: false,
    title: "",
    type: "content",
    data: null,
  });

  useEffect(() => {
    dispatch(getAssociatedAuctionPending.trigger());
  }, []);

  const handleModalClose = () => {
    dispatch(
      setModalAuctionsConfig({ ...modalConfig, show: false, data: null })
    );
  };

  const handleModalOk = () => {
    dispatch(changeStatusAssociatedAuction.trigger(modalConfig.data));
    dispatch(
      setModalAuctionsConfig({ ...modalConfig, show: false, data: null })
    );
  };

  const handleClickRow = ({ data, item }) => {
    switch (data.action) {
      case "upload-files":
        setModalConfigFiles({
          ...modalConfigFiles,
          show: true,
          title: `Cargar Archivos - ${item.auctionName}`,
          data: item,
        });

        break;

      default:
        dispatch(
          setModalAuctionsConfig({
            show: true,
            data: { path: data.action, id: item.id, auctionId: item.auctionId },
            type: data.action,
            title: `${data.title} Oferente`,
            message: (
              <div className="w-full">
                <p className="text-center">
                  {`${
                    data.action === "pending" ? "¿" : "¿Esta seguro que desea"
                  } ${data.title.toUpperCase()} el Oferente?`}
                  <br></br>
                  <span style={{ fontWeight: 700 }}>{item.email}</span>
                </p>
              </div>
            ),
          })
        );
    }
  };

  const buildItems = (_items) => {
    return (
      _items &&
      _items.length > 0 &&
      _items.map((item) => (
        <tr key={item.id}>
          <td>
            {" "}
            <div className="flex flex-column">
              <span style={{ fontWeight: 700 }}>{item.auctionCode}</span>
              <span className="text-sm">
                {item.auctionType}-{item.categoryName}
              </span>
              <span>{item.auctionName}</span>
            </div>
          </td>
          <td>{item.offererName}</td>
          <td>{new Date(item.createdAt).toLocaleString()}</td>
          <td>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CountBadge count={item.files.length}></CountBadge>
              <CommonButtonIcon
                onHandleClick={() =>
                  handleClickRow({
                    item,
                    data: { action: "upload-files" },
                  })
                }
                icon={<AttachIcon className="icon-button" />}
                title={"Subir Archivos"}
              ></CommonButtonIcon>
            </div>
          </td>
          <td>
            <StatusBadge status={item.status} />
          </td>
          <td>
            <CommonOptionsRowTable
              key={item.id}
              item={item}
              buttonsType="secondary"
              onHandleClickRow={handleClickRow}
            ></CommonOptionsRowTable>
          </td>
        </tr>
      ))
    );
  };

  const startButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <NavLink to="/auctions">Atrás</NavLink>
    </div>
  );

  const handleSearch = (e) => {
    dispatch(
      setPendingOfferersFilter({ propertyName: "search", propertyValue: e })
    );
  };

  const handleUploadFiles = (uploadFiles, removeFiles) => {
    dispatch(
      uploadAssociatedAuctionFiles.trigger({
        id:
          modalConfigFiles.data.associatedAuctionId || modalConfigFiles.data.id,
        uploadFiles,
        removeFiles,
      })
    );
    setModalConfigFiles({ ...modalConfigFiles, show: false, data: null });
  };

  return (
    <div>
      <CommonPanel
        title="Solicitudes de participación"
        count={buildItems(associatedAuctionsPending).length}
        startButtons={startButtons}
        searchBar={<SearchBar onHandleSearch={handleSearch}></SearchBar>}
      >
        <div className="w-full p-3 rounded">
          <CommonTable
            columns={[
              "Subasta",
              "Oferente",
              "Fecha solicitud",
              "Archivos",
              "Estado",
              "Opciones",
            ]}
            handleClickRow={handleClickRow}
            items={buildItems(associatedAuctionsPending)}
          ></CommonTable>
        </div>
        <CommonModal
          config={modalConfig}
          handleModalClose={handleModalClose}
          handleModalOk={handleModalOk}
        ></CommonModal>
        {modalConfigFiles.data && (
          <UploadFilesModal
            modalConfig={modalConfigFiles}
            setModalConfig={setModalConfigFiles}
            onHandleUploadFiles={handleUploadFiles}
          ></UploadFilesModal>
        )}
      </CommonPanel>
    </div>
  );
};

export default TableOffererRequests;
