import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { doSignInUser,doVerificationCode } from "./actions";
import { getFormSignIn } from "./selectors";

function* doSignInUserSaga() {
  try {
    yield put(doSignInUser.request());
    const form = yield select(getFormSignIn);
    const body = {
      fullName: form.fullName,
      documentType: form.documentType,
      document: form.document,
      phone: form.phone,
      cellPhone: form.cellPhone,
      address: form.address,
      email: form.email,
      password: form.password,         
      userName: form.userName,         
      cityId: form.cityId,
      acceptPDT: form.acceptPDT,
      acceptSMS: form.acceptSMS,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      "auth/signIn",
      null,
      {},
      body
    );
    yield put(doSignInUser.success(response));
  } catch (e) {
    yield put(
      doSignInUser.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(doSignInUser.fulfill());
  }
}

function* doVerificationCodeSaga() {
  try {
    yield put(doVerificationCode.request());
    const form = yield select(getFormSignIn);
    const body = {
     code:form.verifyCode,
     email:form.email
    };
    const response = yield call(
      callApiAxios,
      "PATCH",
      "auth/verificationCode",
      null,
      {},
      body
    );
    yield put(doVerificationCode.success(response));
  } catch (e) {
    yield put(
      doVerificationCode.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(doVerificationCode.fulfill());
  }
}



export default function* signInSaga() {
  yield takeEvery(doSignInUser.TRIGGER, doSignInUserSaga);
  yield takeEvery(doVerificationCode.TRIGGER, doVerificationCodeSaga);
}
