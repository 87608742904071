import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInitialImages } from "../../../redux/modules/setup/actions";
import { getInitialImagesLoaded } from "../../../redux/modules/setup/selectors";
import CommonCarousel from "../../ui/CommonCarousel";
import AboutSection from "./AboutSection";
import AuctionsSection from "./AuctionsSection";
import SalesSection from "./SalesSection";
import ContactSection from "./ContactSection";
import ServicesSection from "./ServicesSection";
import SocialBar from "./SocialBar";

export const LandingPage = () => {
  const dispatch = useDispatch();
  const imagesLoaded = useSelector(getInitialImagesLoaded);

  useEffect(() => {
    dispatch(getInitialImages.trigger());
    return () => {};
  }, []);
  return (
    <>
      <CommonCarousel
        items={imagesLoaded ? imagesLoaded : []}
        carouselType="images"
      />
      <SalesSection></SalesSection>
      <AuctionsSection></AuctionsSection>
      <ServicesSection></ServicesSection>
      <SocialBar></SocialBar>
      <AboutSection></AboutSection>
      <ContactSection></ContactSection>
    </>
  );
};

export default LandingPage;
