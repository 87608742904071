import React from "react";
import { CountBadge } from "./CommonBadges";
const CommonPanel = ({
  style,
  children,
  title,
  count,
  startOptions,
  middleOptions,
  endOptions,
  additionalButtons,
  startButtons,
  secondaryFilterBar,
  endButtons,
  searchBar,
  filterBar,
  variant = "default",
  padding = "p-4",
  showCount = true,
}) => {
  switch (variant) {
    // case "form":
    //   return (
    //     <div
    //       style={{ ...style }}
    //       className="flex flex-wrap border-1 shadow rounded"
    //     >
    //       <div
    //         style={{ display: "flex", justifyContent: "space-between" }}
    //         className="w-full px-4 pt-4"
    //       >
    //         <div style={{ display: "flex" }}>
    //           {startButtons && startButtons}
    //         </div>
    //         <div style={{ display: "flex" }}>
    //           <div className="text-2xl font-bold">{title}</div>
    //         </div>
    //         <div className="flex items-baseline">
    //           {endButtons && endButtons}
    //         </div>
    //       </div>
    //       {children}
    //     </div>
    //   );
    //   break;

    default:
      return (
        <div style={{ ...style }} className={`row ${padding}`}>
          <div
            style={{ backgroundColor: "white" }}
            className="p-3 col-sm-12 col-md-12  border-1 shadow rounded"
          >
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div style={{ display: "flex" }} className="items-baseline">
                  {startButtons && startButtons}
                  {startOptions && startOptions}
                  {title && (
                    <div className="text-2xl font-bold mr-1">{title}</div>
                  )}
                  {showCount && (
                    <CountBadge className="text-sm" count={count} />
                  )}
                  {additionalButtons && additionalButtons}
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                {middleOptions && middleOptions}
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  className="items-baseline"
                >
                  {secondaryFilterBar && secondaryFilterBar}

                  {filterBar && filterBar}
                  {searchBar && searchBar}
                  {endOptions && endOptions}
                  {endButtons && endButtons}
                </div>
              </div>
            </div>
            {/* <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="w-full items-baseline px-4 pt-4"
            >
    
              {additionalButtons && additionalButtons}
              {endButtons && endButtons}
              {secondaryFilterBar && secondaryFilterBar}
              {filterBar && filterBar}
              {searchBar && searchBar}
            </div> */}
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">{children}</div>
            </div>
          </div>
        </div>
      );
  }
};

export default CommonPanel;
