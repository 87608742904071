export default function NotResults() {
  return (
    <div
      className="w-full, h-full"
      style={{
        display: "flex",
        flexDirection:"column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "2rem",
        padding:"50px 20px",
        fontWeight:600
      }}
    >
      <h1 className="text-center p-5">NO SE ENCONTRARON RESULTADOS</h1>
        <img className="thumbnail" src="/assets/images/empty.png"></img>
    </div>
  );
}
