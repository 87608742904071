import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  requestOffererProcess,
  setTempOfferPrice,
} from "../../../../redux/modules/auctions/actions";
import TrafficLight from "./TrafficLight";

const OfferPanel = ({
  id,
  user,
  minPrice,
  lastPrice,
  minimumIncrease,
  lastOfferer,
  isSale = false,
}) => {
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (price < minPrice) {
      setPrice(minPrice);
    }
  }, [minPrice]);

  const [price, setPrice] = useState(minPrice);
  const handleInputChangeOffer = (e) => {
    setPrice(e.target.value);
  };

  const getErrors = () => {
    if (!price) {
      return "El valor de la oferta es obligatorio.";
    }
    if (parseInt(price) < minPrice) {
      return "El valor de la oferta no puede ser inferior a la oferta base mas al menos un incremento minimo.";
    }
  };

  const isInvalidOffer = () => {
    if (!price) {
      return true;
    }
    if (price < minPrice) {
      return true;
    }
    return false;
  };

  const handleClickMakeOffer = (currentId) => {
    const newOffer = {
      id: currentId,
      offerPrice: price,
    };
    dispatch(requestOffererProcess.trigger(newOffer));
  };

  if (isSale) {
    return       <div className="text-black-50">


    <div className="row">
      <div className="col-6">
        <input
          name="offerPrice"
          className="form-control"
          type="number"
          min={Number(minPrice)}
          value={Number(price)}
          onChange={(e) => handleInputChangeOffer(e)}
        />
      </div>

      <Button
        style={{ background: "orange" }}
        className="my-4"
        disabled={isInvalidOffer()}
        onClick={() => handleClickMakeOffer(id)}
      >
        Enviar Oferta
      </Button>

      {isInvalidOffer() && <div>{getErrors()}</div>}
    </div>
  </div>
  } else {
    return (
      <div className="text-black-50">
        {lastOfferer !== "***" && (
          <TrafficLight
            isWinning={lastOfferer === user.userName}
          ></TrafficLight>
        )}
        <dl>
          <dt className="text-black-50 py-1">
            OFERTA BASE:{"  "}
            <span className="text-black text-lg">
              $ {new Intl.NumberFormat("es-CO").format(lastPrice)}
            </span>
          </dt>

          <dt className="text-black-50 py-1">
            INCREMENTO MINIMO:{"  "}
            <span className="text-black text-lg">
              $ {new Intl.NumberFormat("es-CO").format(minimumIncrease)}
            </span>
          </dt>
        </dl>
        <div className="row">
          <div className="col-6">
            <input
              name="offerPrice"
              className="form-control"
              type="number"
              step={Number(minimumIncrease)}
              min={Number(minPrice)}
              value={Number(price)}
              onChange={(e) => handleInputChangeOffer(e)}
            />
          </div>
          <div className="col-6">
            <Button
              onClick={() => setPrice(minPrice)}
              className="ml-3"
              variant="outline-dark"
            >
              MIN
            </Button>
            <Button
              onClick={() => setPrice(minPrice + minimumIncrease)}
              className="ml-3"
              variant="outline-dark"
            >
              X2
            </Button>
            <Button
              onClick={() => setPrice(minPrice + minimumIncrease * 2)}
              className="ml-3"
              variant="outline-dark"
            >
              X3
            </Button>
            <Button
              onClick={() => setPrice(minPrice + minimumIncrease * 3)}
              className="ml-3"
              variant="outline-dark"
            >
              X4
            </Button>
          </div>
          <Button
            style={{ background: "orange" }}
            className="my-4"
            disabled={isInvalidOffer()}
            onClick={() => handleClickMakeOffer(id)}
          >
            Enviar Oferta
          </Button>

          {isInvalidOffer() && <div>{getErrors()}</div>}
        </div>
      </div>
    );
  }
};

export default OfferPanel;
