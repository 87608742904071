import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStatusCategory,
  createCategory,
  deleteCategory,
  getCategories,
  loadFormCategory,
  setCategoriesFilter,
  updateCategory,
} from "../../../redux/modules/categories/actions";
import { getCategoriesList } from "../../../redux/modules/categories/selectors";
import CommonModal from "../../ui/CommonModal";
import CommonPanel from "../../ui/CommonPanel";
import FilterBar from "../../ui/FilterBar";
import SearchBar from "../../ui/SearchBar";
import FormCategory from "./FormCategory";
import TableCategories from "./TableCategories";

function Categories() {
  const dispatch = useDispatch();
  const categories = useSelector(getCategoriesList);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    title: "",
    message: "",
    type: "delete",
    data: "",
  });

  useEffect(() => {
    dispatch(getCategories.trigger());
  }, []);

  const handleCreate = () => {
    dispatch(createCategory.trigger());
  };

  const handleUpdate = () => {
    setModalConfig({ ...modalConfig, show: true });
  };

  const handleModalClose = () => {
    setModalConfig({ ...modalConfig, show: false, data: null });
  };

  const handleSearch = (e) => {
    dispatch(setCategoriesFilter({ propertyName: "search", propertyValue: e }));
  };

  const handleStatusFilter = (e) => {
    dispatch(setCategoriesFilter({ propertyName: "status", propertyValue: e }));
  };

  const handleModalOk = () => {
    switch (modalConfig.type) {
      case "delete":
        dispatch(deleteCategory.trigger(modalConfig.data));
        setModalConfig({ ...modalConfig, show: false, data: null });
        break;
      case "changeStatus":
        dispatch(changeStatusCategory.trigger(modalConfig.data));
        setModalConfig({ ...modalConfig, show: false, data: null });
        break;
      case "edit":
        dispatch(updateCategory.trigger());
        setModalConfig({ ...modalConfig, show: false, data: null });
        break;
    }
  };

  const handleClickRow = ({ data, item }) => {
    switch (data.action) {
      case "edit":
        setModalConfig({
          show: false,
          type: data.action,
          title: "Actualizar Categoría",
          message: (
            <div className="w-full">
              <p className="text-center">
                ¿Desea guardar los cambios en la categoría? <br></br>
                <span style={{ fontWeight: 700 }}>{item.name}</span>
              </p>
            </div>
          ),
          data: { id: item.id },
        });
        dispatch(loadFormCategory(item));
        break;
      case "delete":
        setModalConfig({
          show: true,
          type: data.action,
          title: "Eliminar Categoría",
          message: (
            <div className="w-full">
              <p className="text-center">
                ¿Desea eliminar la categoría? <br></br>
                <span style={{ fontWeight: 700 }}>{item.name}</span>
              </p>
            </div>
          ),
          data: { id: item.id },
        });
        break;
      case "changeStatus":
        setModalConfig({
          show: true,
          data: { path: data.value, id: item.id },
          type: data.action,
          title: `${data.title} Categoría`,
          message: (
            <div className="w-full">
              <p className="text-center">
                {`¿Desea ${data.title.toLowerCase()} la categoría?`}
                <br></br>
                <span style={{ fontWeight: 700 }}>{item.name}</span>
              </p>
            </div>
          ),
        });

        break;
    }
  };

  const filterBarOptions = [
    { name: "Todos", value: "Todos" },
    { name: "Activo", value: "Activo" },
    { name: "Inactivo", value: "Inactivo" },
  ];

  return (
    <CommonPanel
      title="Lista de Categorías"
      count={categories.length}
      searchBar={<SearchBar onHandleSearch={handleSearch}></SearchBar>}
      filterBar={
        <FilterBar
          name="Estado"
          options={filterBarOptions}
          onHandleFilter={handleStatusFilter}
        ></FilterBar>
      }
    >
      <div className="row">
        <div className="col-md-6">
          <FormCategory
            onHandleCreate={handleCreate}
            onHandleUpdate={handleUpdate}
          ></FormCategory>
        </div>
        <div className="col-md-6">
          <TableCategories
            columns={["Código", "Nombre", "Estado", "Opciones"]}
            onHandleClickRow={handleClickRow}
            items={categories}
          ></TableCategories>
        </div>
      </div>
      <CommonModal
        config={modalConfig}
        handleModalClose={handleModalClose}
        handleModalOk={handleModalOk}
      ></CommonModal>
    </CommonPanel>
  );
}

export default Categories;
