import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { getToken } from "../auth/selectors";
import {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  changeStatusUser,
  getPendingUsers,
  changeStatusUserPending,
} from "./actions";
import { getFormUsers } from "./selectors";

function* getUsersSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(getUsers.request());
    const response = yield call(
      callApiAxios,
      "GET",
      "users",
      tokenAccess,
      {},
      {}
    );
    yield put(getUsers.success(response));
  } catch (e) {
    yield put(
      getUsers.failure({ error: e ? String(e.message) : "Error de conexión" })
    );
  } finally {
    yield put(getUsers.fulfill());
  }
}

function* getPendingUsersSaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(getPendingUsers.request());
    const response = yield call(
      callApiAxios,
      "GET",
      `users/pending`,
      tokenAccess,
      {},
      {}
    );
    yield put(getPendingUsers.success(response));
  } catch (e) {
    yield put(
      getPendingUsers.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getPendingUsers.fulfill());
  }
}

function* createUserSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(createUser.request());
    const form = yield select(getFormUsers);
    const body = {
      userName: form.userName,
      userType: form.userType,
      email: form.email,
      password: form.password,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      "users",
      tokenAccess,
      {},
      body
    );
    yield put(createUser.success(response));
  } catch (e) {
    yield put(
      createUser.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(createUser.fulfill());
  }
}

function* changeStatusUserSaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(changeStatusUser.request());
    const response = yield call(
      callApiAxios,
      "PATCH",
      `users/${payload.id}/changeStatus/${payload.path}`,
      tokenAccess,
      {},
      {}
    );
    yield put(changeStatusUser.success(response));
  } catch (e) {
    yield put(
      changeStatusUser.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(changeStatusUser.fulfill());
  }
}
function* changeStatusUserPendingSaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(changeStatusUserPending.request());
    const response = yield call(
      callApiAxios,
      "PATCH",
      `users/${payload.id}/changeStatus/${payload.path}`,
      tokenAccess,
      {},
      {}
    );
    yield put(changeStatusUserPending.success(response));
  } catch (e) {
    yield put(
      changeStatusUserPending.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(changeStatusUserPending.fulfill());
  }
}

function* deleteUserSaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(deleteUser.request());
    const response = yield call(
      callApiAxios,
      "DELETE",
      `users/${payload.id}`,
      tokenAccess,
      {},
      {}
    );
    yield put(deleteUser.success(response));
  } catch (e) {
    yield put(
      deleteUser.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(deleteUser.fulfill());
  }
}

function* updateUserSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(updateUser.request());
    const form = yield select(getFormUsers);
    const body = {
      id: form.id,
      userName: form.userName,
      userType: form.userType,
      email: form.email,
      password: form.password,
    };
    const response = yield call(
      callApiAxios,
      "PUT",
      `users/${body.id}`,
      tokenAccess,
      {},
      body
    );
    yield put(updateUser.success(response));
  } catch (e) {
    yield put(
      updateUser.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(updateUser.fulfill());
  }
}

export default function* usersSaga() {
  yield takeEvery(getUsers.TRIGGER, getUsersSaga);
  yield takeEvery(getPendingUsers.TRIGGER, getPendingUsersSaga);
  yield takeEvery(createUser.TRIGGER, createUserSaga);
  yield takeEvery(updateUser.TRIGGER, updateUserSaga);
  yield takeEvery(deleteUser.TRIGGER, deleteUserSaga);
  yield takeEvery(changeStatusUser.TRIGGER, changeStatusUserSaga);
  yield takeEvery(changeStatusUserPending.TRIGGER, changeStatusUserPendingSaga);
  yield takeEvery(deleteUser.SUCCESS, getUsersSaga);
  yield takeEvery(updateUser.SUCCESS, getUsersSaga);
  yield takeEvery(changeStatusUser.SUCCESS, getUsersSaga);
  yield takeEvery(changeStatusUserPending.SUCCESS, getPendingUsersSaga);
}

