import produce from "immer";
import { ON_INITIAL_STATE, SET_CURRENT_PAGE, SET_MENU_HEADER } from "./types";
import { initialState } from "./states";

export const layout = produce((draft, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_PAGE:
      draft.currentPage = payload;
      break;
    case SET_MENU_HEADER:
      const getMenuOptions = (userType) => {
        switch (userType) {
          case "admin":
            return [
              {
                path: "/categories",
                name: "Categorias",
                icon: "bi bi-tags-fill",
                imagen: "",
              },
              {
                path: "/companies",
                name: "Empresas",
                icon: "bi bi-tags-fill",
                imagen: "",
              },
              {
                path: "/auctions",
                name: "Subastas",
                icon: "bi bi-bank2",
                imagen: "",
              },
              {
                path: "/offerers",
                name: "Oferentes",
                icon: "bi bi-people-fill",
              },
              { path: "/users", name: "Usuarios", icon: "bi bi-people-fill" },
              { path: "/setup", name: "Configuración", icon: "bi bi-tools" },
            ];

          default:
            return [
              {
                path: "/auctions/grid",
                name: "Subastas",
                icon: "bi bi-bank2",
                imagen: "",
              },
              {
                path: "/sales/grid",
                name: "Ventas Directas",
                icon: "bi bi-bank2",
                imagen: "",
              },
              {
                path: "/my-auctions",
                name: "Subastas Asociadas",
                icon: "bi bi-coin",
                imagen: "",
              },
            ];
        }
      };

      draft.menuOptions = getMenuOptions(payload.userType);

      break;

    case ON_INITIAL_STATE:
      return initialState;
      break;
  }
}, initialState);
