import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  getCitiesByDepartment,
  getCountries,
  getDepartmentsByCountry,
} from "../../../redux/modules/locations/actions";
import {
  getCitiesLoaded,
  getCountriesLoaded,
  getDepartmentsLoaded,
} from "../../../redux/modules/locations/selectors";
import {
  getFormOfferers,
  getModalOfferersConfig,
  getResponseOfferers,
} from "../../../redux/modules/offerers/selectors";
import {
  changeFormOfferer,
  createOfferer,
  updateOfferer,
  resetFormOfferer,
  setModalOfferersConfig,
} from "../../../redux/modules/offerers/actions";
import CommonPanel from "../../ui/CommonPanel";
import CommonModal from "../../ui/CommonModal";
import { getCompanies } from "../../../redux/modules/companies/actions";
import { getCategories } from "../../../redux/modules/categories/actions";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../utils/messages";
import { RequiredIcon } from "../../ui/custom-icons";
import { useNavigate } from "react-router-dom";

function FormOfferer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const form = useSelector(getFormOfferers);
  const response = useSelector(getResponseOfferers);
  const countries = useSelector(getCountriesLoaded);
  const departments = useSelector(getDepartmentsLoaded);
  const cities = useSelector(getCitiesLoaded);
  const modalOfferersConfig = useSelector(getModalOfferersConfig);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    title: "",
    message: "",
    type: "edit",
    data: "",
  });

  useEffect(() => {
    dispatch(getCountries.trigger());
    dispatch(getCompanies.trigger());
    dispatch(getCategories.trigger());
  }, []);

  useEffect(() => {
    if (response.message) {
      navigate("/offerers");
    }
  }, [response]);

  useEffect(() => {
    dispatch(getCountries.trigger());
    if (form.countryId) {
      dispatch(getDepartmentsByCountry.trigger(form.countryId));
      if (form.departmentId) {
        dispatch(getCitiesByDepartment.trigger(form.departmentId));
      }
    }
  }, []);

  const handleChangeInput = (propertyValue, propertyName) => {
    dispatch(changeFormOfferer({ value: propertyValue, name: propertyName }));
  };

  const handleChangeCountry = (e) => {
    e.preventDefault();
    dispatch(changeFormOfferer({ value: e.target.value, name: "countryId" }));
    dispatch(changeFormOfferer({ value: "", name: "departmentId" }));
    dispatch(changeFormOfferer({ value: "", name: "cityId" }));
    dispatch(getDepartmentsByCountry.trigger(e.target.value));
  };

  const handleChangeDepartment = (e) => {
    e.preventDefault();
    dispatch(
      changeFormOfferer({ value: e.target.value, name: "departmentId" })
    );
    dispatch(changeFormOfferer({ value: "", name: "cityId" }));
    dispatch(getCitiesByDepartment.trigger(e.target.value));
  };

  useEffect(() => {
    dispatch(getCountries.trigger());
  }, []);

  const handleSubmitForm = () => {
    if (isValidForm()) {
      if (form.isNew) {
        dispatch(createOfferer.trigger());
      } else {
        setModalConfig({
          show: true,
          type: "edit",
          title: "Actualizar Oferente",
          message: (
            <div className="w-full">
              <p className="text-center">
                ¿Desea guardar los cambios en el oferente? <br></br>
                <span style={{ fontWeight: 700 }}>{form.name}</span>
              </p>
            </div>
          ),
          data: { id: form.id },
        });
      }
    } else {
      toast(MESSAGES.REQUIRED_FIELDS, {
        type: "error",
      });
    }
  };

  /*   const isValidForm = () => {
    return (
      form?.companyId &&
      form?.categoryId &&
      form?.name &&
      form?.description &&
      form?.address &&
      form?.minimumPrice &&
      form?.reservePrice &&
      form?.cityId &&
      form?.minimumIncrese &&
      form?.commission &&
      form?.startAt &&
      form?.closeAt &&
      form?.urlImage &&
      form?.reservePrice
    );
  }; */

  const isValidForm = () => {
    return true /* form?.name */;
  };

  const startButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <NavLink to="/offerers">Atrás</NavLink>
    </div>
  );

  const handleCancel = () => {
    if (form.isNew) {
      dispatch(resetFormOfferer());
    } else {
      dispatch(
        setModalOfferersConfig({
          ...modalOfferersConfig,
          show: false,
          data: null,
        })
      );
      dispatch(resetFormOfferer());
    }
  };

  const endButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <button className="btn btn-secondary mr-1" onClick={handleCancel}>
        Cancelar
      </button>
      <button
        className="btn btn-primary ml-1"
        disabled={!isValidForm()}
        onClick={handleSubmitForm}
      >
        Guardar
      </button>
    </div>
  );

  const handleModalClose = () => {
    setModalConfig({ ...modalConfig, show: false });
  };

  const handleModalOk = () => {
    switch (modalConfig.type) {
      case "edit":
        dispatch(updateOfferer.trigger());
        setModalConfig({ ...modalConfig, show: false, data: null });
        dispatch(
          setModalOfferersConfig({
            ...modalOfferersConfig,
            show: false,
            data: null,
          })
        );
        break;
    }
  };
  return (
    <CommonPanel
      variant="form"
      title={`${form.isNew ? "Agregar" : "Editar"} Oferente`}
      startButtons={form.isNew && startButtons}
      endButtons={endButtons}
    >
      <div className="w-full p-6 rounded">
        <form className="w-full flex flex-wrap" id="formOfferer">
          <div className="w-1/2">
            <div className="pr-6">
              <label className="w-full">
                <RequiredIcon />
                Nombre o Razón Social
              </label>
              <input
                className="form-control"
                required
                name="fullName"
                maxLength="110"
                value={form?.fullName}
                onChange={(e) => handleChangeInput(e.target.value, "fullName")}
              />
              <label className="w-full">
                <RequiredIcon />
                Tipo de Documento
              </label>
              <select
                className="form-select"
                name="documentType"
                maxLength="5"
                required
                value={form?.documentType}
                onChange={(e) =>
                  handleChangeInput(e.target.value, "documentType")
                }
              >
                <option value="CC">Cedula de ciudadanía</option>
                <option value="NIT">Nit</option>
                <option value="CE">Cedula de extranjería</option>
                <option value="PS">Pasaporte</option>
              </select>
              <label className="w-full pt-1">
                <RequiredIcon />
                Número de Documento
              </label>
              <input
                className="form-control"
                required
                name="document"
                maxLength="20"
                value={form?.document}
                onChange={(e) => handleChangeInput(e.target.value, "document")}
              />

              <label className="w-full">Teléfono</label>
              <input
                className="form-control"
                required
                name="phone"
                maxLength="20"
                value={form?.phone}
                onChange={(e) => handleChangeInput(e.target.value, "phone")}
              />
              <label className="w-full">
                <RequiredIcon />
                Celular
              </label>
              <input
                className="form-control"
                required
                name="cellPhone"
                maxLength="20"
                value={form?.cellPhone}
                onChange={(e) => handleChangeInput(e.target.value, "cellPhone")}
              />
            </div>
          </div>
          <div className="w-1/2">
            <label className="w-full">
              <RequiredIcon />
              País
            </label>
            <select
              className="form-select"
              name="countryId"
              maxLength="36"
              required
              value={form?.countryId}
              onChange={handleChangeCountry}
              disabled={countries && countries.length === 0}
            >
              <option value="">--Seleccionar--</option>
              {countries &&
                countries.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
            <label className="w-full">
              <RequiredIcon />
              Departamento
            </label>
            <select
              className="form-select"
              required
              name="departmentId"
              maxLength="36"
              value={form?.departmentId}
              onChange={handleChangeDepartment}
              disabled={departments && departments.length === 0}
            >
              <option value="">--Seleccionar--</option>
              {departments &&
                departments.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>

            <label className="w-full">
              <RequiredIcon />
              Ciudad
            </label>
            <select
              className="form-select"
              name="cityId"
              maxLength="36"
              required
              value={form?.cityId}
              onChange={(e) => handleChangeInput(e.target.value, "cityId")}
              disabled={cities && cities.length === 0}
            >
              <option value="">--Seleccionar--</option>
              {cities &&
                cities.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
            <label className="w-full">
              <RequiredIcon />
              Dirección
            </label>
            <input
              className="form-control"
              required
              name="address"
              maxLength="250"
              value={form?.address}
              onChange={(e) => handleChangeInput(e.target.value, "address")}
            />
          </div>
          <div className="flex w-full">
            <div className="w-1/3 pr-6">
              <label className="w-full">Usuario</label>
              <input
                className="form-control"
                required
                name="userName"
                type="text"
                leyendaError="El usuario debe tener más letras"
                value={form?.userName}
                onChange={(e) => handleChangeInput(e.target.value, "userName")}
              />
              {/* <CommonInput
                state={user}
                stateChange={userChange}
                type="text"
                label="Usuario"
                name="userName"
                labelError="El usuario tiene que ser de 4 a 16 dígitos y solo puede contener numeros, letras y guion bajo."
                regularExpression={expressions.user}
                onChange={(e) => handleChangeInput(e.target.value, "userName")}
                value={form?.userName}
              /> */}
            </div>
            <div className="w-1/3 pr-6">
              <label className="w-full">Correo</label>
              <input
                className="form-control"
                required
                name="email"
                type="email"
                value={form?.email}
                onChange={(e) => handleChangeInput(e.target.value, "email")}
              />
              {/* <CommonInput
                state={form?.email}
                stateChange={(e) => handleChangeInput(e.target.value, "email")}
                type="text"
                label="Correo"
                name="email"
                labelError="El correo solo puede contener letras, numeros, puntos, guiones y guion bajo."
                regularExpression={expressions.email}
              /> */}
            </div>
            <div className="w-1/3">
              <label className="w-full">Contraseña</label>
              <input
                className="form-control"
                required
                type="password"
                name="password"
                value={form?.password}
                onChange={(e) => handleChangeInput(e.target.value, "password")}
              />
              {/* <CommonInput
                state={password}
                stateChange={passwordChange}
                type="password"
                label="Contraseña"
                name="password"
                labelError="La contraseña tiene que ser de 4 a 12 dígitos."
                regularExpression={expressions.password}
                value={form?.password}
                onChange={(e) => handleChangeInput(e.target.value, "password")}
              /> */}
            </div>
          </div>
        </form>
      </div>
      <CommonModal
        config={modalConfig}
        handleModalClose={handleModalClose}
        handleModalOk={handleModalOk}
      ></CommonModal>
    </CommonPanel>
  );
}

export default FormOfferer;
