import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  changeFormAuction,
  createAuction,
  resetFormAuction,
  setModalAuctionsConfig,
  updateAuction,
} from "../../../redux/modules/auctions/actions";
import {
  getFormAuctions,
  getModalAuctionsConfig,
  getResponseAuctions,
} from "../../../redux/modules/auctions/selectors";
import { getCategories } from "../../../redux/modules/categories/actions";
import { getActiveCategoriesLoaded } from "../../../redux/modules/categories/selectors";
import { getCompanies } from "../../../redux/modules/companies/actions";
import { getActiveCompaniesLoaded } from "../../../redux/modules/companies/selectors";
import {
  getCitiesByDepartment,
  getCountries,
  getDepartmentsByCountry,
} from "../../../redux/modules/locations/actions";
import {
  getCitiesLoaded,
  getCountriesLoaded,
  getDepartmentsLoaded,
} from "../../../redux/modules/locations/selectors";
import { MESSAGES } from "../../../utils/messages";
import CommonModal from "../../ui/CommonModal";
import CommonPanel from "../../ui/CommonPanel";
import { RequiredIcon } from "../../ui/custom-icons";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { AddImagesButton } from "../../ui/AddImagesButton";
import { UploadedImageCard } from "../../ui/UploadedImageCard";
const { REACT_APP_ASSETS_URL } = process.env;

function FormAuction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useSelector(getFormAuctions);
  const response = useSelector(getResponseAuctions);
  const countries = useSelector(getCountriesLoaded);
  const departments = useSelector(getDepartmentsLoaded);
  const cities = useSelector(getCitiesLoaded);
  const categories = useSelector(getActiveCategoriesLoaded);
  const companies = useSelector(getActiveCompaniesLoaded);
  const modalAuctionsConfig = useSelector(getModalAuctionsConfig);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    title: "",
    message: "",
    type: "edit",
    data: "",
  });
  const [base64Files, setBase64Files] = useState([]);

  const [editorState, setEditorState] = useState(() =>
    form.description
      ? EditorState.createWithContent(convertFromRaw(form.description))
      : EditorState.createEmpty()
  );

  useEffect(() => {
    dispatch(getCompanies.trigger());
    dispatch(getCategories.trigger());
  }, []);

  useEffect(() => {
    if (response.message) {
      navigate("/auctions");
    }
  }, [response]);

  useEffect(() => {
    dispatch(getCountries.trigger());
    if (form.countryId) {
      dispatch(getDepartmentsByCountry.trigger(form.countryId));
      if (form.departmentId) {
        dispatch(getCitiesByDepartment.trigger(form.departmentId));
      }
    }
  }, []);

  const handleChangeInput = async (propertyValue, propertyName) => {
    switch (propertyName) {
      case "description":
        setEditorState(propertyValue);
        const rawTemp = convertToRaw(editorState.getCurrentContent());
        const descriptionRaw = JSON.stringify(rawTemp);
        dispatch(
          changeFormAuction({ value: descriptionRaw, name: "description" })
        );

        break;
      case "files":
        const files = propertyValue;
        let newFiles = [];
        let base64Temp = [];
        for (let index = 0; index < files.length; index++) {
          const { fileName, base64, file } = files[index];
          let foundImg = base64Files.find((x) => x["name"] === fileName);
          if (!foundImg) {
            base64Temp.push({
              name: fileName,
              base64: base64,
            });
            newFiles.push(file);
          }
        }
        dispatch(changeFormAuction({ value: newFiles, name: propertyName }));
        setBase64Files(base64Files.concat(base64Temp));
        break;

      default:
        dispatch(
          changeFormAuction({ value: propertyValue, name: propertyName })
        );
        break;
    }
  };

  const handleSubmitForm = () => {
    if (isValidForm()) {
      if (form.isNew) {
        dispatch(createAuction.trigger());
      } else {
        setModalConfig({
          show: true,
          type: "edit",
          title: "Actualizar Subasta",
          message: (
            <div className="w-full">
              <p className="text-center">
                ¿Desea guardar los cambios en la subasta? <br></br>
                <span style={{ fontWeight: 700 }}>{form.name}</span>
              </p>
            </div>
          ),
          data: { id: form.id },
        });
      }
    } else {
      toast(MESSAGES.REQUIRED_FIELDS, {
        type: "error",
      });
    }
  };

  const handleCancel = () => {
    if (form.isNew) {
      dispatch(resetFormAuction());
    } else {
      dispatch(
        setModalAuctionsConfig({
          ...modalAuctionsConfig,
          show: false,
          data: null,
        })
      );
      dispatch(resetFormAuction());
    }
  };

  const isValidForm = () => {
    let hasImage = true;
    if (form?.isNew) {
      hasImage = form?.files.length > 0;
    }

    return (
      form?.companyId &&
      form?.code &&
      form?.name &&
      form?.description &&
      form?.categoryId &&
      form?.cityId &&
      form?.minimumPrice &&
      form?.reservePrice &&
      form?.minimumIncrease &&
      form?.commission &&
      form?.startAt &&
      hasImage
    );
  };

  const handleModalClose = () => {
    setModalConfig({ ...modalConfig, show: false });
  };

  const handleModalOk = () => {
    switch (modalConfig.type) {
      case "edit":
        dispatch(updateAuction.trigger());
        setModalConfig({ ...modalConfig, show: false, data: null });
        dispatch(
          setModalAuctionsConfig({
            ...modalAuctionsConfig,
            show: false,
            data: null,
          })
        );
        break;
    }
  };

  const handleRemoveImage = ({ index, id }, type) => {
    if (type === "base64") {
      if (base64Files.length > 0) {
        if (base64Files.length == 1) {
          setBase64Files([]);
        } else {
          let newFiles = base64Files.filter((value, i) => i !== index);
          setBase64Files(newFiles);
        }
      }
    } else {
      const file = form.images[index];
      let currentImages = form.images;
      let images = [];
      if (currentImages.length > 0) {
        if (currentImages.length == 1) {
          images = [];
          if (file.url === form.urlImage) {
            dispatch(changeFormAuction({ value: "", name: "urlImage" }));
          }
        } else {
          images = currentImages.filter((x) => x.id !== id);
          if (file.url === form.urlImage) {
            dispatch(
              changeFormAuction({ value: images[0].url, name: "urlImage" })
            );
          }
        }
        dispatch(changeFormAuction({ value: images, name: "images" }));
        dispatch(changeFormAuction({ value: [id], name: "removeImages" }));
      }
    }
  };

  const handleSelectDefaultImage = ({ index, id }, type, name) => {
    if (type === "base64") {
      dispatch(changeFormAuction({ value: index, name: "defaultImage" }));
    } else {
      const file = form.images[index];
      if (file && file.url !== form.urlImage) {
        dispatch(changeFormAuction({ value: file.url, name: "urlImage" }));
      }
      if (form.defaultImage !== "") {
        dispatch(changeFormAuction({ value: "", name: "defaultImage" }));
      }
    }
  };

  const handleChangeCountry = (newValue) => {
    dispatch(changeFormAuction({ value: newValue, name: "countryId" }));
    dispatch(changeFormAuction({ value: "", name: "departmentId" }));
    dispatch(changeFormAuction({ value: "", name: "cityId" }));
    dispatch(getDepartmentsByCountry.trigger(newValue));
  };

  const handleChangeDepartment = (newValue) => {
    dispatch(changeFormAuction({ value: newValue, name: "departmentId" }));
    dispatch(changeFormAuction({ value: "", name: "cityId" }));
    dispatch(getCitiesByDepartment.trigger(newValue));
  };

  const startButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <NavLink to="/auctions">Atrás</NavLink>
    </div>
  );

  const endButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <button className="btn btn-secondary mr-1" onClick={handleCancel}>
        {form?.isNew ? "Limpiar" : "Cancelar"}
      </button>
      <button
        className="btn btn-primary ml-1"
        disabled={!isValidForm()}
        onClick={handleSubmitForm}
      >
        Guardar
      </button>
    </div>
  );

  return (
    <CommonPanel
      padding={form?.isNew ? "p-4" : ""}
      variant="form"
      title={`${form?.isNew ? "Agregar" : "Editar"} Subasta`}
      startButtons={form?.isNew && startButtons}
      endButtons={endButtons}
    >
      <div className="w-full p-6 rounded">
        <form className="w-full flex-column" id="formAuction">
          <legend>Información de Subasta</legend>
          <div className="row">
            <div className="col-md-4">
              <label className="w-full">
                <RequiredIcon />
                Código
              </label>
              <input
                className="form-control"
                name="code"
                maxLength="100"
                value={form?.code}
                required
                onChange={(e) => handleChangeInput(e.target.value, "code")}
              ></input>
            </div>
            <div className="col-md-4">
              <label className="w-full">
                <RequiredIcon />
                Empresa
              </label>
              <select
                className="form-select"
                name="companyId"
                maxLength="36"
                value={form?.companyId}
                required
                onChange={(e) => handleChangeInput(e.target.value, "companyId")}
              >
                <option value="">--Seleccionar--</option>
                {companies &&
                  companies.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-4">
              <label>
                <RequiredIcon />
                Tipo Subasta
              </label>
              <br />
              <label className="mr-4">
                <input
                  type="radio"
                  onChange={(e) =>
                    handleChangeInput(e.target.value, "auctionType")
                  }
                  name="auctionType"
                  value="Subasta"
                  checked={"Subasta" === form?.auctionType}
                />{" "}
                Subasta
              </label>
              <label className="mr-4">
                <input
                  type="radio"
                  onChange={(e) =>
                    handleChangeInput(e.target.value, "auctionType")
                  }
                  name="auctionType"
                  value="Venta"
                  checked={"Venta" === form?.auctionType}
                />{" "}
                Venta Directa
              </label>

            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="w-full">
                <RequiredIcon />
                Título
              </label>
              <input
                className="form-control"
                required
                name="name"
                maxLength="100"
                value={form?.name}
                onChange={(e) => handleChangeInput(e.target.value, "name")}
              />
            </div>
            <div className="col-md-6">
              <label className="w-full">
                <RequiredIcon />
                Categoría
              </label>
              <select
                className="form-select"
                name="categoryId"
                maxLength="36"
                required
                value={form?.categoryId}
                onChange={(e) =>
                  handleChangeInput(e.target.value, "categoryId")
                }
              >
                <option value="">--Seleccionar--</option>
                {categories &&
                  categories.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-12">
              <label className="w-full">Link Requisitos</label>
              <input
                className="form-control"
                name="requirementsLink"
                required
                value={form?.requirementsLink}
                onChange={(e) =>
                  handleChangeInput(e.target.value, "requirementsLink")
                }
              />
            </div>
            <div className="col-md-12">
              <label className="w-full">Descripción Corta</label>
              <textarea
                className="form-control"
                name="shortDescription"
                required
                maxLength={500}
                value={form?.shortDescription}
                onChange={(e) =>
                  handleChangeInput(e.target.value, "shortDescription")
                }
              />
            </div>
            <div className="col-md-12">
              <label className="w-full">
                <RequiredIcon />
                Descripción
              </label>
              <Editor
                style={{ minHeight: "400px" }}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => handleChangeInput(e, "description")}
              />
            </div>
          </div>
          <legend>Detalle Ubicación</legend>
          <div className="row">
            <div className="col-md-6">
              <label className="w-full">
                <RequiredIcon />
                País
              </label>
              <select
                className="form-select"
                name="countryId"
                maxLength="36"
                required
                value={form?.countryId}
                onChange={(e) => handleChangeCountry(e.target.value)}
                disabled={countries && countries.length === 0}
              >
                <option value="">--Seleccionar--</option>
                {countries &&
                  countries.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-6">
              <label className="w-full">
                <RequiredIcon />
                Departamento
              </label>
              <select
                className="form-select"
                required
                name="departmentId"
                maxLength="36"
                value={form?.departmentId}
                onChange={(e) => handleChangeDepartment(e.target.value)}
                disabled={departments && departments.length === 0}
              >
                <option value="">--Seleccionar--</option>
                {departments &&
                  departments.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-6">
              <label className="w-full">
                <RequiredIcon />
                Ciudad
              </label>
              <select
                className="form-select"
                name="cityId"
                maxLength="36"
                required
                value={form?.cityId}
                onChange={(e) => handleChangeInput(e.target.value, "cityId")}
                disabled={cities && cities.length === 0}
              >
                <option value="">--Seleccionar--</option>
                {cities &&
                  cities.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-6">
              <label className="w-full">
                <RequiredIcon />
                Dirección
              </label>
              <input
                className="form-control"
                name="address"
                maxLength="250"
                required
                value={form?.address}
                onChange={(e) => handleChangeInput(e.target.value, "address")}
              />
            </div>
          </div>
          <legend>Detalles de Subasta</legend>
          <div className="row">
            <div className="col-md-6">
              <label className="w-full">
                <RequiredIcon />
                Precio Mínimo
              </label>
              <input
                className="form-control"
                name="minimumPrice"
                maxLength="11"
                required
                type="number"
                value={form?.minimumPrice}
                onChange={(e) =>
                  handleChangeInput(e.target.value, "minimumPrice")
                }
              />
            </div>
            <div className="col-md-6">
              <label className="w-full">
                <RequiredIcon />
                Valor Reserva
              </label>
              <input
                className="form-control"
                name="reservePrice"
                type="number"
                maxLength="11"
                required
                value={form?.reservePrice}
                onChange={(e) =>
                  handleChangeInput(e.target.value, "reservePrice")
                }
              />
            </div>
            <div className="col-md-6">
              <label className="w-full">
                <RequiredIcon />
                Aumento Mínimo
              </label>
              <input
                className="form-control"
                name="minimumIncrease"
                type="number"
                maxLength="11"
                required
                value={form?.minimumIncrease}
                onChange={(e) =>
                  handleChangeInput(e.target.value, "minimumIncrease")
                }
              />
            </div>
            <div className="col-md-6">
              <label className="w-full">
                <RequiredIcon />
                Comisión %
              </label>
              <input
                className="form-control"
                name="commission"
                type="number"
                min={0}
                max={100}
                required
                value={form?.commission}
                onChange={(e) =>
                  handleChangeInput(e.target.value, "commission")
                }
              />
            </div>
            <div className="col-md-6">
              <label className="w-full">
                <RequiredIcon />
                Fecha Inicio
              </label>
              <input
                className="form-control"
                type="datetime-local"
                name="startAt"
                required
                value={form?.startAt}
                onChange={(e) => handleChangeInput(e.target.value, "startAt")}
              />
            </div>
            <div className="col-md-6">
              <label className="w-full">
                <RequiredIcon />
                Fecha Cierre
              </label>
              <input
                className="form-control"
                type="datetime-local"
                name="closeAt"
                required
                value={form?.closeAt}
                onChange={(e) => handleChangeInput(e.target.value, "closeAt")}
              />
            </div>
          </div>
          <legend>
            <RequiredIcon /> Imagenes de Subasta
            <AddImagesButton
              limit={25}
              onHandleChangeInput={(files) => handleChangeInput(files, "files")}
            ></AddImagesButton>
          </legend>

          <div className="row">
            {base64Files?.map((file, index) => (
              <UploadedImageCard
                isActive={form.defaultImage === index}
                onHandleSelectDefaultImage={(e) =>
                  handleSelectDefaultImage(e, "base64", file.name)
                }
                onHandleRemoveImage={(e) =>
                  handleRemoveImage(e, "base64", file.name)
                }
                index={index}
                base64={file.base64}
              ></UploadedImageCard>
            ))}
            {form.images?.map((file, index) => (
              <UploadedImageCard
                isActive={
                  file.url === form.urlImage && form.defaultImage === ""
                }
                onHandleSelectDefaultImage={(e) =>
                  handleSelectDefaultImage(e, "url")
                }
                onHandleRemoveImage={(e) => handleRemoveImage(e, "url")}
                index={index}
                id={file.id}
                url={`${REACT_APP_ASSETS_URL}/${file.url}`}
              ></UploadedImageCard>
            ))}
          </div>
        </form>
      </div>
      <CommonModal
        config={modalConfig}
        handleModalClose={handleModalClose}
        handleModalOk={handleModalOk}
      ></CommonModal>
    </CommonPanel>
  );
}

export default FormAuction;
