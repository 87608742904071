//AUCTIONS
export const GET_AUCTION_BY_ID = "GET_AUCTION_BY_ID";
export const SHOULD_STOP_UPDATE_RESOURCES = "SHOULD_STOP_UPDATE_RESOURCES";
export const GET_AUCTIONS = "GET_AUCTIONS";
export const CREATE_AUCTION = "CREATE_AUCTION";
export const UPDATE_AUCTION = "UPDATE_AUCTION";
export const DELETE_AUCTION = "DELETE_AUCTION";
export const CHANGE_STATUS_AUCTION = "CHANGE_STATUS_AUCTION";
export const CHANGE_FORM_AUCTION = "CHANGE_FORM_AUCTION";
export const CLEAR_RESPONSE_FORM_AUCTION = "CLEAR_RESPONSE_FORM_AUCTION";
export const CLEAR_RESPONSE_AUCTIONS = "CLEAR_RESPONSE_AUCTIONS";
export const LOAD_FORM_AUCTION = "LOAD_FORM_AUCTION";
export const SET_TEMP_OFFER_PRICE = "SET_TEMP_OFFER_PRICE";
export const SET_MODAL_AUCTIONS_CONFIG = "SET_MODAL_AUCTIONS_CONFIG";
export const SET_AUCTIONS_FILTER = "SET_AUCTIONS_FILTER";
export const RESET_FORM_AUCTION = "RESET_FORM_AUCTION";

export const REQUEST_ASSOCIATE_AUCTION = "REQUEST_ASSOCIATE_AUCTION";

export const REQUEST_OFFERER_PROCESS = "REQUEST_OFFERER_PROCESS"
export const GET_OFFERS_BY_AUCTION_ID = "GET_OFFERS_BY_AUCTION_ID";
export const SET_SELECTED_AUCTION_ID =
  "SET_SELECTED_AUCTION_ID";
export const SET_OFFERERS_FILTER = "SET_OFFERERS_FILTER";
export const SET_OFFERS_FILTER = "SET_OFFERS_FILTER";
export const SET_PENDING_OFFERERS_FILTER = "SET_PENDING_OFFERERS_FILTER";

//AUCTIONS LANDING PAGE
export const GET_ACTIVE_AUCTIONS = "GET_ACTIVE_AUCTIONS";
export const GET_CURRENT_AUCTION_BY_ID = "GET_CURRENT_AUCTION_BY_ID";
export const SET_ACTIVE_AUCTIONS_FILTER = "SET_ACTIVE_AUCTIONS_FILTER";
export const UPDATE_AUCTION_BY_ID = "UPDATE_AUCTION_BY_ID";
export const ADD_ACTIVE_AUCTION = "ADD_ACTIVE_AUCTION";
export const UPDATE_ACTIVE_AUCTION = "UPDATE_ACTIVE_AUCTION";
export const FINISH_ACTIVE_AUCTION = "FINISH_ACTIVE_AUCTION";

