import React from "react";
import { Route, Routes } from "react-router-dom";

import Auctions from "../Auctions";
import DetailAuction from "../Auctions/DetailAuction";
import FormAuction from "../Auctions/FormAuction";
import GridAuctions from "../Auctions/GridAuctions";
import OfferersByAuction from "../Auctions/OfferersByAuction";
import OffersByAuction from "../Auctions/OffersByAuction";
import TableOffererRequests from "../Auctions/TableOffererRequests";
import Categories from "../Categories";
import Companies from "../Companies";
import FormCompany from "../Companies/FormCompany";
import ForgotPassword from "../ForgotPassword";
import Home from "../Home";
import LandingPage from "../LandingPage";
import Layout from "../Layout";
import Login from "../Login";
import MyAuctions from "../MyAuctions";
import OffererProfile from "../OffererProfile";
import Offerers from "../Offerers";
import FormOfferer from "../Offerers/FormOfferer";
import Setup from "../Setup";
import SignIn from "../SignIn";
import Users from "../Users";
import FormUser from "../Users/FormUser";
import TablePendingUsers from "../Users/TablePendingUsers";
import GridSales from "../Auctions/GridSales";
import DetailSale from "../Auctions/DetailSale";

export default function MainRoute({ user }) {
  const getRoutes = (type) => {
    switch (type) {
      case "admin":
        return (
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/setup" element={<Setup />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/companies/form" element={<FormCompany />} />
            <Route path="/auctions" element={<Auctions />} />
            <Route path="/auctions/form" element={<FormAuction />} />
            <Route
              path="/auctions/requests"
              element={<TableOffererRequests />}
            />
            <Route
              path="/auctions/offerers/:id"
              element={<OfferersByAuction />}
            />
            <Route path="/auctions/offers/:id" element={<OffersByAuction />} />
            <Route path="/auctions/grid" element={<GridAuctions />} />
            <Route
              path="/auction/detail/:id"
              exact
              element={<DetailAuction />}
            />
            <Route
              path="/sale/detail/:id"
              exact
              element={<DetailSale />}
            />
            <Route path="/offerers" element={<Offerers />} />
            <Route path="/offerers/form" element={<FormOfferer />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/form" element={<FormUser />} />
            <Route path="/users/pending" element={<TablePendingUsers />} />
            <Route path="*" element={<Home />} />
          </Routes>
        );
        break;
      case "offerer":
        return (
          <Routes>
            <Route path="/" exact element={<GridAuctions />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/auctions/grid" element={<GridAuctions />} />
            <Route path="/sales/grid" element={<GridSales />} />
            <Route
              path="/auction/detail/:id"
              exact
              element={<DetailAuction />}
            />
            <Route
              path="/sale/detail/:id"
              exact
              element={<DetailSale />}
            />
            <Route path="/my-auctions" element={<MyAuctions />} />
            <Route path="/profile" element={<OffererProfile />} />
          </Routes>
        );
        break;
      default:
        return (
          <Routes>
            <Route path="/" exact element={<LandingPage />} />
            <Route path="/view-auctions" exact element={<GridAuctions />} />
            <Route path="/view-sales" exact element={<GridSales />} />
            <Route
              path="/auction/detail/:id"
              exact
              element={<DetailAuction />}
            />
            <Route
              path="/sales/detail/:id"
              exact
              element={<DetailSale />}
            />
            <Route path="/login" exact element={<Login />} />
            <Route path="/signIn" element={<SignIn />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="*" element={<LandingPage />} />
          </Routes>
        );
    }
  };
  return <Layout>{getRoutes(user ? user.userType : "landing")}</Layout>;
}
