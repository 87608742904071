import * as types from "./types";
import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";

export const getAuctionById = createRoutine(types.GET_AUCTION_BY_ID);
export const getAuctions = createRoutine(types.GET_AUCTIONS);

export const createAuction = createRoutine(types.CREATE_AUCTION);
export const updateAuction = createRoutine(types.UPDATE_AUCTION);
export const deleteAuction = createRoutine(types.DELETE_AUCTION);
export const changeStatusAuction = createRoutine(types.CHANGE_STATUS_AUCTION);
export const requestAssociateAuction = createRoutine(
  types.REQUEST_ASSOCIATE_AUCTION
);

export const requestOffererProcess = createRoutine(
  types.REQUEST_OFFERER_PROCESS
);


export const changeFormAuction = createAction(types.CHANGE_FORM_AUCTION);
export const addActiveAuction = createAction(types.ADD_ACTIVE_AUCTION);
export const finishActiveAuction = createAction(types.FINISH_ACTIVE_AUCTION);
export const updateActiveAuction = createAction(types.UPDATE_ACTIVE_AUCTION);
export const clearResponseFormAuction = createAction(
  types.CLEAR_RESPONSE_FORM_AUCTION
);
export const loadFormAuction = createAction(types.LOAD_FORM_AUCTION);
export const setTempOfferPrice = createAction(types.SET_TEMP_OFFER_PRICE);
export const clearResponseAuctions = createAction(
  types.CLEAR_RESPONSE_AUCTIONS
);
export const setModalAuctionsConfig = createAction(
  types.SET_MODAL_AUCTIONS_CONFIG
);
export const updateAuctionById = createAction(
  types.UPDATE_AUCTION_BY_ID
);
export const setAuctionsFilter = createAction(types.SET_AUCTIONS_FILTER);
export const resetFormAuction = createAction(types.RESET_FORM_AUCTION);
export const setSelectedAuctionId = createAction(types.SET_SELECTED_AUCTION_ID);
export const setOfferersFilter = createAction(types.SET_OFFERERS_FILTER);
export const setOffersFilter = createAction(types.SET_OFFERS_FILTER);
export const setPendingOfferersFilter = createAction(
  types.SET_PENDING_OFFERERS_FILTER
);

export const getActiveAuctions = createRoutine(types.GET_ACTIVE_AUCTIONS);
export const getCurrentAuctionById = createRoutine(
  types.GET_CURRENT_AUCTION_BY_ID
);
export const setActiveAuctionsFilter = createAction(
  types.SET_ACTIVE_AUCTIONS_FILTER
);
