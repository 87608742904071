import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import Countdown from "../pages/Auctions/Countdown";
import { CountBadge } from "./CommonBadges";

const { REACT_APP_ASSETS_URL } = process.env;

function CommonCarousel({ items, carouselType, style }) {
  switch (carouselType) {
    case "images":
      return (
        <Carousel style={{ ...style }}>
          {items &&
            items.length &&
            items.map((item, index) => {
              return (
                <Carousel.Item key={`carousel-img-${index}`}>
                  <img
                    style={{ ...style, maxHeight: "700px" }}
                    className="d-block w-100"
                    alt=""
                    src={`${REACT_APP_ASSETS_URL}/${item.urlFile}`}
                  />
                </Carousel.Item>
              );
            })}
        </Carousel>
      );
      break;

    case "auctionDetail":
      return (
        <Carousel style={{ ...style }}>
          {items &&
            items.length &&
            items.map((item, index) => {
              return (
                <Carousel.Item key={`carousel-img-${index}`}>
                  <div className="absolute z-20 pt-3 pl-4">
                    <CountBadge
                      count={
                        <div className="text-sm">
                          {index + 1}/{items.length}
                        </div>
                      }
                    />
                  </div>
                  <img
                    className="card-img-top"
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "600px",
                    }}
                    src={`${REACT_APP_ASSETS_URL}/${item.url}`}
                  />
                </Carousel.Item>
              );
            })}
        </Carousel>
      );

    default:
      return (
        <Carousel>
          {items &&
            items.length &&
            items.map((item, index) => {
              return (
                <Carousel.Item interval={index * 2000}>
                  <Link to={`auction/detail/${item.id}`}>
                    <div className="w-full flex px-8 py-2">
                      <img
                        className="d-block w-1/3 h-80 rounded-l-lg"
                        src={`${REACT_APP_ASSETS_URL}/${item.urlImage}`}
                        alt="Some"
                      />
                      <div className="w-2/3 bg-white rounded-r-lg">
                        <div>
                          <div className="pt-4 pl-4 mb-8">
                            <CountBadge
                              count={
                                <>
                                  <Countdown
                                    key={item.id}
                                    auctionId={item.id}
                                    countdownDate={new Date(
                                      item.closeAt
                                    ).getTime()}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div className="px-12 py-4 text-3xl mb-2 text-black-900 font-sans">
                            <span className="text-black-50">{item.name}:</span>
                            <p className="truncate text-ellipsis overflow-hidden px-4">
                              {item.shortDescription}
                            </p>
                          </div>

                          <hr className="text-black-50 mx-4" />
                          <div className="px-4 pt-2">
                            <div className="text-xs text-black-50">
                              Valor actual:
                            </div>
                            <div className="pl-8 text-3xl text-blue-600 ">{`${new Intl.NumberFormat(
                              "es-CO"
                            ).format(item.reservePrice)}`}</div>
                            <div className="text-xs text-black-50">{`${item.offerersCount} participantes`}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Carousel.Item>
              );
            })}
        </Carousel>
      );
  }
}

export default CommonCarousel;
