import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { CommonTooltip } from "./CommonTooltip";
const CommonButtonIcon = ({ icon, onHandleClick, title }) => {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250 }}
        overlay={CommonTooltip(title)}
      >
        <span className="icon-button-row-table" onClick={onHandleClick}>
          {icon}
        </span>
      </OverlayTrigger>
    );
  };

  export default CommonButtonIcon;