import {
  faLocationDot,
  faUsd,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getActiveAuctions } from "../../../redux/modules/auctions/actions";
import { getActiveAuctionsLoaded } from "../../../redux/modules/auctions/selectors";
import { CountBadge } from "../../ui/CommonBadges";
import Countdown from "../Auctions/Countdown";
const { REACT_APP_ASSETS_URL } = process.env;
export default function AuctionsSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auctions = useSelector(getActiveAuctionsLoaded);

  useEffect(() => {
    dispatch(getActiveAuctions.trigger());
  }, []);

  return (
    <section className="page-section bg-light" id="auctions">
      <div className="container">
        <div
          style={{ background: "#005493" }}
          className="text-uppercase text-white text-5xl text-center py-2 rounded-md"
        >
          Subastas activas
        </div>

        <div className="row p-2">
          <Carousel className="col-md-12">
            {auctions.length > 0 &&
              auctions.map((item, index) => (
                <Carousel.Item interval={4000}>
                  <Link key={item.id} to={`auction/detail/${item.id}`}>
                    <div
                      style={{ minHeight: "300px" }}
                      className="row m-1 border-1 rounded shadow-sm"
                    >
                      <div className="col-md-4 p-2">
                        <div
                          className="card-img-top"
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundImage: `url('${REACT_APP_ASSETS_URL}/${item.urlImage}')`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="flex flex-col p-3 h-full justify-between">
                          <div className="flex flex-col">
                            <div className="flex flex-row justify-between">
                              <span
                                style={{ fontSize: "30px" }}
                                className="text-black font-bold "
                              >
                                {item.name}
                              </span>
                              <div
                                style={{
                                  fontWeight: 600,
                                  backgroundColor: "#005493",
                                }}
                                className="text-3xl text-white rounded p-2"
                              >{`${new Intl.NumberFormat("es-CO").format(
                                item.reservePrice
                              )}`}</div>
                            </div>
                            <div className="w-full truncate text-ellipsis overflow-hidden  pt-2">
                              {item.shortDescription}
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <div className="w-full flex flex-row pb-1">
                              <span className="text-black font-semibold text-base">
                                <FontAwesomeIcon icon={faLocationDot} />
                                {` ${item.departmentName}/${item.cityName}`}
                              </span>
                            </div>
                            <div className="border-t-[1px] pt-2 flex flex-row mb-4 justify-between">
                              <div className="text-lg text-black-50">
                                <span className="">
                                  {" "}
                                  <FontAwesomeIcon icon={faUserCircle} />
                                  {` ${item.offerersCount}`}
                                </span>
                                <span className="">
                                  {" "}
                                  <FontAwesomeIcon icon={faUsd} />
                                  {` ${item.offersCount}`}
                                </span>
                              </div>
                              <CountBadge
                                className="text-lg"
                                count={
                                  <>
                                    <Countdown
                                      key={item.id}
                                      auctionId={item.id}
                                      countdownDate={new Date(
                                        item.closeAt
                                      ).getTime()}
                                    />
                                  </>
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Carousel.Item>
              ))}
            {auctions.length === 0 && (
              <Carousel.Item interval={4000}>
                <div
                  role="status"
                  className="w-full h-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
                >
                  <div className="flex flex-column justify-between">
                    <div className="w-full h-20 bg-gray-300 rounded-full dark:bg-gray-300 mb-2"></div>
                    <div className="w-full h-20 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></div>

                    <div className="w-full h-20 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></div>

                    <div className="w-full h-20 bg-gray-200 rounded-full dark:bg-gray-300 mb-2"></div>
                  </div>
                </div>
              </Carousel.Item>
            )}
          </Carousel>
        </div>
        {auctions.length > 0 && (
          <div className="flex flex-row justify-center py-2">
            <div
              style={{ backgroundColor: "#005493", width: "200px" }}
              className="cursor-pointer text-center p-2 text-white rounded"
              onClick={() => navigate("/view-auctions")}
            >
              Ver Todas
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
