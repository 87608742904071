export const offerersState = (state) => state.offerers;

export const getLoadingOfferers = (state) => offerersState(state).loading;

export const getOfferersLoaded = (state) => offerersState(state).offerers;

export const getResponseOfferers = (state) => offerersState(state).response;

export const getOfferersList = (state) => {
  const offerers = offerersState(state).offerers;
  const status = offerersState(state).filters.status;
  const search = offerersState(state).filters.search;
  let itemsFiltered = offerers;
  if (status && status != "Todos") {
    itemsFiltered = itemsFiltered.filter(
      (offerer) => offerer.status === status
    );
  }
  if (search) {
    itemsFiltered = itemsFiltered.filter(
      (offerer) =>
        offerer.userName.toUpperCase().indexOf(search.toUpperCase()) != -1
    );
  }

  return itemsFiltered;
};

export const getFormOfferers = (state) => offerersState(state).form;

export const getModalOfferersConfig = (state) => offerersState(state).modal;