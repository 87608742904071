import produce from "immer";
import {
  getCompanies,
  createCompany,
  updateCompany,
  deleteCompany,
  changeStatusCompany,
} from "./actions";
import { initialState } from "./states";
import * as types from "./types";

export const companies = produce((draft, { type, payload }) => {
  switch (type) {
    case getCompanies.TRIGGER:
      draft.loading = true;
      break;
    case getCompanies.REQUEST:
      break;
    case getCompanies.SUCCESS:
      draft.companies = payload;
      break;
    case getCompanies.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getCompanies.FULFILL:
      draft.loading = false;
      break;

    case createCompany.TRIGGER:
      draft.loading = true;
      break;
    case createCompany.REQUEST:
      break;
    case createCompany.SUCCESS:
      draft.response.message = payload.message;
      draft.response.result = "SUCCESS";
      draft.form = initialState.form;
      break;
    case createCompany.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case createCompany.FULFILL:
      draft.loading = false;
      break;

    case updateCompany.TRIGGER:
      draft.loading = true;
      break;
    case updateCompany.REQUEST:
      break;
    case updateCompany.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      draft.form = initialState.form;
      break;
    case updateCompany.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      draft.form = initialState.form;
      break;
    case updateCompany.FULFILL:
      draft.loading = false;
      break;

    case deleteCompany.TRIGGER:
      draft.loading = true;
      break;
    case deleteCompany.REQUEST:
      break;
    case deleteCompany.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      break;
    case deleteCompany.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case deleteCompany.FULFILL:
      draft.loading = false;
      break;

    case changeStatusCompany.TRIGGER:
      draft.loading = true;
      break;
    case changeStatusCompany.REQUEST:
      break;
    case changeStatusCompany.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      break;
    case changeStatusCompany.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case changeStatusCompany.FULFILL:
      draft.loading = false;
      break;

    case types.CLEAR_RESPONSE_COMPANIES:
      draft.response = initialState.response;
      break;

    case types.CHANGE_FORM_COMPANY:
      if (payload.name === "files") {
        draft.form.files = draft.form.files.concat(payload.value);
      } else {
        draft.form[payload.name] = payload.value;
      }
      break;
    case types.RESET_FORM_COMPANY:
      draft.form = initialState.form;
      break;
    case types.LOAD_FORM_COMPANY:
      draft.form.id = payload.id;
      draft.form.documentType = payload.documentType;
      draft.form.document = payload.document;
      draft.form.name = payload.name;
      draft.form.description = payload.description;
      draft.form.phone = payload.phone;
      draft.form.cellPhone = payload.cellPhone;
      draft.form.countryId = payload.countryId;
      draft.form.departmentId = payload.departmentId;
      draft.form.cityId = payload.cityId;
      draft.form.address = payload.address;
      draft.form.images = payload.images;
      draft.form.urlImage = payload.urlImage;
      draft.form.isNew = false;
      break;
    case types.SET_MODAL_COMPANIES_CONFIG:
      draft.modal = payload;
      break;
    case types.SET_COMPANIES_FILTER:
      draft.filters[payload.propertyName] = payload.propertyValue;
      break;
  }
}, initialState);
