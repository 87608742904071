import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import {
  doLoginUser,
  doForgotPassword,
  doChangePassword,
  tryVerificationCode,
} from "./actions";
import { setUserApp } from "../app/actions";
import { getFormLogin } from "./selectors";

function* doLoginUserSaga({ payload }) {
  try {
    yield put(doLoginUser.request());
    const form = yield select(getFormLogin);
    const body = {
      email: form.email,
      password: form.password,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      "auth/login",
      {},
      {},
      body
    );
    yield put(doLoginUser.success(response.data));
    yield put(setUserApp(response.data.user));
  } catch (e) {
    if (e.statusCode === 340) {
      yield put(
        doLoginUser.failure({
          error: e ? String(e.message) : "Error de conexión",
          result: "ValidationCode",
        })
      );
    } else {
      yield put(
        doLoginUser.failure({
          error: e ? String(e.message) : "Error de conexión",
        })
      );
    }
  } finally {
    yield put(doLoginUser.fulfill());
  }
}

function* doForgotPasswordSaga({ payload }) {
  try {
    yield put(doForgotPassword.request());
    const form = yield select(getFormLogin);
    const body = {
      email: form.email,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      "auth/forgotPassword",
      {},
      {},
      body
    );
    yield put(doForgotPassword.success(response));
  } catch (e) {
    if (e.statusCode === 340) {
      yield put(
        doForgotPassword.failure({
          error: e ? String(e.message) : "Error de conexión",
          result: "ValidationCode",
        })
      );
    } else {
      yield put(
        doForgotPassword.failure({
          error: e ? String(e.message) : "Error de conexión",
        })
      );
    }
  } finally {
    yield put(doForgotPassword.fulfill());
  }
}
function* doChangePasswordSaga({ payload }) {
  try {
    yield put(doChangePassword.request());
    const form = yield select(getFormLogin);
    const body = {
      email: form.email,
      code: form.verifyCode,
      password: form.password,
    };
    const response = yield call(
      callApiAxios,
      "PATCH",
      "auth/changePassword",
      {},
      {},
      body
    );
    yield put(doChangePassword.success(response));
  } catch (e) {
    if (e.statusCode === 340) {
      yield put(
        doChangePassword.failure({
          error: e ? String(e.message) : "Error de conexión",
          result: "ValidationCode",
        })
      );
    } else {
      yield put(
        doChangePassword.failure({
          error: e ? String(e.message) : "Error de conexión",
        })
      );
    }
  } finally {
    yield put(doChangePassword.fulfill());
  }
}

function* tryVerificationCodeSaga({ payload }) {
  try {
    yield put(tryVerificationCode.request());
    const form = yield select(getFormLogin);
    const body = {
      code: form.verifyCode,
      email: form.email,
    };
    const response = yield call(
      callApiAxios,
      "PATCH",
      "auth/verificationCode",
      {},
      {},
      body
    );
    yield put(tryVerificationCode.success(response));
  } catch (e) {
    yield put(
      tryVerificationCode.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(tryVerificationCode.fulfill());
  }
}

export default function* authSaga() {
  yield takeEvery(doLoginUser.TRIGGER, doLoginUserSaga);
  yield takeEvery(tryVerificationCode.TRIGGER, tryVerificationCodeSaga);
  yield takeEvery(doForgotPassword.TRIGGER, doForgotPasswordSaga);
  yield takeEvery(doChangePassword.TRIGGER, doChangePasswordSaga);
}
