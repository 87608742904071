import React from "react";
import CommonTable from "../../../ui/CommonTable";

const TableOffers = ({ columns, items, onHandleClickRow }) => {
  const buildItems = (_items) => {
    return (
      _items &&
      _items.length > 0 &&
      _items.map((item) => (
        <tr>
          <td>{new Date(item.createdAt).toLocaleString()}</td>
          <td>{item.offererUserName}</td>
          <td>{new Intl.NumberFormat("es-CO").format(item.lastPrice)}</td>
          <td>{new Intl.NumberFormat("es-CO").format(item.offerPrice)}</td>
        </tr>
      ))
    );
  };

  return (
    <div className="w-full p-3 rounded">
      <div className="flex flex-wrap">
        <CommonTable columns={columns} items={buildItems(items)}></CommonTable>
      </div>
    </div>
  );
};

export default TableOffers;
