export const initialState = {
  loading: false,
  response: { message: null, error: null },
  category: null,
  categories: [],
  filters: {
    search: "",
    status: "Todos",
  },
  form: { isNew: true, code: "", name: "", description: "" },
};
