import Modal from "react-bootstrap/Modal";

const styleModal = {
  btnOK: {
    delete: "btn-danger",
    changeStatus: "btn-primary",
    edit: "btn-primary",
    code: "btn-primary",
    reject: "btn-danger",
    approve: "btn-primary",
    content: "btn-primary",
  },
};
export default function CommonModal({
  config,
  handleModalClose,
  handleModalOk,
  children,
  backdrop,
  keyboard,
  isOkDisabled,
}) {
  switch (config.type) {
    case "form":
      return (
        <Modal
          className="modal-form"
          show={config.show}
          onHide={handleModalClose}
          size={config.size && config.size}
        >
          <Modal.Body>{children && children}</Modal.Body>
        </Modal>
      );
      break;

    case "content":
      return (
        <Modal
          show={config.show}
          onHide={handleModalClose}
          size={config.size && config.size}
        >
       
          {children && children}
          <Modal.Footer>
            <button className={`btn btn-secondary`} onClick={handleModalClose}>
              Cancelar
            </button>
            <button
              className={`btn ${styleModal.btnOK[config.type]}`}
              onClick={handleModalOk}
            >
              Aceptar
            </button>
          </Modal.Footer>
        </Modal>
      );
      break;

    case "code":
      return (
        <Modal
          show={config.show}
          onHide={handleModalClose}
          keyboard={keyboard}
          backdrop={backdrop}
        >
          <Modal.Header closeButton>
            <Modal.Title
              className="w-full"
              style={{ fontSize: "18px", textAlign: "center" }}
            >
              {config.title}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>{children}</Modal.Body>
          <Modal.Footer>
            <button className={`btn btn-secondary`} onClick={handleModalClose}>
              Cancelar
            </button>
            {/* <button className={`btn btn-warning`} onClick={handleModalClose}>
              Reenviar
            </button> */}
            <button
              className={`btn ${styleModal.btnOK[config.type]}`}
              onClick={handleModalOk}
              disabled={isOkDisabled}
            >
              Verificar
            </button>
          </Modal.Footer>
        </Modal>
      );

    default:
      return (
        <Modal show={config.show} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title
              className="w-full"
              style={{ fontSize: "18px", textAlign: "center" }}
            >
              {config.title}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>{config.message}</Modal.Body>
          <Modal.Footer>
            <button className={`btn btn-secondary`} onClick={handleModalClose}>
              Cancelar
            </button>
            <button
              className={`btn ${styleModal.btnOK[config.type]}`}
              onClick={handleModalOk}
            >
              Confirmar
            </button>
          </Modal.Footer>
        </Modal>
      );
  }
}
