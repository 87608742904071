import produce from "immer";

import {
  getInitialImages, setInitialImages
} from "./actions";
import { initialState } from "./states";
import * as types from "./types";

export const setup = produce((draft, { type, payload }) => {
  switch (type) {
    case getInitialImages.TRIGGER:
      draft.loading = true;
      break;
    case getInitialImages.REQUEST:
      break;
    case getInitialImages.SUCCESS:
      draft.initialImages = payload;
      break;
    case getInitialImages.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getInitialImages.FULFILL:
      draft.loading = false;
      break;

    case setInitialImages.TRIGGER:
      draft.loading = true;
      break;
    case setInitialImages.REQUEST:
      break;
    case setInitialImages.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;

      break;
    case setInitialImages.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case setInitialImages.FULFILL:
      draft.loading = false;
      break;

    case types.CLEAR_RESPONSE_SETUP:
      draft.response = initialState.response;
      break;

    case types.CHANGE_FORM_SETUP:
      if (payload.name === "files") {
        draft.form.files = draft.form.files.concat(payload.value);
      } else if (payload.name === "removeImages") {
        draft.form.removeImages = draft.form.removeImages.concat(payload.value);
      } else {
        draft.form[payload.name] = payload.value;
      }

      break;
  }
}, initialState);
