import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import MainRoute from "./components/pages/Routes";
import GlobalToast from "./components/ui/GlobalToast";
import Loading from "./components/ui/Loading";
import { getLanguageApp, getUserApp } from "./redux/modules/app/selectors";
import { getIsAuth } from "./redux/modules/auth/selectors";

function App() {
  const [t, i18n] = useTranslation("global");
  const languageSelected = useSelector(getLanguageApp);
  const isAuth = useSelector(getIsAuth);
  const userApp = useSelector(getUserApp);
  useEffect(() => {
    i18n.changeLanguage(languageSelected);
  }, [languageSelected, isAuth]);

  return (
    <div className="App">
      <BrowserRouter>
        <GlobalToast></GlobalToast>
        <MainRoute isAuth={isAuth} user={userApp} />
        <Loading />
      </BrowserRouter>
    </div>
  );
}

export default App;
