import {
  faBalanceScale,
  faShoppingCart,
  faLaptop,
  faReceipt,
  faDollyFlatbed,
  faFileSignature,
  faDonate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ServicesSection() {
  return (
    <section className="page-section" id="services">
      <div className="container">
        <div className="text-center">
          <h2
            style={{ background: "#005493" }}
            className="section-heading text-uppercase text-white py-2 rounded-md"
          >
            Nuestros Servicios
          </h2>
        </div>
        <div className="row text-center">
          <div className="item-service col-md-6">
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon color="#005493" icon={faShoppingCart} />
            </span>
            <h4 className="my-3">Subastas Online</h4>
            <p className="text-muted">
              Contamos con una plataforma, segura, confiable, y un equipo de
              trabajo dinámico, estratégico y honesto, subastamos y rematamos
              bienes tangibles, e intangibles, realizamos estrategias de venta
              que van acorde a las necesidades de tu empresa, actuando siempre
              con <strong>HONESTIDAD Y TRANSPARENCIA</strong>.
            </p>
            <p className="text-muted">
              Contamos con disponibilidad 7x24, atención personalizada,
              información clara y veraz. ¡Porque tus negocios son los nuestros!
            </p>
          </div>
          <div className="item-service col-md-6">
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon color="#005493" icon={faReceipt} />
            </span>
            <h4 className="my-3">Venta Directa</h4>
            <p className="text-muted">
              Si estás interesado en adquirir algún bien tangible e intangible
              de forma directa, puedes contactarnos, estamos abiertos a escuchar
              ofertas, y realizar alianzas estratégicas que propendan al
              beneficio mutuo. Si tu empresa no quiere vender a través de
              subasta o remate, estas en el sitio correcto, realizamos un filtro
              y tenemos compradores recurrentes que estarían interesados en
              realizar una oferta por los bienes que deseas vender, garantizamos
              seriedad en el negocio y respaldamos todo el trámite entre las
              partes, y puedes venderle a Central de Subastas y Remates a través
              del servicio (Compra directa).
            </p>
          </div>
          <div className="item-service col-md-6">
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon color="#005493" icon={faDonate} />
            </span>
            <h4 className="my-3">Compra Directa</h4>
            <p className="text-muted">
              ¿No quieres vender a través de subasta y /o remate? ¡Te compramos
              tus bienes tangibles, contáctanos ya! Si quieres venderle
              directamente a Central de Subastas y Remates, puedes hacerlo,
              analizamos el negocio y realizamos una oferta justa. Así mismo,
              contamos con aliados estratégicos que tienen la capacidad
              financiera para compra inmediata.
            </p>
          </div>
          <div className="item-service col-md-6">
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon color="#005493" icon={faLaptop} />
            </span>
            <h4 className="my-3">SERVICIO INTEGRAL O 360 GRADOS</h4>
            <p className="text-muted">
              Nos ajustamos a tus necesidades, a tus propósitos y tus objetivos,
              contamos con varias propuestas y estrategias comerciales. ¿Quieres
              un servicio de venta completo? ¿no tienes personal suficiente? ¿No
              sabes cómo monitorear tus ventas, o no tienes los recursos
              disponibles? ¿requieres equipos, maquinaria, vehículos, informes,
              auditorias, y otros procesos administrativos?
            </p>
          </div>
          <div className="item-service col-md-4">
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon color="#005493" icon={faBalanceScale} />
            </span>
            <h4 className="my-3">Peritos y valuadores</h4>
            <p className="text-muted">
              Contamos con empresas aliadas, que nos permiten ofrecer este tipo
              de servicios, tenemos personal idóneo, calificado y con
              experiencia.
            </p>
          </div>

          <div className="item-service col-md-4">
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon color="#005493" icon={faFileSignature} />
            </span>
            <h4 className="my-3">Asesoría en documentación</h4>
            <p className="text-muted">
              Si requieres asesoría en documentos HSE, logística, y otros,
              puedes contactarnos, contamos con funcionarios que estarán
              dispuestos atender todas tus solicitudes.
            </p>
          </div>

          <div className="item-service col-md-4">
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon color="#005493" icon={faDollyFlatbed} />
            </span>
            <h4 className="my-3">Logística</h4>
            <p className="text-muted">
              Si requieres movimiento de mercancía a nivel nacional, y otros
              temas relacionados con logística, contáctanos, tenemos la solución
              a tu movilidad.
            </p>
          </div>
        </div>
        <div className="row text-center ">
          <div className="subtitle col-md-12 pt-2">
            <h2 className="first">
              TU SOLUCION ES CENTRAL DE SUBASTAS Y REMATES
            </h2>
            <h2 className="">CONTACTANOS</h2>
          </div>
        </div>
      </div>
    </section>
  );
}

/*<section id="services">
<div className="site-section site-section-sm bg-light">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-7 text-center">
        <div className="site-section-title">
          <h2>Nuestros Servicios</h2>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-md-6 col-lg-4">
        <a href="#" className="service text-center">
          <span className="icon flaticon-flat"></span>
          <h2 className="service-heading">Subastas y remates online</h2>
          <p>Bienes y servicios y mucho mas</p>
          <p>
            <span className="read-more">Ver más</span>
          </p>
        </a>
      </div>
      <div className="col-md-6 col-lg-4">
        <a href="#" className="service text-center">
          <span className="icon flaticon-sold"></span>
          <h2 className="service-heading">Venta Directa</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Incidunt iure qui natus perspiciatis ex odio molestia.
          </p>
          <p>
            <span className="read-more">Read More</span>
          </p>
        </a>
      </div>
      <div className="col-md-6 col-lg-4">
        <a href="#" className="service text-center">
          <span className="icon flaticon-coin"></span>
          <h2 className="service-heading">Compra Directa</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Incidunt iure qui natus perspiciatis ex odio molestia.
          </p>
          <p>
            <span className="read-more">Ver más</span>
          </p>
        </a>
      </div>
      <div className="col-md-6 col-lg-4">
        <a href="#" className="service text-center">
          <span className="icon flaticon-location"></span>
          <h2 className="service-heading">Peritos y valuadores</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Incidunt iure qui natus perspiciatis ex odio molestia.
          </p>
          <p>
            <span className="read-more">Ver más</span>
          </p>
        </a>
      </div>
      <div className="col-md-6 col-lg-4">
        <a href="#" className="service text-center">
          <span className="icon flaticon-camera"></span>
          <h2 className="service-heading">Asesoría en documentación</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Incidunt iure qui natus perspiciatis ex odio molestia.
          </p>
          <p>
            <span className="read-more">Read More</span>
          </p>
        </a>
      </div>
      <div className="col-md-6 col-lg-4">
        <a href="#" className="service text-center">
          <span className="icon flaticon-new"></span>
          <h2 className="service-heading">Logística</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Incidunt iure qui natus perspiciatis ex odio molestia.
          </p>
          <p>
            <span className="read-more">Read More</span>
          </p>
        </a>
      </div>
    </div>
  </div>
</div>
</section>*/
