import React from "react";
import { StatusBadge } from "../../../ui/CommonBadges";
import CommonOptionsRowTable from "../../../ui/CommonOptionsRowTable";
import CommonTable from "../../../ui/CommonTable";

const TableOfferers = ({ columns, items, onHandleClickRow }) => {
  const buildItems = (_items) => {
    return (
      _items &&
      _items.length > 0 &&
      _items.map((item) => (
        <tr key={item.offererId}>
          <td>{item.offerer.fullName}</td>
          <td>
            {item.offerer.documentType} {item.offerer.document}
          </td>
          <td>{item.offerer.userName}</td>
          <td>{item.offerer.email}</td>
          <td>{item.offerer.cellPhone}</td>
          {/* <td>{item.offerer.cityName}</td> */}
          <td>
            <StatusBadge status={item.status} />
          </td>
          <td>
            <CommonOptionsRowTable
              key={item.offererId}
              item={item}
              buttonsType="secondary"
              onHandleClickRow={onHandleClickRow}
            ></CommonOptionsRowTable>
          </td>
        </tr>
      ))
    );
  };

  return (
    <div className="w-full p-3 rounded">
      <div className="flex flex-wrap">
        <CommonTable columns={columns} items={buildItems(items)}></CommonTable>
      </div>
    </div>
  );
};

export default TableOfferers;
