export const authState = state => state.auth;

export const getIsAuth = state => authState(state).isAuth;

export const getToken = state => authState(state).token;

export const getFormLogin = state => authState(state).form;

export const getLoadingLogin = state => authState(state).loading;

export const getResponseLogin = state => authState(state).response;

export const getUserRemember = state => authState(state).user;

export const getRemember = state => authState(state).remember;
