export const initialState = {
  loading: false,
  response: { message: null, error: null },
  offer: null,
  offers: [],
  filters: {
    search: "",
    status: "Todos",
  },
  associatedAuctions:[],
  associatedAuctionsPending: [],
};
