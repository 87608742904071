
import * as types from './types';
import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";


export const getConfig = createRoutine(types.GET_CONFIG_APP);
export const saveConfig = createRoutine(types.SAVE_CONFIG_APP);

export const selectLanguage = createAction(types.SELECT_LANGUAGE);
export const setUserApp = createAction(types.SET_USER_APP);
export const doLogoutUserApp = createAction(types.DO_LOGOUT_USER_APP);
export const clearResponseApp = createAction(types.CLEAR_RESPONSE_APP);
export const setInitialStateApp = createAction(types.ON_INITIAL_STATE_APP);

