import React from "react";
import { theme } from "../../utils/theme";

const styleInput = {
  
  default: {
    borderRadius: "1.5em",
    border: "2px solid " + theme.colors.text,
    backgroundColor: theme.colors.background,
    transition: "all .25s",
    padding: ".5em 1em",
    textAlign: "center",
    width: "100%",
    "&:focus": {
      outline: "none",
      border: "2px solid " + theme.colors.text,
    },
    "&:hover": {
      borderColor: theme.colors.thirdVariant,
    },
  }
};

const Input = (props) => {
  const getVariant = (variant) => {
    switch (variant) {
         default:
        return <input style={styleInput["default"]} {...props} />;
    }
  };
  return getVariant(props.variant);
};

export default Input;
