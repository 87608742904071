import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  changeStatusUser,
  clearResponseUsers,
  deleteUser,
  getPendingUsers,
  getUsers,
  loadFormUser,
  setModalUsersConfig,
  setUsersFilter,
  updateUser,
} from "../../../redux/modules/users/actions";
import {
  getModalUsersConfig,
  getPendingUsersLoaded,
  getResponseUsers,
  getUsersList,
} from "../../../redux/modules/users/selectors";
import CommonPanel from "../../ui/CommonPanel";
import TableUsers from "./TableUsers";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import CommonModal from "../../ui/CommonModal";
import { AdditionalButton } from "../../ui/AdditionalButton";
import SearchBar from "../../ui/SearchBar";
import FilterBar from "../../ui/FilterBar";
import FormUser from "./FormUser";
import { CountBadge } from "../../ui/CommonBadges";

export default function Users() {
  const dispatch = useDispatch();
  const users = useSelector(getUsersList);
  const pendingUsers = useSelector(getPendingUsersLoaded);
  const response = useSelector(getResponseUsers);
  const modalConfig = useSelector(getModalUsersConfig);

  useEffect(() => {
    if (response.error || response.message) {
      toast(response.error ? response.error : response.message, {
        type: response.error ? "error" : "success",
      });
      dispatch(clearResponseUsers());
    }
  }, [response]);

  useEffect(() => {
    dispatch(getUsers.trigger());
    dispatch(getPendingUsers.trigger());
  }, []);

  const handleSearch = (e) => {
    dispatch(setUsersFilter({ propertyName: "search", propertyValue: e }));
  };

  const handleModalClose = () => {
    dispatch(setModalUsersConfig({ ...modalConfig, show: false, data: null }));
  };

  const handleModalOk = () => {
    switch (modalConfig.type) {
      case "delete":
        dispatch(deleteUser.trigger(modalConfig.data));
        dispatch(
          setModalUsersConfig({ ...modalConfig, show: false, data: null })
        );
        break;
      case "changeStatus":
        dispatch(changeStatusUser.trigger(modalConfig.data));
        dispatch(
          setModalUsersConfig({ ...modalConfig, show: false, data: null })
        );
        break;
      case "edit":
        dispatch(updateUser.trigger());
        dispatch(
          setModalUsersConfig({ ...modalConfig, show: false, data: null })
        );
        break;
    }
  };

  const handleClickRow = ({ data, item }) => {
    switch (data.action) {
      case "edit":
        dispatch(
          setModalUsersConfig({
            show: true,
            type: "form",
            size: "xl",
            message: "",
            data: { id: item.id },
          })
        );
        dispatch(loadFormUser(item));
        break;
      case "delete":
        dispatch(
          setModalUsersConfig({
            show: true,
            type: data.action,
            title: "Eliminar Usuario",
            message: (
              <div className="w-full">
                <p className="text-center">
                  ¿Desea eliminar el usuario? <br></br>
                  <span style={{ fontWeight: 700 }}>{item.userName}</span>
                </p>
              </div>
            ),
            data: { id: item.id },
          })
        );
        break;
      case "changeStatus":
        dispatch(
          setModalUsersConfig({
            show: true,
            data: { path: data.value, id: item.id },
            type: data.action,
            title: `${data.title} Usuario`,
            message: (
              <div className="w-full">
                <p className="text-center">
                  {`¿Desea ${data.title.toLowerCase()} el Usuario?`}
                  <br></br>
                  <span style={{ fontWeight: 700 }}>{item.userName}</span>
                </p>
              </div>
            ),
          })
        );
        break;
    }
  };

  const filterBarOptions = [
    { name: "Todos", value: "Todos" },
    { name: "Activo", value: "Activo" },
    { name: "Inactivo", value: "Inactivo" },
  ];

  const filterBarUserType = [
    { name: "Todos", value: "Todos" },
    { name: "Administrador", value: "admin" },
    { name: "Oferente", value: "offerer" },
    { name: "Operador", value: "staff" },
  ];

  const handleStatusFilter = (e) => {
    dispatch(setUsersFilter({ propertyName: "status", propertyValue: e }));
  };

  const handleUserTypeFilter = (e) => {
    dispatch(setUsersFilter({ propertyName: "userType", propertyValue: e }));
  };

  const additionalButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <AdditionalButton
        title="Agregar Usuario"
        linkTo="/users/form"
        iconClass="bi-plus"
      />
    </div>
  );

  return (
    <CommonPanel
      title={"Lista de Usuarios"}
      count={users.length}
      additionalButtons={additionalButtons}
      searchBar={<SearchBar onHandleSearch={handleSearch}></SearchBar>}
      filterBar={
        <FilterBar
          name="Estado"
          options={filterBarOptions}
          onHandleFilter={handleStatusFilter}
        ></FilterBar>
      }
      secondaryFilterBar={
        <FilterBar
          name="Tipo"
          options={filterBarUserType}
          onHandleFilter={handleUserTypeFilter}
        ></FilterBar>
      }
    >
      <div className="w-full flex">
        {pendingUsers.length > 0 && (
          <div className="w-full my-3 py-1 text-end pr-10 text-blue-400">
            <NavLink to="/users/pending">
              Solicitudes Usuarios Pendientes{" "}
              <CountBadge count={pendingUsers.length}></CountBadge>
            </NavLink>
          </div>
        )}
      </div>
      <TableUsers
        columns={[
          "Tipo Usuario",
          "Usuario",
          "Correo",
          "Descripción",
          "Estado",
          "Opciones",
        ]}
        onHandleClickRow={handleClickRow}
        items={users}
      ></TableUsers>
      <CommonModal
        config={modalConfig}
        handleModalClose={handleModalClose}
        handleModalOk={handleModalOk}
      >
        {modalConfig.type === "form" && <FormUser />}
      </CommonModal>
    </CommonPanel>
  );
}
