import React from "react";
import { useDispatch } from "react-redux";
import CommonTable from "../../ui/CommonTable";
import { CountBadge, StatusBadge } from "../../ui/CommonBadges";
import CommonOptionsRowTable from "../../ui/CommonOptionsRowTable";
import { NavLink } from "react-router-dom";
import { setSelectedAuctionId } from "../../../redux/modules/auctions/actions";

const TableAuctions = ({ columns, items, onHandleClickRow }) => {
  const dispatch = useDispatch();

  const getStatusTitle = (item) => {
    switch (item.status) {
      case "Activo":
        return "Inactivar";
      case "Inactiva":
        return "Activar";
      case "Cerrada":
        return "";
    }
  };
  const getStatusValue = (item) => {
    switch (item.status) {
      case "Activo":
        return "inactive";
      case "Inactiva":
        return "active";
      case "Cerrada":
        return "";
    }
  };

  const buildButtons = (item) => {
    let buttons = {
      auctions: [],
    };
    buttons.auctions.push({
      key: "4",
      action: "view",
      title: "Ver",
      value: "",
    });
    if (item.status !== "Cerrada") {
      buttons.auctions.push({
        key: "1",
        action: "edit",
        title: "Editar",
        value: "",
      });
      buttons.auctions.push({
        key: "3",
        action: "changeStatus",
        title: getStatusTitle(item),
        value: getStatusValue(item),
      });
    }
    buttons.auctions.push({
      key: "2",
      action: "delete",
      title: "Eliminar",
      value: "",
    });
    return buttons;
  };

  const buildItems = (_items) => {
    return (
      _items &&
      _items.length > 0 &&
      _items.map((item) => (
        <tr key={item.id}>
          <td>
            <div className="flex flex-column">
              <span style={{ fontWeight: 700 }}>{item.code}</span>
              <span className="text-sm">
                {item.auctionType}-{item.categoryName}
              </span>
              <span>{item.name}</span>
            </div>
          </td>

          <td>{new Intl.NumberFormat("es-CO").format(item.minimumPrice)}</td>
          <td>{new Intl.NumberFormat("es-CO").format(item.reservePrice)}</td>
          <td>
            <NavLink
              to={
                item.offerers.length > 0 ? `/auctions/offerers/${item.id}` : "#"
              }
              onClick={() => dispatch(setSelectedAuctionId(item.id))}
            >
              <CountBadge count={item.offerers.length}></CountBadge>
            </NavLink>
          </td>
          <td>
            <NavLink
              to={item.offers.length > 0 ? `/auctions/offers/${item.id}` : "#"}
              onClick={() => dispatch(setSelectedAuctionId(item.id))}
            >
              <CountBadge count={item.offers.length}></CountBadge>
            </NavLink>
          </td>
          <td>{item.maxOffererName}</td>
          <td>{new Intl.NumberFormat("es-CO").format(item.maxOfferPrice)}</td>
          <td>
            <StatusBadge status={item.status} />
          </td>
          <td>
            <CommonOptionsRowTable
              key={item.id}
              item={item}
              buttonsType={"auctions"}
              onHandleClickRow={onHandleClickRow}
              additionalButtons={buildButtons(item)}
            ></CommonOptionsRowTable>
          </td>
        </tr>
      ))
    );
  };
  return (
    <div className="row p-2">
      <div className="col-md-12">
        <CommonTable
          columns={columns}
          items={buildItems(items)}
          onHandleClickRow={onHandleClickRow}
        ></CommonTable>
      </div>
    </div>
  );
};

export default TableAuctions;
