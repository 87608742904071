import React from "react";

export function DeleteIcon(props) {
  const classDeleteIcon = {
    fill: "#fff",
    strokeLineCap: "round",
    strokeLinejJoin: "round",
    strokeWidth: "1.5",
  };

  return (
    <svg {...props} viewBox="0 0 48 60">
      <g transform="matrix(2,0,0,2,0,0)">
        <g>
          <line x1="1" y1="5" x2="23" y2="5" style={classDeleteIcon}></line>
          <path
            d="M14.25,1H9.75a1.5,1.5,0,0,0-1.5,1.5V5h7.5V2.5A1.5,1.5,0,0,0,14.25,1Z"
            style={classDeleteIcon}
          ></path>
          <line
            x1="9.75"
            y1="17.75"
            x2="9.75"
            y2="10.25"
            style={classDeleteIcon}
          ></line>
          <line
            x1="14.25"
            y1="17.75"
            x2="14.25"
            y2="10.25"
            style={classDeleteIcon}
          ></line>
          <path
            d="M18.86,21.62A1.49,1.49,0,0,1,17.37,23H6.63a1.49,1.49,0,0,1-1.49-1.38L3.75,5h16.5Z"
            style={classDeleteIcon}
          ></path>
        </g>
      </g>
    </svg>
  );
}
