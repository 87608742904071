import produce from "immer";
import { initialState } from "./states";
import {
  changeStatusUser,
  createUser,
  deleteUser,
  getPendingUsers,
  getUsers,
  updateUser,
} from "./actions";
import * as types from "./types";

export const users = produce((draft, { type, payload }) => {
  switch (type) {
    case getUsers.TRIGGER:
      draft.loading = true;
      break;
    case getUsers.REQUEST:
      break;
    case getUsers.SUCCESS:
      draft.users = payload;
      break;
    case getUsers.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getUsers.FULFILL:
      draft.loading = false;
      break;

    case getPendingUsers.TRIGGER:
      draft.loading = true;
      break;
    case getPendingUsers.REQUEST:
      break;
    case getPendingUsers.SUCCESS:
      draft.pendingUsers = payload;
      break;
    case getPendingUsers.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getPendingUsers.FULFILL:
      draft.loading = false;
      break;

    case createUser.TRIGGER:
      draft.loading = true;
      break;
    case createUser.REQUEST:
      break;
    case createUser.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      break;
    case createUser.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case createUser.FULFILL:
      draft.loading = false;
      break;

    case updateUser.TRIGGER:
      draft.loading = true;
      break;
    case updateUser.REQUEST:
      break;
    case updateUser.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      draft.form = initialState.form;
      break;
    case updateUser.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      draft.form = initialState.form;
      break;
    case updateUser.FULFILL:
      draft.loading = false;
      break;

    case deleteUser.TRIGGER:
      draft.loading = true;
      break;
    case deleteUser.REQUEST:
      break;
    case deleteUser.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      break;
    case deleteUser.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case deleteUser.FULFILL:
      draft.loading = false;
      break;

    case changeStatusUser.TRIGGER:
      draft.loading = true;
      break;
    case changeStatusUser.REQUEST:
      break;
    case changeStatusUser.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      break;
    case changeStatusUser.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case changeStatusUser.FULFILL:
      draft.loading = false;
      break;

    case types.CHANGE_FORM_USER:
      draft.form[payload.name] = payload.value;
      break;

    case types.SET_MODAL_USERS_CONFIG:
      draft.modal = payload;
      break;

    case types.SET_USERS_FILTER:
      draft.filters[payload.propertyName] = payload.propertyValue;
      break;

    case types.LOAD_FORM_USER:
      draft.form.id = payload.id;
      draft.form.userName = payload.userName;
      draft.form.userType = payload.userType;
      draft.form.email = payload.email;
      draft.form.password = "#PASSWORD!";
      draft.form.isNew = false;
      break;

    case types.RESET_FORM_USER:
      draft.form = initialState.form;
      break;

    case types.CLEAR_RESPONSE_USERS:
      draft.response = initialState.response;
      break;
  }
}, initialState);
