//COMPANIES
export const GET_COMPANY_BY_ID = "GET_COMPANY_BY_ID";
export const GET_COMPANIES = "GET_COMPANIES";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const CHANGE_STATUS_COMPANY = "CHANGE_STATUS_COMPANY";
export const CHANGE_FORM_COMPANY = "CHANGE_FORM_COMPANY";
export const CLEAR_RESPONSE_COMPANIES = "CLEAR_RESPONSE_COMPANIES";
export const LOAD_FORM_COMPANY = "LOAD_FORM_COMPANY";
export const RESET_FORM_COMPANY = "RESET_FORM_COMPANY";
export const SET_MODAL_COMPANIES_CONFIG = "SET_MODAL_COMPANIES_CONFIG";
export const SET_COMPANIES_FILTER = "SET_COMPANIES_FILTER";