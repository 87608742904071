export default function CommonTable({ items, columns }) {
  return (
    <table className="table table-responsive">
      <thead>
        <tr>
          {columns &&
            columns.length > 0 &&
            columns.map((column, index) => <th key={`col-${index}`}>{column}</th>)}
        </tr>
      </thead>
      <tbody>
        {items}
      </tbody>
    </table>
  );
}
