export const companiesState = (state) => state.companies;

export const getLoadingCompanies = (state) => companiesState(state).loading;

export const getCompaniesList = (state) => {
  const companies = companiesState(state).companies;
  const status = companiesState(state).filters.status;
  const search = companiesState(state).filters.search;
  let itemsFiltered = companies;
  if (status && status != "Todos") {
    itemsFiltered = itemsFiltered.filter(
      (company) => company.status === status
    );
  }
  if (search) {
    itemsFiltered = itemsFiltered.filter(
      (company) =>
      company.document.toUpperCase().indexOf(search.toUpperCase()) != -1 ||
      company.name.toUpperCase().indexOf(search.toUpperCase())!= -1 
    );
  }

  return itemsFiltered;
};

export const getCompaniesLoaded = (state) => companiesState(state).companies;
export const getActiveCompaniesLoaded =  (state) =>
companiesState(state).companies.filter((x) => x.status === "Activo");

export const getFormCompanies = (state) => companiesState(state).form;

export const getResponseCompanies = (state) => companiesState(state).response;

export const getModalCompaniesConfig = (state) => companiesState(state).modal;
