import { all, call, spawn } from "redux-saga/effects";
import appSaga from "./modules/app/saga";
import setupSaga from "./modules/setup/saga";
import authSaga from "./modules/auth/saga";
import signInSaga from "./modules/sign-in/saga";
import usersSaga from "./modules/users/saga";
import categoriesSaga from "./modules/categories/saga";
import auctionsSaga from "./modules/auctions/saga";
import locationsSaga from "./modules/locations/saga";
import companiesSaga from "./modules/companies/saga";
import offerersSaga from "./modules/offerers/saga";
import associatedAuctionsSaga from "./modules/associated-auctions/saga";
import profileSaga from "./modules/profile/saga";
import webSocketSaga from "./modules/web-socket/saga";

export default function* rootSaga() {
  try {
    yield all([
      spawn(webSocketSaga),
      call(appSaga),
      call(setupSaga),
      call(locationsSaga),
      call(authSaga),
      call(signInSaga),
      call(usersSaga),
      call(categoriesSaga),
      call(auctionsSaga),
      call(associatedAuctionsSaga),
      call(companiesSaga),
      call(offerersSaga),
      call(profileSaga),
    ]);
  } catch (error) {}
}
