export const auctionsState = (state) => state.auctions;

export const getLoadingAuctions = (state) => auctionsState(state).loading;

export const getAuctionsLoaded = (state) => auctionsState(state).auctions;

export const getFormAuctions = (state) => auctionsState(state).form;
export const getResponseAuctions = (state) => auctionsState(state).response;

export const getAuctionsList = (state) => {
  const auctions = auctionsState(state).auctions;
  const { status, search, auctionType } = auctionsState(state).filters;
  let itemsFiltered = auctions;
  if (status && status != "Todos") {
    itemsFiltered = itemsFiltered.filter(
      (auction) => auction.status === status
    );
  }
  if (auctionType && auctionType != "Todos") {
    itemsFiltered = itemsFiltered.filter(
      (auction) => auction.auctionType === auctionType
    );
  }
  if (search) {
    itemsFiltered = itemsFiltered.filter(
      (auction) =>
        auction.auctionType.toUpperCase().indexOf(search.toUpperCase()) != -1 ||
        auction.name.toUpperCase().indexOf(search.toUpperCase()) != -1 ||
        auction.code.toUpperCase().indexOf(search.toUpperCase()) != -1 ||
        auction.categoryName.toUpperCase().indexOf(search.toUpperCase()) != -1
    );
  }

  return itemsFiltered;
};

export const getModalAuctionsConfig = (state) => auctionsState(state).modal;
export const getCurrentStatusLoaded = (state) =>
  auctionsState(state).currentStatus;
export const getCurrentOfferPrice = (state) => auctionsState(state).newOffer;

export const getOfferersByAuction = (state, auctionId) => {
  const status = auctionsState(state).filters.status;
  const search = auctionsState(state).filters.search;
  const auctions = auctionsState(state).auctions;
  const auctionExist = auctions.find((item) => item.id === auctionId);
  let auctionName = "";
  let itemsFiltered = [];
  if (auctionExist) {
    itemsFiltered = auctionExist.offerers;
    auctionName = auctionExist.name;
    if (status && status != "Todos") {
      itemsFiltered = itemsFiltered.filter(
        (offerer) => offerer.status === status
      );
    }
    if (search) {
      itemsFiltered = itemsFiltered.filter(
        (offerer) =>
          offerer.offerer.fullName
            .toUpperCase()
            .indexOf(search.toUpperCase()) != -1 ||
          offerer.offerer.userName
            .toUpperCase()
            .indexOf(search.toUpperCase()) != -1 ||
          offerer.offerer.email.toUpperCase().indexOf(search.toUpperCase()) !=
            -1 ||
          offerer.document.toUpperCase().indexOf(search.toUpperCase()) != -1 ||
          offerer.cellPhone.toUpperCase().indexOf(search.toUpperCase()) != -1
      );
    }
  }

  let result = {
    auctionName,
    offerers: itemsFiltered,
  };

  return result;
};

export const getOffersByAuction = (state) => {
  const search = auctionsState(state).filters.search;
  const auctionId = auctionsState(state).auctionId;
  const auctions = auctionsState(state).auctions;
  const auctionExist = auctions.find((item) => item.id === auctionId);
  let auctionName = "";
  let itemsFiltered = [];
  if (auctionExist) {
    auctionName = auctionExist.name;
    itemsFiltered = auctionExist.offers;
    if (search) {
      itemsFiltered = itemsFiltered.filter(
        (offer) =>
          offer.offererUserName.toUpperCase().indexOf(search.toUpperCase()) !=
          -1
      );
    }
  }
  let result = {
    auctionName,
    offers: itemsFiltered,
  };

  return result;
};

export const getLoadingActiveAuctions = (state) => auctionsState(state).loading;

export const getActiveAuctionsLoaded = (state) => {
  const auctions = auctionsState(state).auctions;
  return auctions.filter((x) => x.auctionType === "Subasta");
};

export const getActiveSalesLoaded = (state) => {
  const auctions = auctionsState(state).auctions;
  return auctions.filter((x) => x.auctionType === "Venta");
};

export const getCurrentAuctionByIdLoaded = (state, auctionId, user) => {
  const auctions =
    user && user.userType === "admin"
      ? auctionsState(state).auctions
      : auctionsState(state).auctions;
  let auctionExist = null;
  auctionExist = auctions.find(
    (auction) => auction.id.toUpperCase() === auctionId.toUpperCase()
  );
  if (user && auctionExist) {
    const offererExist = auctionExist.offerers.find(
      (x) => x.offererId === user.offererId
    );
    if (offererExist) {
      auctionExist = {
        ...auctionExist,
        associatedAuctionStatus: offererExist.status,
      };
    }
  }

  return auctionExist;
};

export const getResponseActiveAuctions = (state) =>
  auctionsState(state).response;

export const getActiveSalesList = (state) => {
  let auctions = getActiveList(state);
  return auctions.filter((x) => x.auctionType === "Venta");
};
export const getActiveAuctionsList = (state) => {
  let auctions = getActiveList(state);
  return auctions.filter((x) => x.auctionType === "Subasta");
};
export const getActiveList = (state) => {
  const auctions = auctionsState(state).auctions;
  const category = auctionsState(state).filters.category;
  const search = auctionsState(state).filters.search;
  let itemsFiltered = auctions;
  if (category && category != "Todas") {
    itemsFiltered = itemsFiltered.filter(
      (auction) => auction.categoryName === category
    );
  }
  if (search) {
    itemsFiltered = itemsFiltered.filter(
      (auction) =>
        auction.name.toUpperCase().indexOf(search.toUpperCase()) != -1 ||
        auction.categoryName.toUpperCase().indexOf(search.toUpperCase()) != -1
    );
  }
  return itemsFiltered;
};

export const getCategoriesInAuctions = (state) => {
  const auctions = auctionsState(state).auctions.filter((x) => x.auctionType === "Subasta");
  const categories = auctions.map((item) => item.categoryName).sort();
  return categories.filter(
    (value, index) => categories.indexOf(value) === index
  );
};
export const getCategoriesInSales = (state) => {
  const auctions = auctionsState(state).auctions.filter((x) => x.auctionType === "Venta");
  const categories = auctions.map((item) => item.categoryName).sort();
  return categories.filter(
    (value, index) => categories.indexOf(value) === index
  );
};

export const getLastAuctionId = (state) => auctionsState(state).auctionId;
