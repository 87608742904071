import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveAuctions,
  setActiveAuctionsFilter,
} from "../../../../redux/modules/auctions/actions";
import {
  getActiveAuctionsList,
  getCategoriesInAuctions,
} from "../../../../redux/modules/auctions/selectors";
import { getIsAuth } from "../../../../redux/modules/auth/selectors";
import BackButton from "../../../ui/BackButton";
import CommonPanel from "../../../ui/CommonPanel";
import FilterBar from "../../../ui/FilterBar";
import SearchBar from "../../../ui/SearchBar";
import CardAuction from "./CardAuction";
import NotResults from "../../../ui/NotResults";

function GridAuctions() {
  const dispatch = useDispatch();
  const isAuth = useSelector(getIsAuth);
  const auctions = useSelector(getActiveAuctionsList);
  const categories = useSelector(getCategoriesInAuctions);

  useEffect(() => {
    dispatch(getActiveAuctions.trigger());
  }, []);

  const startButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <BackButton title={"Atras"} to="/" />
    </div>
  );

  let filterBarOptions = [{ name: "Todas", value: "Todas" }];
  filterBarOptions = filterBarOptions.concat(
    categories.map((item) => {
      return { name: item, value: item };
    })
  );
  const handleCategoriesActiveAuctionsFilter = (e) => {
    dispatch(
      setActiveAuctionsFilter({ propertyName: "category", propertyValue: e })
    );
  };
  const handleSearch = (e) => {
    dispatch(
      setActiveAuctionsFilter({ propertyName: "search", propertyValue: e })
    );
  };

  return (
    <CommonPanel
      style={{
        minHeight: "700px",
      }}
      W
      title={"Subastas Activas"}
      count={auctions.length}
      startButtons={!isAuth && startButtons}
      searchBar={<SearchBar onHandleSearch={handleSearch}></SearchBar>}
      filterBar={
        <div>
          <FilterBar
            name="Categoría"
            options={filterBarOptions}
            onHandleFilter={handleCategoriesActiveAuctionsFilter}
          ></FilterBar>
        </div>
      }
    >
      <div className="row p-3">
        {auctions.length === 0 && <NotResults/>}
        {auctions.map((auction) => (
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 p-1">
            <CardAuction item={auction} />
          </div>
        ))}
      </div>
    </CommonPanel>
  );
}

export default GridAuctions;
