import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  getFormUsers,
  getModalUsersConfig,
  getResponseUsers,
} from "../../../redux/modules/users/selectors";
import {
  changeFormUser,
  createUser,
  updateUser,
  resetFormUser,
  setModalUsersConfig,
  clearResponseUsers,
} from "../../../redux/modules/users/actions";
import CommonPanel from "../../ui/CommonPanel";
import CommonModal from "../../ui/CommonModal";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../utils/messages";
import CommonInput from "../../ui/CommonInput";

function FormUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useSelector(getFormUsers);
  const response = useSelector(getResponseUsers);
  const modalUsersConfig = useSelector(getModalUsersConfig);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    title: "",
    message: "",
    type: "edit",
    data: "",
  });


  useEffect(() => {
    if (response.error || response.message) {
      toast(response.error ? response.error : response.message, {
        type: response.error ? "error" : "success",
      });
      dispatch(clearResponseUsers());

      if (response.message) {
        navigate("/users");
      }
    }
  }, [response]);

  const expressions = {
    user: /^[a-zA-Z0-9_-\s]{4,16}$/, // Letras, numeros, espacios, guion y guion_bajo
    name: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    password: /^.{4,12}$/, // 4 a 12 digitos.
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    phone: /^\d{7,14}$/, // 7 a 14 numeros.
  };

  const handleChangeInput = (propertyValue, propertyName) => {
    dispatch(changeFormUser({ value: propertyValue, name: propertyName }));
  };

  const handleSubmitForm = () => {
    if (isValidForm()) {
      if (form.isNew) {
        dispatch(createUser.trigger());
      } else {
        setModalConfig({
          show: true,
          type: "edit",
          title: "Actualizar Oferente",
          message: (
            <div className="w-full">
              <p className="text-center">
                ¿Desea guardar los cambios en el oferente? <br></br>
                <span style={{ fontWeight: 700 }}>{form.userName}</span>
              </p>
            </div>
          ),
          data: { id: form.id },
        });
      }
    } else {
      toast(MESSAGES.REQUIRED_FIELDS, {
        type: "error",
      });
    }
  };

  const isValidForm = () => {
    return true /* form?.name */;
  };

  const startButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <NavLink to="/users">Atrás</NavLink>
    </div>
  );

  const handleCancel = () => {
    if (form.isNew) {
      dispatch(resetFormUser());
    } else {
      dispatch(
        setModalUsersConfig({
          ...modalUsersConfig,
          show: false,
          data: null,
        })
      );
      dispatch(resetFormUser());
    }
  };

  const endButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <button className="btn btn-secondary mr-1" onClick={handleCancel}>
        Cancelar
      </button>
      <button
        className="btn btn-primary ml-1"
        disabled={!isValidForm()}
        onClick={handleSubmitForm}
      >
        Guardar
      </button>
    </div>
  );

  const handleModalClose = () => {
    setModalConfig({ ...modalConfig, show: false });
  };

  const handleModalOk = () => {
    switch (modalConfig.type) {
      case "edit":
        dispatch(updateUser.trigger());
        setModalConfig({ ...modalConfig, show: false, data: null });
        dispatch(
          setModalUsersConfig({
            ...modalUsersConfig,
            show: false,
            data: null,
          })
        );
        break;
    }
  };

  return (
    <CommonPanel
      variant="form"
      title={`${form.isNew ? "Agregar" : "Editar"} Usuario`}
      startButtons={form.isNew && startButtons}
      endButtons={endButtons}
    >
      <div className="w-full p-6 rounded">
        <form className="w-full flex flex-wrap" id="formUser">
          <div
            className="form-check"
            value={form?.userType}
            onChange={(e) => handleChangeInput(e.target.value, "userType")}
          >
            <label>Tipo Usuario</label>
            <br />
            <span className="mr-4">
              <input type="radio"  checked={"admin" === form?.userType} name="userType" value="admin" /> Administrador
            </span>
            </div>

          <div className="flex w-full">
            <div className="w-1/3 pr-6">
              <label className="w-full">Usuario</label>
              <input
                className="form-control"
                required
                name="userName"
                type="text"
                leyendaError="El usuario debe tener más letras"
                value={form?.userName}
                onChange={(e) => handleChangeInput(e.target.value, "userName")}
              />
              {/* <CommonInput
                state={user}
                stateChange={userChange}
                type="text"
                label="Usuario"
                name="userName"
                labelError="El usuario tiene que ser de 4 a 16 dígitos y solo puede contener numeros, letras y guion bajo."
                regularExpression={expressions.user}
                onChange={(e) => handleChangeInput(e.target.value, "userName")}
                value={form?.userName}
              /> */}
            </div>
            <div className="w-1/3 pr-6">
              <label className="w-full">Correo</label>
              <input
                className="form-control"
                required
                name="email"
                type="email"
                value={form?.email}
                onChange={(e) => handleChangeInput(e.target.value, "email")}
              />
              {/* <CommonInput
                state={form?.email}
                stateChange={(e) => handleChangeInput(e.target.value, "email")}
                type="text"
                label="Correo"
                name="email"
                labelError="El correo solo puede contener letras, numeros, puntos, guiones y guion bajo."
                regularExpression={expressions.email}
              /> */}
            </div>
            <div className="w-1/3">
              <label className="w-full">Contraseña</label>
              <input
                className="form-control"
                required
                type="password"
                name="password"
                value={form?.password}
                onChange={(e) => handleChangeInput(e.target.value, "password")}
              />
              {/* <CommonInput
                state={password}
                stateChange={passwordChange}
                type="password"
                label="Contraseña"
                name="password"
                labelError="La contraseña tiene que ser de 4 a 12 dígitos."
                regularExpression={expressions.password}
                value={form?.password}
                onChange={(e) => handleChangeInput(e.target.value, "password")}
              /> */}
            </div>
          </div>
        </form>
      </div>
      <CommonModal
        config={modalConfig}
        handleModalClose={handleModalClose}
        handleModalOk={handleModalOk}
      ></CommonModal>
    </CommonPanel>
  );
}

export default FormUser;
