//OFFERERS
export const GET_OFFERERS = "GET_OFFERERS";
export const CREATE_OFFERER = "CREATE_OFFERER";
export const CHANGE_FORM_OFFERER = "CHANGE_FORM_OFFERER";
export const GET_OFFERERS_BY_STATUS = "GET_OFFERERS_BY_STATUS";
export const GET_OFFERERS_BY_TYPE = "GET_OFFERERS_BY_TYPE";
export const UPDATE_OFFERER = "UPDATE_OFFERER";
export const DELETE_OFFERER = "DELETE_OFFERER";
export const CHANGE_STATUS_OFFERER = "CHANGE_STATUS_OFFERER";
export const GET_PENDING_OFFERERS = "GET_PENDING_OFFERERS";
export const CLEAR_RESPONSE_OFFERERS = "CLEAR_RESPONSE_OFFERERS";
export const LOAD_FORM_OFFERER = "LOAD_FORM_OFFERER";
export const RESET_FORM_OFFERER = "RESET_FORM_OFFERER";
export const SET_MODAL_OFFERERS_CONFIG = "SET_MODAL_OFFERERS_CONFIG";
export const SET_OFFERERS_FILTER = "SET_OFFERERS_FILTER";