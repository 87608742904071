import React from "react";
import { CountBadge, StatusBadge } from "../../ui/CommonBadges";
import CommonOptionsRowTable from "../../ui/CommonOptionsRowTable";
import CommonTable from "../../ui/CommonTable";

const TableOfferers = ({ columns, items, onHandleClickRow }) => {
  const buildItems = (_items) => {
    return (
      _items &&
      _items.length > 0 &&
      _items.map((item) => (
        <tr key={item.id}>
          <td>{item.userName}</td>
          <td>{item.fullName}</td>
          <td>{item.email}</td>
          <td>{item.cellPhone}</td>
          <td>
            <CountBadge count={item.associatedAuctions.length}></CountBadge>
          </td>
          <td>
            <StatusBadge status={item.status} />
          </td>
          <td>
            <CommonOptionsRowTable
              key={item.id}
              item={item}
              onHandleClickRow={onHandleClickRow}
              buttonsType="custom"
              additionalButtons={{
                custom: [
                  { key: "1", action: "edit", title: "Editar", value: "" },
                  item.associatedAuctions.length === 0
                    ? {
                        key: "2",
                        action: "delete",
                        title: "Eliminar",
                        value: "",
                      }
                    : {
                        key: "3",
                        action: "changeStatus",
                        title:
                          item.status === "Activo" ? "Inactivar" : "Activar",
                        value: item.status === "Activo" ? "inactive" : "active",
                      },
                  ,
                ],
              }}
            ></CommonOptionsRowTable>
          </td>
        </tr>
      ))
    );
  };
  return (
    <div className="w-full p-3 rounded">
      <div className="flex flex-wrap">
        <CommonTable
          columns={columns}
          items={buildItems(items)}
          onHandleClickRow={onHandleClickRow}
        ></CommonTable>
      </div>
    </div>
  );
};

export default TableOfferers;
