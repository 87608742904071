//CATEGORIES
export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const CHANGE_STATUS_CATEGORY = "CHANGE_STATUS_CATEGORY";
export const CHANGE_FORM_CATEGORY = "CHANGE_FORM_CATEGORY";
export const CLEAR_RESPONSE_CATEGORIES = "CLEAR_RESPONSE_CATEGORIES";
export const LOAD_FORM_CATEGORY = "LOAD_FORM_CATEGORY";
export const RESET_FORM_CATEGORY = "RESET_FORM_CATEGORY";
export const SET_CATEGORIES_FILTER = "SET_CATEGORIES_FILTER";