export default function ContactSection() {
  return (
    <section className="page-section" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-4 pt-3">
            <div className="flex-row text-white gap-2">
              <h2 className="font-bold text-center text-uppercase text-lg text-white">
                Contáctenos
              </h2>
              <div className="text-white text-lg text-center">
                Nos encantaría poder asesorarte, para ello, dale click al boton
                de Whatsapp y envianos un mensaje, nosotros nos pondremos en
                contacto contigo en muy poco tiempo.
              </div>
            </div>
          </div>
          <div className="col-md-4 text-white text-center">
            <h2 className="font-bold text-center text-uppercase text-lg text-white">
              Ubicación
            </h2>
            <p className="font-bold">Oficina Administrativa</p>
            <p>Calle 26 # 102 - 20 Oficina 303</p>
            <p className="font-bold">Centro de operación tecnológico</p>
            <p className="mb-3">Calle 97c # 63 - 43 piso 1</p>
          </div>
          <div className="col-md-4 text-white text-center">
            <h2 className="font-bold text-center text-uppercase text-lg text-white">
              Información de contacto
            </h2>
            <p className="font-bold">Teléfonos</p>
            <span className="mb-3">315 3158231 | 321 2275222</span>
            <p className="font-bold">Correos electrónicos:</p>
            <a href="#">gerencia@centraldesubastasyremates.com</a>
            <br />
            <a href="#">comercial@centraldesubastasyremates.com</a>
            <br />
            <a href="#">contabilidad@centraldesubastasyremates.com</a>
          </div>
        </div>
        <footer className="site-footer text-center">
          <div className="row ">
            <div className="col pt-4">
              <div className="flex text-white pt-3 gap-2 justify-content-center">
                <a
                  className="text-white pr-3 font-bold  text-lg"
                  target="_blank"
                  href="assets/files/POLÍTICA_DE_PRIVACIDAD_Y_TRATAMIENTO DE DATOS PERSONALES.pdf"
                >
                  Política de privacidad
                </a>

                <a
                  className="pr-3 text-white font-bold  text-lg"
                  target="_blank"
                  href="assets/files/TERMINOS_Y_CONDICIONES_DE_USO.pdf"
                >
                  Terminos y condiciones
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=573212275222&text=Hola, Me gustaría recibir información."
                  target="_blank"
                >
                  <img
                    className="icon-whatsapp2"
                    src="assets/images/upload/whatsapp_logo.png"
                  />
                </a>
                <a
                  href="https://www.facebook.com/Central-de-Subastas-y-Remates-102043888689570"
                  target="_blank"
                >
                  <img
                    src="assets/images/upload/facebook_logo.png"
                    className="ico-facebook"
                  ></img>
                </a>
                <a
                  href="https://www.instagram.com/centraldesubastasyremates/"
                  target="_blank"
                >
                  <img
                    src="assets/images/upload/instagram_logo.png"
                    className="ico-instagram2"
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </section>
  );
}
