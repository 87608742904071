/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
import { eventChannel } from "redux-saga";
import { call, fork, put, take } from "redux-saga/effects";
import io from "socket.io-client";
import {
  getAssociatedAuctionPending,
  setAssociatedAuction,
} from "../associated-auctions/actions";
import {
  addActiveAuction,
  getActiveAuctions,
  updateActiveAuction,
} from "../auctions/actions";

const { REACT_APP_WS_URL } = process.env;

function connect() {
  const socket = io(REACT_APP_WS_URL);
  return new Promise((resolve) => {
    socket.on("connect", () => {
      resolve(socket);
    });
  });
}
function* readSocket(socket) {
  try {
    const channel = yield call(subscribe, socket);
    while (true) {
      const message = yield take(channel);
      // console.log(message);
      switch (message.type) {
        case "Auctions":
          yield put(getActiveAuctions.success(message.payload));
          break;
        case "AuctionCreated":
          yield put(addActiveAuction(message.payload));
          break;
        case "AuctionUpdated":
          yield put(
            updateActiveAuction({ data: message.payload, type: "auction" })
          );
          break;
        case "AuctionUpdatedStatus":
          yield put(
            updateActiveAuction({
              data: message.payload,
              type: "auctionStatus",
            })
          );
          break;
        case "AssociateRequested":
          yield put(getAssociatedAuctionPending.trigger());
          break;
        case "AssociatedAuctionStatusChanged":
          yield put(
            setAssociatedAuction({ data: message.payload, type: "status" })
          );
          yield put(
            updateActiveAuction({
              data: message.payload,
              type: "requestStatus",
            })
          );
          break;
      }
    }
  } catch (error) {
    console.error(error);
    // throw error;
  }
}

export function* subscribe(socket) {
  return new eventChannel((emit) => {
    const handleMessage = (payload) => {
      return emit(payload);
    };

    socket.on("message", handleMessage);
    return () => {
      socket.off("message", handleMessage);
    };
  });
}

export default function* webSocketSaga() {
  const socket = yield call(connect);
  yield fork(readSocket, socket);
}
