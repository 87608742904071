export const initialState = {
  loading: false,
  form: {
    isNew: true,
    userName: "",
    userType: "",
    email: "",
    password: "",
  },
  response: { message: null, error: null },
  users: [],
  pendingUsers: [],
  filters: {
    userType: "",
    search: "",
    status: "Todos",
  },
  modal: {
    show: false,
    title: "",
    message: "",
    type: "",
    data: "",
    size: null,
  },
};