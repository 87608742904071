import {
  faCalendarDays,
  faLocationDot,
  faUsd,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import { CountBadge } from "../../../ui/CommonBadges";

const { REACT_APP_ASSETS_URL } = process.env;

function CardAuction({ item }) {
  const navigate = useNavigate();
  const dateCloseAuction = new Date(item.closeAt);
  return (
    <div
      style={{
        maxHeight: "400px",
        justifyContent: "space-between",
        backgroundColor: "#f7f7f770",
      }}
      className="flex flex-column cursor-pointer border-1 rounded-lg hover:border-blue-500"
      onClick={() => navigate(`/auction/detail/${item.id}`)}
    >
      <div className="flex">
        <span className="w-full text-center bg-[#005493] rounded-sm p-1 text-white">
          {item.categoryName}
        </span>
        <span className="w-full text-center text-blac font-bold">
          {item.code}
        </span>
      </div>
      <div
        className="card-img-top"
        style={{
          minHeight: "200px",
          maxHeight: "250px",
          backgroundImage: `url('${REACT_APP_ASSETS_URL}/${item.urlImage}')`,
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
      <div className="flex flex-column pt-1 px-3  pb-3">
        <span className="w-full text-center text-lg">{item.name}</span>

        <span className="w-full text-center text-sm">
          {item.shortDescription}
        </span>
        <div className="w-full flex flex-row">
          <span className="text-black font-semibold text-base basis-3/4">
            <FontAwesomeIcon icon={faLocationDot} />
            {` ${item.departmentName}/${item.cityName}`}
          </span>
          <div className="basis-1/4 text-lg">
            <CountBadge
              count={`${new Intl.NumberFormat("es-CO").format(
                item.reservePrice
              )}`}
            />
          </div>
        </div>

        <div className="w-full flex flex-row">
          <span className="basis-3/4 text-black font-semibold text-base">
            <FontAwesomeIcon icon={faCalendarDays} />
            {`  Cierra:  ${
              dateCloseAuction && dateCloseAuction.toLocaleString()
            }`}
          </span>
          <div className="basis-1/4">
            <span className="">
              {" "}
              <FontAwesomeIcon icon={faUserCircle} />
              {` ${item.offerersCount}`}
            </span>
            <span className="">
              {" "}
              <FontAwesomeIcon icon={faUsd} />
              {` ${item.offersCount}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardAuction;
