import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  changeFormCompany,
  createCompany,
  resetFormCompany,
  setModalCompaniesConfig,
  updateCompany,
} from "../../../redux/modules/companies/actions";
import {
  getFormCompanies,
  getModalCompaniesConfig,
  getResponseCompanies,
} from "../../../redux/modules/companies/selectors";
import {
  getCitiesByDepartment,
  getCountries,
  getDepartmentsByCountry,
} from "../../../redux/modules/locations/actions";
import {
  getCitiesLoaded,
  getCountriesLoaded,
  getDepartmentsLoaded,
} from "../../../redux/modules/locations/selectors";
import { MESSAGES } from "../../../utils/messages";
import { AddImagesButton } from "../../ui/AddImagesButton";
import CommonModal from "../../ui/CommonModal";
import CommonPanel from "../../ui/CommonPanel";
import { RequiredIcon } from "../../ui/custom-icons";
import { UploadedImageCard } from "../../ui/UploadedImageCard";

const { REACT_APP_ASSETS_URL } = process.env;

function FormCompany() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useSelector(getFormCompanies);
  const response = useSelector(getResponseCompanies);
  const countries = useSelector(getCountriesLoaded);
  const departments = useSelector(getDepartmentsLoaded);
  const cities = useSelector(getCitiesLoaded);
  const modalCompaniesConfig = useSelector(getModalCompaniesConfig);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    title: "",
    message: "",
    type: "edit",
    data: "",
  });
  const [base64Files, setBase64Files] = useState([]);

  useEffect(() => {
    dispatch(getCountries.trigger());
  }, []);

  useEffect(() => {
    if (form.countryId) {
      dispatch(getDepartmentsByCountry.trigger(form.countryId));
      if (form.departmentId) {
        dispatch(getCitiesByDepartment.trigger(form.departmentId));
      }
    }
  }, [form.countryId]);

  useEffect(() => {
    if (response.result && response.result === "SUCCESS") {
      navigate("/companies");
    }
  }, [response.result]);

  const handleChangeInput = (propertyValue, propertyName) => {
    switch (propertyName) {
      case "files":
        const files = propertyValue;
        let newFiles = [];
        let base64Temp = [];
        const { fileName, base64, file } = files[0];
        base64Temp.push({
          name: fileName,
          base64: base64,
        });
        newFiles.push(file);
        dispatch(changeFormCompany({ value: newFiles, name: propertyName }));
        setBase64Files(base64Files.concat(base64Temp));
        break;

      default:
        dispatch(
          changeFormCompany({ value: propertyValue, name: propertyName })
        );
        break;
    }
  };

  const handleSubmitForm = () => {
    if (isValidForm()) {
      if (form.isNew) {
        dispatch(createCompany.trigger());
      } else {
        setModalConfig({
          show: true,
          type: "edit",
          title: "Actualizar Companía",
          message: (
            <div className="w-full">
              <p className="text-center">
                ¿Desea guardar los cambios en la compañía? <br></br>
                <span style={{ fontWeight: 700 }}>{form.name}</span>
              </p>
            </div>
          ),
          data: { id: form.id },
        });
      }
    } else {
      toast(MESSAGES.REQUIRED_FIELDS, {
        type: "error",
      });
    }
  };

  const handleCancel = () => {
    if (form.isNew) {
      dispatch(resetFormCompany());
    } else {
      dispatch(
        setModalCompaniesConfig({
          ...modalCompaniesConfig,
          show: false,
          data: null,
        })
      );
      dispatch(resetFormCompany());
    }
  };

  const handleRemoveImage = ({ index, id }, type) => {
    if (type === "base64") {
      setBase64Files([]);
    } else {
      let images = [];
      dispatch(changeFormCompany({ value: images, name: "images" }));
    }
  };

  const isValidForm = () => {
    return form?.name;
  };

  const handleModalClose = () => {
    setModalConfig({ ...modalConfig, show: false });
  };

  const handleModalOk = () => {
    switch (modalConfig.type) {
      case "edit":
        dispatch(updateCompany.trigger());
        setModalConfig({ ...modalConfig, show: false, data: null });
        dispatch(
          setModalCompaniesConfig({
            ...modalCompaniesConfig,
            show: false,
            data: null,
          })
        );
        break;
    }
  };

  const handleChangeCountry = (newValue) => {
    dispatch(changeFormCompany({ value: newValue, name: "countryId" }));
    dispatch(changeFormCompany({ value: "", name: "departmentId" }));
    dispatch(changeFormCompany({ value: "", name: "cityId" }));
    dispatch(getDepartmentsByCountry.trigger(newValue));
  };

  const handleChangeDepartment = (newValue) => {
    dispatch(changeFormCompany({ value: newValue, name: "departmentId" }));
    dispatch(changeFormCompany({ value: "", name: "cityId" }));
    dispatch(getCitiesByDepartment.trigger(newValue));
  };

  const startButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <NavLink to="/companies">Atrás</NavLink>
    </div>
  );

  const endButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <button className="btn btn-secondary mr-1" onClick={handleCancel}>
        Cancelar
      </button>
      <button
        className="btn btn-primary ml-1"
        disabled={!isValidForm()}
        onClick={handleSubmitForm}
      >
        Guardar
      </button>
    </div>
  );
  return (
    <CommonPanel
      variant="form"
      title={`${form.isNew ? "Agregar" : "Editar"} Empresa`}
      startButtons={form.isNew && startButtons}
      endButtons={endButtons}
    >
      <div className=" w-full p-6 rounded">
        <form className="w-full flex flex-wrap" id="formCompany">
          <div className="w-1/2">
            <div className="pr-6">
              <label className="w-full">
                {" "}
                <RequiredIcon />
                Nombre
              </label>
              <input
                className="form-control"
                maxLength="100"
                required
                name="name"
                value={form?.name}
                onChange={(e) => handleChangeInput(e.target.value, "name")}
              />
              <label className="w-full">Tipo de Documento</label>
              <select
                className="form-select"
                name="documentType"
                maxLength="5"
                required
                value={form?.documentType}
                onChange={(e) =>
                  handleChangeInput(e.target.value, "documentType")
                }
              >
                <option value="CC">Cedula de ciudadanía</option>
                <option value="NIT">Nit</option>
                <option value="CE">Cedula de extranjería</option>
                <option value="PS">Pasaporte</option>
              </select>
              <div className="form-group">
                <label>Documento</label>
                <input
                  className="form-control"
                  name="document"
                  maxLength="20"
                  value={form?.document}
                  onChange={(e) =>
                    handleChangeInput(e.target.value, "document")
                  }
                />
              </div>
              <div className="form-group">
                <label>Descripción</label>
                <input
                  className="form-control"
                  name="description"
                  maxLength="500"
                  value={form?.description}
                  onChange={(e) =>
                    handleChangeInput(e.target.value, "description")
                  }
                />
              </div>
            </div>
          </div>
          <div className="w-1/2">
            <label className="w-full">País</label>
            <select
              className="form-select"
              name="countryId"
              maxLength="36"
              required
              value={form?.countryId}
              onChange={(e) => handleChangeCountry(e.target.value)}
              disabled={countries && countries.length === 0}
            >
              <option value="">--Seleccionar--</option>
              {countries &&
                countries.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
            <label className="w-full">Departamento</label>
            <select
              className="form-select"
              required
              name="departmentId"
              maxLength="36"
              value={form?.departmentId}
              onChange={(e) => handleChangeDepartment(e.target.value)}
              disabled={departments && departments.length === 0}
            >
              <option value="">--Seleccionar--</option>
              {departments &&
                departments.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
            <label className="w-full">Ciudad</label>
            <select
              className="form-select"
              name="cityId"
              maxLength="36"
              required
              value={form?.cityId}
              onChange={(e) => handleChangeInput(e.target.value, "cityId")}
              disabled={cities && cities.length === 0}
            >
              <option value="">--Seleccionar--</option>
              {cities &&
                cities.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
            <label className="w-full">Dirección</label>
            <input
              className="form-control"
              required
              name="address"
              maxLength="250"
              value={form?.address}
              onChange={(e) => handleChangeInput(e.target.value, "address")}
            />
          </div>
          <div className="w-full">
            <RequiredIcon /> Imagen
            <AddImagesButton
              limit={1}
              onHandleChangeInput={(files) => handleChangeInput(files, "files")}
            ></AddImagesButton>
            <div className="row">
              {base64Files?.map((imgBase64, index) => (
                <UploadedImageCard
                  onHandleRemoveImage={(file) =>
                    handleRemoveImage(file, "base64")
                  }
                  index={index}
                  base64={imgBase64.base64}
                ></UploadedImageCard>
              ))}
              {form.images?.map((file, index) => (
                <UploadedImageCard
                  onHandleRemoveImage={(file) => handleRemoveImage(file, "url")}
                  index={index}
                  id={file.id}
                  url={`${REACT_APP_ASSETS_URL}/${file.url}`}
                ></UploadedImageCard>
              ))}
            </div>
          </div>
        </form>
      </div>
      <CommonModal
        config={modalConfig}
        handleModalClose={handleModalClose}
        handleModalOk={handleModalOk}
      ></CommonModal>
    </CommonPanel>
  );
}

export default FormCompany;
