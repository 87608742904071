//OFFERS
export const GET_OFFERS = "GET_OFFERS";
export const GET_ASSOCIATED_AUCTIONS = "GET_ASSOCIATED_AUCTIONS";
export const SET_ASSOCIATED_AUCTION = "SET_ASSOCIATED_AUCTION";
export const UPLOAD_ASSOCIATED_AUCTION_FILES =
  "UPLOAD_ASSOCIATED_AUCTION_FILES";
export const CLEAR_RESPONSE_OFFERS = "CLEAR_RESPONSE_OFFERS";
export const SET_OFFERS_FILTER = "SET_OFFERS_FILTER";
export const GET_ASSOCIATED_AUCTIONS_PENDING =
  "GET_ASSOCIATED_AUCTIONS_PENDING";
  export const CHANGE_STATUS_ASSOCIATED_AUCTION =
  "CHANGE_STATUS_ASSOCIATED_AUCTION";