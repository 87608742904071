export const initialState = {
  loading: false,
  form: {
    isNew: true,
    companyId: "",
    categoryId: "",
    offererId: "",
    countryId: "",
    departmentId: "",
    cityId: "",
    auctionType: "Subasta",
    name: "",
    description: null,
    shortDescription: "",
    minimumPrice: null,
    minimumIncrease: null,
    reservePrice: null,
    startAt: "",
    closeAt: "",
    endAt: "",
    finalOffer: null,
    urlImage: "",
    defaultImage: "",
    files: [],
    address: "",
    requirementsLink: "",
    commission: null,
    removeImages: [],
  },
  response: { message: null, error: null },
  auction: null,
  auctions: [],
  sales: [],
  filters: {
    search: "",
    status: "Todos",
    category: "Todas",
    auctionType: "Todos",
  },
  modal: {
    show: false,
    title: "",
    message: "",
    type: "",
    data: "",
    size: null,
  },
  newOffer: {
    price: 0,
    minPrice: 0,
    lastPrice: 0,
    lastOfferer: "",
  },
  auctionId: "",
};
