export const categoriesState = (state) => state.categories;

export const getLoadingCategories = (state) => categoriesState(state).loading;

export const getCategoriesLoaded = (state) => categoriesState(state).categories;
export const getActiveCategoriesLoaded = (state) =>
  categoriesState(state).categories.filter((x) => x.status === "Activo");

export const getFormCategories = (state) => categoriesState(state).form;

export const getResponseCategories = (state) => categoriesState(state).response;

export const getCategoriesList = (state) => {
  const categories = categoriesState(state).categories;
  const status = categoriesState(state).filters.status;
  const search = categoriesState(state).filters.search;
  let itemsFiltered = categories;
  if (status && status != "Todos") {
    itemsFiltered = itemsFiltered.filter(
      (category) => category.status === status
    );
  }
  if (search) {
    itemsFiltered = itemsFiltered.filter(
      (category) =>
        (category.code &&
          category.code.toUpperCase().indexOf(search.toUpperCase()) != -1) ||
        category.name.toUpperCase().indexOf(search.toUpperCase()) != -1
    );
  }

  return itemsFiltered;
};
