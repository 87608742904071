const { REACT_APP_ASSETS_URL } = process.env;
export default function TrafficLight({ isWinning }) {
  return (
    <div style={{ height: "80px" }} className="w-full ">
      {isWinning ? (
        <div
          style={{ border: "1px solid", backgroundColor: "green" }}
          className="flex flex-row align-items-center rounded"
        >
          <div
            style={{
              width: "80px",
              height: "80px",
              backgroundSize: "115px",
              backgroundPositionY: "75px",
              backgroundPositionX: "-20px",
              backgroundImage: `url('${REACT_APP_ASSETS_URL}/assets/images/winner.gif')`,
            }}
          ></div>
          <div className="text-white text-lg  font-bold text-center px-2 rounded w-full">
            Eres el actual <strong>LIDER</strong> de la subasta!
          </div>
        </div>
      ) : (
        <div
          style={{ border: "1px solid", backgroundColor: "white" }}
          className="flex flex-row align-items-center rounded"
        >
          <div
            style={{
              width: "80px",
              height: "80px",
              backgroundSize: "115px",
              backgroundPositionY: "75px",
              backgroundPositionX: "-20px",
              backgroundImage: `url('${REACT_APP_ASSETS_URL}/assets/images/527984-middle.png')`,
            }}
          ></div>
          <div className="text-danger text-lg font-bold text-center px-2 rounded">
            Actualmente vas perdiendo la subasta!
          </div>
        </div>
      )}
    </div>
  );
}
