export const theme = {
  colors: {
    primary: "#005493",
    secondary: "#1F1C36",
    third: "#EBCE75",
    thirdVariant: "#407D68",
    text: "#CDCACE",
    background: "#fff",
    primaryVariant: "#469378",
    secondaryVariant: "#00000029",
    backgroundContrast: "#C4C3C4",
    danger:"#b53333",
  },
};
