import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";
import * as type from "./types";

export const changeFormCompany = createAction(type.CHANGE_FORM_COMPANY);
export const getCompanies = createRoutine(type.GET_COMPANIES);
export const createCompany = createRoutine(type.CREATE_COMPANY);
export const updateCompany = createRoutine(type.UPDATE_COMPANY);
export const deleteCompany = createRoutine(type.DELETE_COMPANY);
export const changeStatusCompany = createRoutine(type.CHANGE_STATUS_COMPANY);
export const clearResponseCompanies = createAction(type.CLEAR_RESPONSE_COMPANIES);
export const setModalCompaniesConfig = createAction(type.SET_MODAL_COMPANIES_CONFIG);
export const setCompaniesFilter = createAction(type.SET_COMPANIES_FILTER);
export const loadFormCompany = createAction(type.LOAD_FORM_COMPANY);
export const resetFormCompany = createAction(type.RESET_FORM_COMPANY);
