import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { getFormCategories } from "../../../redux/modules/categories/selectors";
import {
  changeFormCategory,
  resetFormCategory,
} from "../../../redux/modules/categories/actions";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../utils/messages";
import { RequiredIcon } from "../../ui/custom-icons";

function FormCategory({ onHandleCreate, onHandleUpdate }) {
  const dispatch = useDispatch();
  const form = useSelector(getFormCategories);

  const handleChangeInput = (propertyValue, propertyName) => {
    dispatch(changeFormCategory({ value: propertyValue, name: propertyName }));
  };

  const handleResetForm = () => {
    dispatch(resetFormCategory());
  };

  const handleSubmitForm = () => {
    if (isValidForm()) {
      if (form.isNew) {
        onHandleCreate();
      } else {
        onHandleUpdate();
      }
    } else {
      toast(MESSAGES.REQUIRED_FIELDS, {
        type: "error",
      });
    }
  };

  const isValidForm = () => {
    return form?.name;
  };

  return (
    <div className="border-1 p-3 mt-3 rounded">
      <div style={{ fontSize: "18px", textAlign: "center" }}>
        {form?.isNew ? "Agregar" : "Editar"} <strong>Categoría</strong>
      </div>

      <label>Codigo</label>
      <input
        className="form-control"
        name="code"
        maxLength="20"
        value={form?.code}
        onChange={(e) => handleChangeInput(e.target.value, "code")}
      />
      <label>
        <RequiredIcon />
        Nombre
      </label>
      <input
        required
        className="form-control"
        name="name"
        maxLength="100"
        value={form?.name}
        onChange={(e) => handleChangeInput(e.target.value, "name")}
      />
      <label>Descripción</label>
      <textarea
        className="form-control"
        name="Description"
        maxLength="500"
        value={form?.description}
        onChange={(e) => handleChangeInput(e.target.value, "description")}
      ></textarea>
      <div
        style={{
          display: "flex",
          paddingTop: "1rem",
          justifyContent: "space-evenly",
        }}
      >
        <button
          className="btn btn-secondary mr-2 w-full"
          onClick={handleResetForm}
        >
          Cancelar
        </button>
        <button
          className="btn btn-primary ml-2 w-full"
          disabled={!isValidForm()}
          onClick={handleSubmitForm}
        >
          Guardar
        </button>
      </div>
    </div>
  );
}

export default FormCategory;
