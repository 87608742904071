import ReactPlayer from "react-player";
export default function AboutSection() {
  return (
    <section className="page-section" id="about">
      <div className="container">
        <div className="text-center">
          <h2
            style={{ background: "#005493" }}
            className="section-heading text-uppercase text-white py-2 rounded-md"
          >
            Nosotros
          </h2>
        </div>
        <div className="row about pt-3">
          <div
            className="col-md-6 align-self-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="row pt-3">
              <div
                className="col-md-12 align-self-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h3
                  style={{ padding: "10px" }}
                  className="h6 mb-3 text-uppercase lead text-center"
                >
                  Central de Subastas y Remates
                </h3>
                <div className="text-center" style={{ padding: "10px" }}>
                  Nos dedicamos a la intermediación, comercialización y venta
                  (online) de activos fijos bienes tangibles e intangibles de
                  grandes, medianas y pequeñas empresas; a través de una
                  plataforma tecnológica , auditable de caracter público o
                  privado que premite tener trazabilidad y transparencia en el
                  negocio.
                </div>
              </div>

              <div
                className="col-md-12 align-self-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h3
                  style={{ padding: "10px" }}
                  className="h6 mb-3 text-uppercase lead text-center"
                >
                  Nuestra Diferenciación
                </h3>
                <div className="text-center" style={{ padding: "10px" }}>
                  Nos caracterizamos por ofrecer a compradores y vendedores
                  información, verás, clara, oportuna y transparente, con
                  herramientas digitales auditables que facilitan y agilizan la
                  negociación online en cualquier lugar del pais. Nuestros
                  vendedores, compradores y colaboradores son la razón de ser de
                  nuestra empresa.
                </div>
                <div className="text-center" style={{ padding: "10px" }}>
                  Nuestros vendedores saben que a través de este modelo de
                  negocio tendrán liquidez inmediata por bienes que estaban
                  congelados y que generan costos ocultos, saben que en el
                  mercado se encuentra el precio esperado por el bien ofrecido;
                  nuestros compradores conocen el valor que le otorgarán al ien
                  que desea adquirir, tomamos como propio cada uno de sus
                  negocios.
                </div>
              </div>
              <div
                className="col-md-12 align-self-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h3
                  style={{ padding: "10px" }}
                  className="h6 mb-3 text-uppercase lead text-center"
                >
                  EXPERIENCIA
                </h3>
                <div className="text-center" style={{ padding: "10px" }}>
                  Contamos con 30 años de experiencia en negocios de subastas y
                  remates presenciales en varias industrias, llevamos 1 año de
                  lanzamiento de nuestro sistema tecnológico que permite
                  automatizar dichas subastas y remates, a través de negocios
                  online de tipo subasta al alza y a la baja, así como urna
                  virtual, venta directa, compra directa y otros.
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 align-self-center"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <ReactPlayer
              url="https://www.youtube.com/watch?v=gRqLGL4sMBw"
              width="100%"
              height="350px"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
