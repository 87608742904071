import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { getToken } from "../auth/selectors";
import {
  createOfferer,
  getOfferers,
  changeStatusOfferer,
  updateOfferer,
} from "./actions";
import { getFormOfferers } from "./selectors";

function* getOfferersSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(getOfferers.request());
    const response = yield call(
      callApiAxios,
      "GET",
      "offerers",
      tokenAccess,
      {},
      {}
    );
    yield put(getOfferers.success(response));
  } catch (e) {
    yield put(
      getOfferers.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getOfferers.fulfill());
  }
}

function* createOffererSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(createOfferer.request());
    const form = yield select(getFormOfferers);
    const body = {
      fullName: form.fullName,
      documentType: form.documentType,
      document: form.document,
      phone: form.phone,
      cellPhone: form.cellPhone,
      status: form.status,
      address: form.address,
      cityId: form.cityId,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      "offerers",
      tokenAccess,
      {},
      body
    );
    yield put(createOfferer.success(response));
  } catch (e) {
    yield put(
      createOfferer.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(createOfferer.fulfill());
  }
}
function* updateOffererSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(updateOfferer.request());
    const form = yield select(getFormOfferers);
    const body = {
      id: form.id,
      fullName: form.fullName,
      documentType: form.documentType,
      document: form.document,
      phone: form.phone,
      cellPhone: form.cellPhone,
      address: form.address,
      cityId: form.cityId,
      userId: form.userId,
      email: form.email,
      userName: form.userName,
      password: form.password,
    };
    const response = yield call(
      callApiAxios,
      "PUT",
      `offerers/${body.id}`,
      tokenAccess,
      {},
      body
    );
    yield put(updateOfferer.success(response));
  } catch (e) {
    yield put(
      updateOfferer.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(updateOfferer.fulfill());
  }
}

function* changeStatusOffererSaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(changeStatusOfferer.request());
    const response = yield call(
      callApiAxios,
      "PATCH",
      `offerers/${payload.id}/changeStatus/${payload.path}/${payload.userId}`,
      tokenAccess,
      {},
      {}
    );
    yield put(changeStatusOfferer.success(response));
  } catch (e) {
    yield put(
      changeStatusOfferer.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(changeStatusOfferer.fulfill());
  }
}

export default function* offerersSaga() {
  yield takeEvery(getOfferers.TRIGGER, getOfferersSaga);
  yield takeEvery(createOfferer.TRIGGER, createOffererSaga);
  yield takeEvery(changeStatusOfferer.TRIGGER, changeStatusOffererSaga);
  yield takeEvery(changeStatusOfferer.SUCCESS, getOfferersSaga);
  yield takeEvery(updateOfferer.TRIGGER, updateOffererSaga);
  yield takeEvery(updateOfferer.SUCCESS, getOfferersSaga);
}
