import React from "react";
import CommonButtonIcon from "./CommonButtonIcon";
import {
  AlertIcon,
  ApproveIcon,
  DeleteIcon,
  EditIcon,
  RejectIcon,
  ViewIcon,
  ActiveIcon,
  InactiveIcon,
} from "./custom-icons";

const CommonOptionsRowTable = ({
  item,
  onHandleClickRow,
  style,
  buttonsType = "primary",
  additionalButtons,
}) => {
  const buttons = {
    primary: [
      { key: "1", action: "edit", title: "Editar", value: "" },
      { key: "2", action: "delete", title: "Eliminar", value: "" },
      {
        key: "3",
        action: "changeStatus",
        title: item.status === "Activo" ? "Inactivar" : "Activar",
        value: item.status === "Activo" ? "inactive" : "active",
      },
    ],
    secondary: [
      { key: "1", action: "approve", title: "Aprobar", value: "" },
      { key: "2", action: "reject", title: "Rechazar", value: "" },
      {
        key: "3",
        action: "pending",
        title: "Tiene Archivos Pendientes",
        value: "",
      },
    ],
    tertiary: [{ key: "1", action: "reject", title: "Remover", value: "" }],
    quaternary: [{ key: "1", action: "approve", title: "Aprobar", value: "" }],
    ...additionalButtons,
  };

  const listButtons = buttons[buttonsType];

  const getIcon = (action, value) => {
    switch (action) {
      case "view":
        return <ViewIcon className="icon-button" />;
        break;
      case "edit":
        return <EditIcon className="icon-button" />;
        break;
      case "delete":
        return <DeleteIcon className="icon-button" />;
        break;
      case "pending":
        return <AlertIcon className="icon-button" />;
        break;
      case "changeStatus":
        switch (value) {
          case "inactive":
            return <InactiveIcon className="icon-button" />;
            break;
          case "active":
            return <ActiveIcon className="icon-button" />;
            break;
        }
      case "approve":
        return <ApproveIcon className="icon-button" />;
        break;
      case "reject":
        return <RejectIcon className="icon-button" />;
        break;
      default:
        return <EditIcon className="icon-button" />;
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      {listButtons &&
        listButtons.length > 0 &&
        listButtons.map((button) => (
          <CommonButtonIcon
            key={`button-${button.key}-${item.id}`}
            onHandleClick={() => onHandleClickRow({ item, data: button })}
            icon={getIcon(button.action, button.value)}
            title={button.title}
          ></CommonButtonIcon>
        ))}
    </div>
  );
};
export default CommonOptionsRowTable;
