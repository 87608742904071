import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAssociatedAuctions,
  setOffersFilter,
  uploadAssociatedAuctionFiles,
} from "../../../redux/modules/associated-auctions/actions";
import { getAssociatedAuctionsLoaded } from "../../../redux/modules/associated-auctions/selectors";

import CommonPanel from "../../ui/CommonPanel";
import FilterBar from "../../ui/FilterBar";
import SearchBar from "../../ui/SearchBar";
import UploadFilesModal from "../../ui/UploadFilesModal";
import TableAssociatedAuctions from "./TableAssociatedAuctions";

function MyAuctions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentAuctions = useSelector(getAssociatedAuctionsLoaded);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    title: "",
    type: "content",
    data: null,
  });
  useEffect(() => {
    dispatch(getAssociatedAuctions.trigger());
  }, []);

  const handleSearch = (e) => {
    dispatch(setOffersFilter({ propertyName: "search", propertyValue: e }));
  };

  const handleStatusFilter = (e) => {
    dispatch(setOffersFilter({ propertyName: "status", propertyValue: e }));
  };

  const handleClickRow = ({ data, item }) => {
    switch (data.action) {
      case "offering-process":
        navigate(`/auction/detail/${item.id}`);
        break;
      case "upload-files":
        setModalConfig({
          ...modalConfig,
          show: true,
          title: `Cargar Archivos - ${item.name}`,
          data: item,
        });

        break;
    }
  };

  const handleUploadFiles = (uploadFiles, removeFiles) => {
    dispatch(
      uploadAssociatedAuctionFiles.trigger({
        id: modalConfig.data.associatedAuctionId,
        uploadFiles,
        removeFiles,
      })
    );
    setModalConfig({ ...modalConfig, show: false, data: null });
  };

  const filterBarOptions = [
    { name: "Todos", value: "Todos" },
    { name: "Activo", value: "Activo" },
    { name: "Inactivo", value: "Inactivo" },
  ];

  return (
    <CommonPanel
      title="Subastas Asociadas"
      count={currentAuctions.length}
      searchBar={<SearchBar onHandleSearch={handleSearch}></SearchBar>}
      filterBar={
        <FilterBar
          name="Estado"
          options={filterBarOptions}
          onHandleFilter={handleStatusFilter}
        ></FilterBar>
      }
    >
      <div className="flex flex-row w-full p-4">
        <TableAssociatedAuctions
          columns={[
            "Nombre",
            "Oferentes",
            "Ofertas",
            "Max Oferente",
            "Max Oferta",
            "Archivos",
            "Estado",
            "Opciones",
          ]}
          onHandleClickRow={handleClickRow}
          items={currentAuctions}
        ></TableAssociatedAuctions>
      </div>
      {modalConfig.data && (
        <UploadFilesModal
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          onHandleUploadFiles={handleUploadFiles}
        ></UploadFilesModal>
      )}
    </CommonPanel>
  );
}

export default MyAuctions;
