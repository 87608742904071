import React from "react";

export function AttachIcon(props) {
  const classAttachIcon = {
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2px",
  };
  return (
    <svg {...props}  viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M18.75,15.749V7.5a6.75,6.75,0,0,0-13.5,0v11.25a4.5,4.5,0,0,0,9,0V7.5a2.25,2.25,0,1,0-4.5,0v9.75"
          style={classAttachIcon}
        ></path>
      </g>
    </svg>
  );
}
