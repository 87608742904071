import { beforeImageUpload } from "../../utils/images";

export const AddImagesButton = ({ limit = 1, onHandleChangeInput }) => {
  const handleChangeInput = async (files) => {
    let newFiles = [];
    for (let index = 0; index < files.length; index++) {
      if (index >= limit) break;
      const file = files[index];
      const base64 = await beforeImageUpload(file);
      newFiles.push({
        fileName: file["name"],
        base64,
        file,
      });
    }
    onHandleChangeInput(newFiles);
  };

  return (
    <label className="cursor-pointer title-group-form input-file">
      <span className="rounded-circle m-2 py-1 px-2 bg-green-500">
        <i className="bi bi-plus text-white"></i>
      </span>
      <input
        className="file-control form-control text-lg"
        accept="image/png, image/gif, image/jpeg"
        onChange={(e) => handleChangeInput(e.target.files)}
        type="file"
        multiple
        name="files"
      />
    </label>
  );
};
