import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getFormProfile,
  getResponseProfile,
} from "../../../redux/modules/profile/selectors";
import {
  changeFormProfile,
  clearResponseProfile,
  getCurrentProfile,
  updateProfile,
} from "../../../redux/modules/profile/actions";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../utils/messages";
import {
  getCountries,
  getCitiesByDepartment,
  getDepartmentsByCountry,
} from "../../../redux/modules/locations/actions";
import {
  getCitiesLoaded,
  getCountriesLoaded,
  getDepartmentsLoaded,
} from "../../../redux/modules/locations/selectors";
import CommonPanel from "../../ui/CommonPanel";
import CommonModal from "../../ui/CommonModal";

function FormProfile() {
  const dispatch = useDispatch();
  const form = useSelector(getFormProfile);
  const countries = useSelector(getCountriesLoaded);
  const departments = useSelector(getDepartmentsLoaded);
  const cities = useSelector(getCitiesLoaded);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    title: "",
    message: "",
    type: "edit",
    data: "",
  });

  useEffect(() => {
    dispatch(getCurrentProfile.trigger());
  }, []);

  useEffect(() => {
    dispatch(getCountries.trigger());
    if (form.countryId) {
      dispatch(getDepartmentsByCountry.trigger(form.countryId));
      if (form.departmentId) {
        dispatch(getCitiesByDepartment.trigger(form.departmentId));
      }
    }
  }, [form.countryId]);

  const handleChangeInput = (propertyValue, propertyName) => {
    dispatch(changeFormProfile({ value: propertyValue, name: propertyName }));
  };

  const handleSubmitForm = () => {
    if (isValidForm()) {
      if (form.isNew) {
      } else {
        setModalConfig({
          show: true,
          type: "edit",
          title: "Actualizar Perfil",
          message: (
            <div className="w-full">
              <p className="text-center">
                ¿Desea guardar los cambios en el perfil? <br></br>
                <span style={{ fontWeight: 700 }}>{form.name}</span>
              </p>
            </div>
          ),
          data: { id: form.id },
        });
      }
    } else {
      toast(MESSAGES.REQUIRED_FIELDS, {
        type: "error",
      });
    }
  };

  const handleCancel = () => {};

  const isValidForm = () => {
    return (
      form?.fullName &&
      form?.document &&
      form?.documentType &&
      form?.address &&
      form?.cellPhone &&
      form?.userName &&
      form?.password &&
      form?.password2 &&
      form?.password === form?.password2 &&
      form?.cityId
    );
  };

  const handleModalClose = () => {
    setModalConfig({ ...modalConfig, show: false });
  };

  const handleModalOk = () => {
    switch (modalConfig.type) {
      case "edit":
        dispatch(updateProfile.trigger());
        setModalConfig({ ...modalConfig, show: false, data: modalConfig });
        break;
    }
  };

  const handleChangeCountry = (newValue) => {
    dispatch(changeFormProfile({ value: newValue, name: "countryId" }));
    dispatch(changeFormProfile({ value: "", name: "departmentId" }));
    dispatch(changeFormProfile({ value: "", name: "cityId" }));
    dispatch(getDepartmentsByCountry.trigger(newValue));
  };

  const handleChangeDepartment = (newValue) => {
    dispatch(changeFormProfile({ value: newValue, name: "departmentId" }));
    dispatch(changeFormProfile({ value: "", name: "cityId" }));
    dispatch(getCitiesByDepartment.trigger(newValue));
  };

  const endButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <button
        className="btn btn-primary ml-1"
        disabled={!isValidForm()}
        onClick={handleSubmitForm}
      >
        Guardar
      </button>
    </div>
  );

  return (
    <CommonPanel variant="form" title="Mi perfil" endButtons={endButtons}>
      <div className=" w-full p-6 rounded">
        <form id="formProfile">
          <div className="row">
            <div className="col-md-4 form-group">
              <label className="w-full">Nombre o Razón Social</label>
              <input
                className="form-control"
                required
                name="fullName"
                value={form?.fullName}
                onChange={(e) => handleChangeInput(e.target.value, "fullName")}
              />
            </div>
            <div className="col-md-4 form-group">
              <label className="w-full">Tipo de Documento</label>
              <select
                className="form-select"
                name="documentType"
                required
                value={form.documentType}
                onChange={(e) =>
                  handleChangeInput(e.target.value, "documentType")
                }
              >
                <option value="CC">Cedula de ciudadanía</option>
                <option value="NIT">Nit</option>
                <option value="CE">Cedula de extranjería</option>
                <option value="PS">Pasaporte</option>
              </select>
            </div>
            <div className="col-md-4 form-group">
              <label className="w-full pt-1">Número de Documento</label>
              <input
                className="form-control"
                required
                name="document"
                value={form?.document}
                onChange={(e) => handleChangeInput(e.target.value, "document")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 form-group">
              <label className="w-full">País</label>
              <select
                className="form-select"
                name="countryId"
                required
                value={form?.countryId}
                onChange={(e) => handleChangeCountry(e.target.value)}
                disabled={countries && countries.length === 0}
              >
                <option value="">--Seleccionar--</option>
                {countries &&
                  countries.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-4 form-group">
              <label className="w-full">Departamento</label>
              <select
                className="form-select"
                required
                name="departmentId"
                value={form?.departmentId}
                onChange={(e) => handleChangeDepartment(e.target.value)}
                disabled={departments && departments.length === 0}
              >
                <option value="">--Seleccionar--</option>
                {departments &&
                  departments.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-4 form-group">
              <label className="w-full">Ciudad</label>
              <select
                className="form-select"
                name="cityId"
                required
                value={form?.cityId}
                onChange={(e) => handleChangeInput(e.target.value, "cityId")}
                disabled={cities && cities.length === 0}
              >
                <option value="">--Seleccionar--</option>
                {cities &&
                  cities.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 form-group">
              <label className="w-full">Dirección</label>
              <input
                className="form-control"
                required
                name="address"
                value={form?.address}
                onChange={(e) => handleChangeInput(e.target.value, "address")}
              />
            </div>
            <div className="col-md-4 form-group">
              <label className="w-full">Teléfono</label>
              <input
                className="form-control"
                required
                name="phone"
                value={form?.phone}
                onChange={(e) => handleChangeInput(e.target.value, "phone")}
              />
            </div>
            <div className="col-md-4 form-group">
              <label className="w-full">Celular</label>
              <input
                className="form-control"
                required
                name="cellPhone"
                value={form?.cellPhone}
                onChange={(e) => handleChangeInput(e.target.value, "cellPhone")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-2">
              <legend>Opciones de Usuario</legend>
            </div>
            <div className="col-md-3">
              <label className="w-full">Usuario</label>
              <input
                className="form-control"
                required
                name="userName"
                type="text"
                value={form?.userName}
                onChange={(e) => handleChangeInput(e.target.value, "userName")}
              />
            </div>
            <div className="col-md-3">
              <label className="w-full">Correo</label>
              <input
                className="form-control"
                required
                name="email"
                type="email"
                disabled={true}
                value={form?.email}
                onChange={(e) => handleChangeInput(e.target.value, "email")}
              />
            </div>

            <div className="col-md-3">
              <label className="w-full">Contraseña</label>
              <input
                className="form-control"
                required
                type="password"
                name="password"
                value={form?.password}
                onChange={(e) => handleChangeInput(e.target.value, "password")}
              />
            </div>
            <div className="col-md-3">
              <label className="w-full">Repetir Contraseña</label>
              <input
                className="form-control"
                required
                type="password"
                name="password2"
                value={form?.password2}
                onChange={(e) => handleChangeInput(e.target.value, "password2")}
              />
            </div>
          </div>
        </form>
      </div>
      <CommonModal
        config={modalConfig}
        handleModalClose={handleModalClose}
        handleModalOk={handleModalOk}
      ></CommonModal>
    </CommonPanel>
  );
}

export default FormProfile;
