import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAuctions,
  setModalAuctionsConfig,
  setOfferersFilter,
} from "../../../../redux/modules/auctions/actions";
import {
  getModalAuctionsConfig,
  getOfferersByAuction,
} from "../../../../redux/modules/auctions/selectors";
import { setSelectedAuctionId } from "../../../../redux/modules/auctions/actions";
import CommonPanel from "../../../ui/CommonPanel";
import TableOfferers from "../OfferersByAuction/TableOfferers";
import { NavLink } from "react-router-dom";
import SearchBar from "../../../ui/SearchBar";
import FilterBar from "../../../ui/FilterBar";
import CommonModal from "../../../ui/CommonModal";
import { changeStatusAssociatedAuction } from "../../../../redux/modules/associated-auctions/actions";
const OfferersByAuction = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { auctionName, offerers } = useSelector((state) =>
    getOfferersByAuction(state, id)
  );
  const modalConfig = useSelector(getModalAuctionsConfig);

  useEffect(() => {
    if (auctionName === "") {
      dispatch(setSelectedAuctionId(id));
      dispatch(getAuctions.trigger());
    }
  }, [id, auctionName]);

  const startButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <NavLink to="/auctions">Atrás</NavLink>
    </div>
  );

  const handleSearch = (e) => {
    dispatch(setOfferersFilter({ propertyName: "search", propertyValue: e }));
  };

  const handleStatusFilter = (e) => {
    dispatch(setOfferersFilter({ propertyName: "status", propertyValue: e }));
  };

  const handleModalClose = () => {
    dispatch(
      setModalAuctionsConfig({ ...modalConfig, show: false, data: null })
    );
  };

  const handleModalOk = () => {
    dispatch(changeStatusAssociatedAuction.trigger(modalConfig.data));
    dispatch(
      setModalAuctionsConfig({ ...modalConfig, show: false, data: null })
    );
  };

  const filterBarOptions = [
    { name: "Todos", value: "Todos" },
    { name: "Activo", value: "Activo" },
    { name: "No Aprobado", value: "No Aprobado" },
  ];
  const handleClickRow = ({ data, item }) => {
    switch (data.action) {
      default:
        dispatch(
          setModalAuctionsConfig({
            show: true,
            data: { path: data.action, id: item.id, auctionId: id },
            type: data.action,
            title: `${data.title} Oferente`,
            message: (
              <div className="w-full">
                <p className="text-center">
                  {`${
                    data.action === "pending" ? "¿" : "¿Esta seguro que desea"
                  } ${data.title.toUpperCase()} el Oferente?`}
                  <br></br>
                  <span style={{ fontWeight: 700 }}>{item.email}</span>
                </p>
              </div>
            ),
          })
        );
    }
  };

  return (
    <CommonPanel
      title={`Oferentes - ${auctionName}`}
      count={offerers ? offerers.length : 0}
      startButtons={startButtons}
      searchBar={<SearchBar onHandleSearch={handleSearch}></SearchBar>}
      filterBar={
        <FilterBar
          name="Estado"
          options={filterBarOptions}
          onHandleFilter={handleStatusFilter}
        ></FilterBar>
      }
    >
      <TableOfferers
        columns={[
          "Nombre",
          "Documento",
          "Usuario",
          "Correo",
          "Celular",
          "Estado",
          "Opciones",
        ]}
        onHandleClickRow={handleClickRow}
        items={offerers}
      ></TableOfferers>
      <CommonModal
        config={modalConfig}
        handleModalClose={handleModalClose}
        handleModalOk={handleModalOk}
      ></CommonModal>
    </CommonPanel>
  );
};

export default OfferersByAuction;
