import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import CommonModal from "./CommonModal";
import CommonButtonIcon from "./CommonButtonIcon";
import { DeleteIcon, RejectIcon } from "./custom-icons";
export default function UploadFilesModal({
  onHandleUploadFiles,
  modalConfig,
  setModalConfig,
}) {
  const [uploadFiles, setUploadFiles] = useState([]);
  const [removeFiles, setRemoveFiles] = useState([]);

  const handleModalClose = () => {
    setUploadFiles([]);
    setModalConfig({ ...modalConfig, show: false, data: null });
  };

  const handleModalOk = () => {
    onHandleUploadFiles(uploadFiles, removeFiles);
  };
  const handleRemoveFilesInput = async (file, cancel) => {
    if (file.isTemp) {
      const newArray = uploadFiles.filter((x) => x.name !== file.name);
      setUploadFiles(newArray);
    } else {
      if (cancel) {
        const newArray = removeFiles.filter((id) => id !== file.id);
        setRemoveFiles(newArray);
      } else {
        setRemoveFiles([...removeFiles, file.id]);
      }
    }
  };
  const handleUploadFilesInput = async (files) => {
    let temp = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      let fileExist = uploadFiles.find(
        (x) =>
          x.name === file.name &&
          x.associatedAuctionId === modalConfig.data.associatedAuctionId
      );
      if (!fileExist) {
        temp.push({
          associatedAuctionId: modalConfig.data.associatedAuctionId,
          name: file.name,
          file: file,
          isTemp: true,
          id: null,
        });
      }
    }
    setUploadFiles(uploadFiles.concat(temp));
  };

  const buildFiles = (files, title) => {
    if (files && files.length > 0) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontWeight: 700 }}>{title}</span>
          {files.map((file, index) => (
            <div
              key={index + 1}
              style={{
                display: "flex",
                padding: "10px 20px",
                alignItems: "center",
              }}
            >
              <span style={{ width: "20px", padding: "0px 20px" }}>
                {index + 1}
              </span>
              {file.isTemp ? (
                <span
                  style={{
                    width: "350px",
                    padding: "0px 20px",
                    overflowWrap: "break-word",
                  }}
                >
                  {file.name}
                </span>
              ) : (
                <NavLink
                  target="_blank"
                  style={{
                    width: "350px",
                    padding: "0px 20px",
                    overflowWrap: "break-word",
                    textDecoration: removeFiles.includes(file.id)
                      ? "line-through"
                      : "none",
                    color: removeFiles.includes(file.id) ? "red" : "black",
                  }}
                  to={file.url}
                >
                  {file.name}
                </NavLink>
              )}
              <div style={{ width: "40px" }}>
                {removeFiles.includes(file.id) ? (
                  <CommonButtonIcon
                    onHandleClick={() => handleRemoveFilesInput(file, true)}
                    icon={<RejectIcon className="icon-button" />}
                    title={"Cancelar"}
                  ></CommonButtonIcon>
                ) : (
                  <CommonButtonIcon
                    onHandleClick={() => handleRemoveFilesInput(file, false)}
                    icon={<DeleteIcon className="icon-button" />}
                    title={"Eliminar"}
                  ></CommonButtonIcon>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <CommonModal
      config={modalConfig}
      handleModalClose={handleModalClose}
      handleModalOk={handleModalOk}
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="w-full"
          style={{ fontSize: "18px", textAlign: "center" }}
        >
          {modalConfig.title}
          <label className="cursor-pointer title-group-form input-file">
            <span className="rounded-circle m-2 py-1 px-2 bg-green-500">
              <i className="bi bi-plus text-white"></i>
            </span>
            <input
              className="file-control form-control text-lg"
              onChange={(e) => handleUploadFilesInput(e.target.files)}
              type="file"
              multiple
              name="files"
            />
          </label>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {buildFiles(modalConfig.data.files, "Cargados")}
          {buildFiles(uploadFiles, "Pendientes por Cargar")}
        </div>
      </Modal.Body>
    </CommonModal>
  );
}
