import React from "react";
import { NavLink } from "react-router-dom";
import { CountBadge, StatusBadge } from "../../ui/CommonBadges";
import CommonButtonIcon from "../../ui/CommonButtonIcon";
import CommonTable from "../../ui/CommonTable";
import { AttachIcon, UploadIcon, ViewIcon } from "../../ui/custom-icons";
import { CurrencyIcon } from "../../ui/custom-icons/CurrencyIcon";

const TableAssociatedAuctions = ({ columns, items, onHandleClickRow }) => {
  const getCustomOptions = (item) => {
    switch (item.status) {
      case "Activo":
        switch (item.requestStatus) {
          case "No Aprobado":
            return <StatusBadge status={"No Aprobado"} />;
            break;
          case "Pendiente":
            return <StatusBadge status={"Pendiente Aprobación"} />;

            break;
          case "Archivos Pendientes":
            return <StatusBadge status={"Pendiente por Archivos"} />;
            break;
          case "Activo":
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CommonButtonIcon
                  onHandleClick={() =>
                    onHandleClickRow({
                      item,
                      data: { action: "offering-process" },
                    })
                  }
                  icon={<CurrencyIcon className="icon-button" />}
                  title={"Ir a Ofertar"}
                ></CommonButtonIcon>
              </div>
            );
            break;
        }
        break;

      case "Cerrada":
        switch (item.requestStatus) {
          case "No Aprobado":
            return <StatusBadge status={"No Aprobado"} />;
            break;
          case "Pendiente":
            return <StatusBadge status={"Pendiente Aprobación"} />;
            break;
          case "Activo":
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CommonButtonIcon
                  onHandleClick={() =>
                    onHandleClickRow({
                      item,
                      data: { action: "result-process" },
                    })
                  }
                  icon={<CurrencyIcon className="icon-button" />}
                  title={"Ver resultados"}
                ></CommonButtonIcon>
              </div>
            );
            break;
        }

        break;
    }
  };

  const buildItems = (_items) => {
    return (
      _items &&
      _items.length > 0 &&
      _items.map((item, index) => (
        <tr key={`tableAssociatedAuctions-tr${index}`}>
          <td>
            <NavLink to={`/auction/detail/${item.id}`}>
              <div className="flex flex-column">
                <span style={{ fontWeight: 700 }}>{item.code}</span>
                <span className="text-sm">
                  {item.auctionType}-{item.categoryName}
                </span>
                <span>{item.name}</span>
              </div>
            </NavLink>
          </td>
          <td>
            <CountBadge count={item.offerersCount} />
          </td>
          <td>
            <CountBadge count={item.offersCount} />
          </td>
          <td>{item.currentMaxOfferer}</td>
          <td>
            {new Intl.NumberFormat("es-CO").format(item.currentMaxOfferPrice)}
          </td>
          <td>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CountBadge count={item.files.length} />
              {item.requestStatus !== "Activo" && (
                <CommonButtonIcon
                  onHandleClick={() =>
                    onHandleClickRow({
                      item,
                      data: { action: "upload-files" },
                    })
                  }
                  icon={<AttachIcon className="icon-button" />}
                  title={"Subir Archivos"}
                ></CommonButtonIcon>
              )}
            </div>
          </td>
          <td>
            <StatusBadge status={item.status} />
          </td>
          <td>{getCustomOptions(item)}</td>
        </tr>
      ))
    );
  };
  return (
    <div className="w-100 p-3 rounded">
      <div className="flex flex-wrap">
        <CommonTable columns={columns} items={buildItems(items)}></CommonTable>
      </div>
    </div>
  );
};

export default TableAssociatedAuctions;
