import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { getToken } from "../auth/selectors";
import {
  changeStatusAssociatedAuction,
  getAssociatedAuctionPending,
  getAssociatedAuctions,
  uploadAssociatedAuctionFiles,
} from "./actions";
const { REACT_APP_API_BASE_URL } = process.env;

function* getAssociatedAuctionsSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(getAssociatedAuctions.request());
    const response = yield call(
      callApiAxios,
      "GET",
      "auctions/associatedByOfferer/all",
      tokenAccess,
      {},
      {}
    );
    yield put(getAssociatedAuctions.success(response));
  } catch (e) {
    yield put(
      getAssociatedAuctions.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getAssociatedAuctions.fulfill());
  }
}
function* getAssociatedAuctionPendingSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(getAssociatedAuctionPending.request());
    const response = yield call(
      callApiAxios,
      "GET",
      "auctions/requests/all",
      tokenAccess,
      {},
      {}
    );
    yield put(getAssociatedAuctionPending.success(response));
  } catch (e) {
    yield put(
      getAssociatedAuctionPending.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getAssociatedAuctionPending.fulfill());
  }
}
function* uploadAssociatedAuctionFilesSaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(uploadAssociatedAuctionFiles.request());
    let hasNewImages = payload.uploadFiles.length > 0;
    let hasRemoveImages = payload.removeFiles.length > 0;
    const data = new FormData();
    for (let index = 0; index < payload.uploadFiles.length; index++) {
      const file = payload.uploadFiles[index].file;
      data.append("files[]", file, file["name"]);
    }
    if (hasNewImages) {
      const response = yield call(
        fetch,
        `${REACT_APP_API_BASE_URL}/api/auctions/${payload.id}/setFilesAssociatedAuction`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${tokenAccess}`,
          },
          body: data,
        }
      );
    }
    if (hasRemoveImages) {
      const body = { list: payload.removeFiles };
      const response2 = yield call(
        callApiAxios,
        "PATCH",
        `auctions/${payload.id}/removeFilesAssociatedAuction`,
        tokenAccess,
        {},
        body
      );
    }
    yield put(uploadAssociatedAuctionFiles.success("OK"));
  } catch (e) {
    yield put(
      uploadAssociatedAuctionFiles.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(uploadAssociatedAuctionFiles.fulfill());
  }
}

export default function* associatedAuctionsSaga() {
  yield takeEvery(getAssociatedAuctions.TRIGGER, getAssociatedAuctionsSaga);
  yield takeEvery(
    uploadAssociatedAuctionFiles.SUCCESS,
    getAssociatedAuctionsSaga
  );
  yield takeEvery(
    uploadAssociatedAuctionFiles.TRIGGER,
    uploadAssociatedAuctionFilesSaga
  );
  yield takeEvery(
    getAssociatedAuctionPending.TRIGGER,
    getAssociatedAuctionPendingSaga
  );
  yield takeEvery(
    uploadAssociatedAuctionFiles.SUCCESS,
    getAssociatedAuctionPendingSaga
  );
  yield takeEvery(
    changeStatusAssociatedAuction.SUCCESS,
    getAssociatedAuctionPendingSaga
  );
}
