import * as type from "./types";
import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";

export const getCurrentProfile = createRoutine(type.GET_CURRENT_PROFILE);
export const updateProfile = createRoutine(type.UPDATE_PROFILE);

export const changeFormProfile = createAction(type.CHANGE_FORM_PROFILE)
export const loadFormProfile = createAction(type.LOAD_FORM_PROFILE);
export const resetFormProfile = createAction(type.RESET_FORM_PROFILE);
export const clearResponseProfile = createAction(type.CLEAR_RESPONSE_PROFILE);