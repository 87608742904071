export default function SocialBar() {
  return (
    <div className="social-bar">
      <a
        href="https://www.facebook.com/Central-de-Subastas-y-Remates-102043888689570"
        className="icon icon-facebook2"
        target="_blank"
      >
          <i className="bi bi-facebook"></i>
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=573212275222"
        className="icon icon-whatsapp"
        target="_blank"
      >
        <i className="bi bi-whatsapp"></i>
      </a>
      <a
        href="https://www.instagram.com/centraldesubastasyremates/"
        className="icon icon-instagram"
        target="_blank"
      >
          <i className="bi bi-instagram"></i>
      </a>
    </div>
  );
}
