import { useState } from "react";

export default function FilterBar({ name, options, onHandleFilter }) {
  const [filterValue, setFilterValue] = useState("");

  const handleFilter = (e) => {
    const newValue = e.target.value;
    setFilterValue(newValue);
    onHandleFilter(newValue);
  };

  return (
    <div className="flex">
      <label className="mr-1 ">{name}:</label>
      <select
        style={{ width: "auto" }}
        className="form-select mr-3"
        name="status"
        value={filterValue}
        onChange={(e) => handleFilter(e)}
      >
        {options &&
          options.length > 0 &&
          options.map((option, index) => (
            <option key={`option-${index}`} value={option.value}>
              {option.name}
            </option>
          ))}
      </select>
    </div>
  );
}
