import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";
import * as type from "./types";

export const getUsers = createRoutine(type.GET_USERS);
export const createUser = createRoutine(type.CREATE_USER);
export const changeFormUser = createAction(type.CHANGE_FORM_USER);
export const getUsersByStatus = createAction(type.GET_USERS_BY_STATUS);
export const clearResponseUsers = createAction(type.CLEAR_RESPONSE_USERS);
export const getUsersByType = createAction(type.GET_USERS_BY_TYPE);
export const updateUser = createRoutine(type.UPDATE_USER);
export const deleteUser = createRoutine(type.DELETE_USER);
export const changeStatusUser = createRoutine(type.CHANGE_STATUS_USER);
export const changeStatusUserPending = createRoutine(type.CHANGE_STATUS_USER_PENDING);
export const getPendingUsers = createRoutine(type.GET_PENDING_USERS);
export const setUsersFilter = createAction(type.SET_USERS_FILTER);
export const loadFormUser = createAction(type.LOAD_FORM_USER);
export const resetFormUser = createAction(type.RESET_FORM_USER);
export const setModalUsersConfig = createAction(type.SET_MODAL_USERS_CONFIG);
