import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";
import * as types from "./types";

export const getAssociatedAuctions = createRoutine(types.GET_ASSOCIATED_AUCTIONS);
export const uploadAssociatedAuctionFiles = createRoutine(types.UPLOAD_ASSOCIATED_AUCTION_FILES);
export const setOffersFilter = createAction(types.SET_OFFERS_FILTER);
export const setAssociatedAuction = createAction(types.SET_ASSOCIATED_AUCTION);
export const clearResponseOffers = createAction(types.CLEAR_RESPONSE_OFFERS);
export const getAssociatedAuctionPending = createRoutine(
    types.GET_ASSOCIATED_AUCTIONS_PENDING
  );
  export const changeStatusAssociatedAuction = createRoutine(
    types.CHANGE_STATUS_ASSOCIATED_AUCTION
  );