import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import {
  getAuctions,
  setOffersFilter, setSelectedAuctionId
} from "../../../../redux/modules/auctions/actions";
import { getOffersByAuction } from "../../../../redux/modules/auctions/selectors";
import CommonPanel from "../../../ui/CommonPanel";
import SearchBar from "../../../ui/SearchBar";
import TableOffers from "./TableOffers";
const OffersByAuction = () => {
  const dispatch = useDispatch();
  const { auctionName, offers } = useSelector(getOffersByAuction);
  const { id } = useParams();

  useEffect(() => {
    if (auctionName === "") {
      dispatch(setSelectedAuctionId(id));
      dispatch(getAuctions.trigger());
    }
  }, [id, auctionName]);

  const startButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <NavLink to="/auctions">Atrás</NavLink>
    </div>
  );

  const handleSearch = (e) => {
    dispatch(setOffersFilter({ propertyName: "search", propertyValue: e }));
  };

  const handleClickRow = ({ data, item }) => {};

  return (
    <CommonPanel
      title={`Ofertas - ${auctionName}`}
      count={offers ? offers.length : 0}
      startButtons={startButtons}
      searchBar={<SearchBar onHandleSearch={handleSearch}></SearchBar>}
    >
      <TableOffers
        columns={[ "Fecha Oferta","Oferente", "Oferta Anterior", "Oferta Actual", ]}
        onHandleClickRow={handleClickRow}
        items={offers}
      ></TableOffers>
    </CommonPanel>
  );
};

export default OffersByAuction;
