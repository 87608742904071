import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { finishActiveAuction } from "../../../redux/modules/auctions/actions";
const { REACT_APP_ASSETS_URL } = process.env;

const Countdown = ({ countdownDate, auctionId }) => {
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();

  const [timerDays, setTimerDays] = useState(0);
  const [timerHours, setTimerHours] = useState(0);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);

  let interval = null;

  const startTimer = () => {
    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance <= 0) {
        setStatus("Finished");
        dispatch(finishActiveAuction(auctionId));
        clearInterval(interval);
      } else if (minutes < 3) {
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
        setStatus("LastLap3");
      } else if (minutes < 6) {
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
        setStatus("LastLap6");
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
        setStatus("Started");
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => clearInterval(interval);
  }, [countdownDate]);

  let template = "";
  switch (status) {
    case "Started":
      template = (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="loader-green"></span>

          <span style={{ fontWeight: 700, fontSize: "1.2rem" }}>
            {`Cierra en ${timerDays !== 0 ? timerDays + " día(s) | " : ""}${
              timerHours !== 0 ? timerHours + " hora(s) | " : ""
            }${timerMinutes !== 0 ? timerMinutes + " minutos | " : ""}${
              timerSeconds !== 0 ? timerSeconds + " segundos" : ""
            }`}
          </span>
        </div>
      );
      break;
    case "LastLap3":
      template = (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "80px",
              height: "80px",
              backgroundSize: "80px",
              backgroundPositionY: "80px",
              backgroundPositionX: "2px",
              backgroundImage: `url('${REACT_APP_ASSETS_URL}/assets/images/loser.gif')`,
            }}
          ></div>

          <span style={{ fontWeight: 700, fontSize: "1.2rem" }}>
            {`Cierra en ${timerDays !== 0 ? timerDays + " día(s) | " : ""}${
              timerHours !== 0 ? timerHours + " hora(s) | " : ""
            }${timerMinutes !== 0 ? timerMinutes + " minutos | " : ""}${
              timerSeconds !== 0 ? timerSeconds + " segundos" : ""
            }`}
          </span>
        </div>
      );
      break;
    case "LastLap6":
      template = (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="loader-lastlap"></span>

          <span style={{ fontWeight: 700, fontSize: "1.2rem" }}>
            {`Cierra en ${timerDays !== 0 ? timerDays + " día(s) | " : ""}${
              timerHours !== 0 ? timerHours + " hora(s) | " : ""
            }${timerMinutes !== 0 ? timerMinutes + " minutos | " : ""}${
              timerSeconds !== 0 ? timerSeconds + " segundos" : ""
            }`}
          </span>
        </div>
      );
      break;
    case "Finished":
      template = "Subasta Cerrada";
      break;
  }

  return template;
};

export default Countdown;
