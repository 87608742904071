import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { changeStatusAssociatedAuction } from "../associated-auctions/actions";
import { getToken } from "../auth/selectors";
import {
  changeStatusAuction,
  createAuction,
  deleteAuction,
  getActiveAuctions,
  getAuctions,
  getCurrentAuctionById,
  requestAssociateAuction,
  requestOffererProcess,
  updateAuction,
} from "./actions";
import { getFormAuctions, getLastAuctionId } from "./selectors";
const { REACT_APP_API_BASE_URL } = process.env;

function* getActiveAuctionsSaga() {
  try {
    yield put(getActiveAuctions.request());
    const tokenAccess = yield select(getToken);
    const response = yield call(
      callApiAxios,
      "GET",
      `${tokenAccess ? "auctions/getActiveAuctions" : "landingPage/getActiveAuctions"}`,
      tokenAccess && tokenAccess,
      {},
      {}
    );
    yield put(getActiveAuctions.success(response));
  } catch (e) {
    yield put(
      getActiveAuctions.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getActiveAuctions.fulfill());
  }
}
function* getCurrentAuctionByIdSaga({ auctionId, tokenAccess }) {
  try {
    tokenAccess = tokenAccess ? tokenAccess : yield select(getToken);
    auctionId = auctionId ? auctionId : yield select(getLastAuctionId);
    yield put(getCurrentAuctionById.request());
    const response = yield call(
      callApiAxios,
      "GET",
      `${
        tokenAccess ? "auctions" : "landingPage/getActiveAuction"
      }/${auctionId}`,
      tokenAccess && tokenAccess,
      {},
      {}
    );
    yield put(getCurrentAuctionById.success(response));
  } catch (e) {
    yield put(
      getCurrentAuctionById.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getCurrentAuctionById.fulfill());
  }
}
function* requestOffererProcessSaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(requestOffererProcess.request());
    const body = {};
    const response = yield call(
      callApiAxios,
      "PATCH",
      `auctions/${payload.id}/makeOffer/${payload.offerPrice}`,
      tokenAccess,
      {},
      body
    );
    yield put(
      requestOffererProcess.success({ ...response, auctionId: payload.id })
    );
  } catch (e) {
    yield put(
      requestOffererProcess.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(requestOffererProcess.fulfill());
  }
}
function* requestAssociateAuctionSaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(requestAssociateAuction.request());
    const body = {};
    const response = yield call(
      callApiAxios,
      "PATCH",
      `auctions/${payload}/requestAssociate`,
      tokenAccess,
      {},
      body
    );
    yield put(requestAssociateAuction.success(response));
  } catch (e) {
    yield put(
      requestAssociateAuction.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(requestAssociateAuction.fulfill());
  }
}

function* getAuctionsSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(getAuctions.request());
    const response = yield call(
      callApiAxios,
      "GET",
      "auctions",
      tokenAccess,
      {},
      {}
    );
    yield put(getAuctions.success(response));
  } catch (e) {
    yield put(
      getAuctions.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getAuctions.fulfill());
  }
}
function* createAuctionSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(createAuction.request());
    const form = yield select(getFormAuctions);
    const body = {
      code: form.code,
      companyId: form.companyId,
      categoryId: form.categoryId,
      offererId: form.offererId,
      cityId: form.cityId,
      auctionType: form.auctionType,
      name: form.name,
      description: form.description,
      shortDescription: form.shortDescription,
      minimumPrice: form.minimumPrice,
      minimumIncrease: form.minimumIncrease,
      reservePrice: form.reservePrice,
      startAt: form.startAt,
      closeAt: form.closeAt,
      endAt: form.endAt,
      finalOffer: form.finalOffer,
      urlImage: form.urlImage,
      address: form.address,
      commission: form.commission,
      requirementsLink: form.requirementsLink,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      "auctions",
      tokenAccess,
      {},
      body
    );
    let hasNewImages = form.files.length > 0;
    const data = new FormData();
    for (let index = 0; index < form.files.length; index++) {
      const file = form.files[index];
      data.append("files[]", file, file["name"]);
    }
    if (hasNewImages) {
      const params =
        form.defaultImage !== "" ? `?index=${form.defaultImage}` : "";
      const url = `${REACT_APP_API_BASE_URL}/api/auctions/${response.data.id}/setImagesAuction${params}`;

      const responseImages = yield call(fetch, url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
        },
        body: data,
      });
    }

    yield put(createAuction.success(response));
  } catch (e) {
    yield put(
      createAuction.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(createAuction.fulfill());
  }
}
function* changeStatusAuctionSaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(changeStatusAuction.request());
    const response = yield call(
      callApiAxios,
      "PATCH",
      `auctions/${payload.id}/changeStatus/${payload.path}`,
      tokenAccess,
      {},
      {}
    );
    yield put(changeStatusAuction.success(response));
  } catch (e) {
    yield put(
      changeStatusAuction.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(changeStatusAuction.fulfill());
  }
}
function* changeStatusAssociatedAuctionSaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(changeStatusAssociatedAuction.request());
    const response = yield call(
      callApiAxios,
      "PATCH",
      `auctions/${payload.auctionId}/changeStatusAssociatedAuction/${payload.id}/${payload.path}`,
      tokenAccess,
      {},
      {}
    );
    yield put(changeStatusAssociatedAuction.success(response));
  } catch (e) {
    yield put(
      changeStatusAssociatedAuction.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(changeStatusAssociatedAuction.fulfill());
  }
}
function* deleteAuctionSaga({ payload }) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(deleteAuction.request());
    const response = yield call(
      callApiAxios,
      "DELETE",
      `auctions/${payload.id}`,
      tokenAccess,
      {},
      {}
    );
    yield put(deleteAuction.success(response));
  } catch (e) {
    yield put(
      deleteAuction.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(deleteAuction.fulfill());
  }
}
function* updateAuctionSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(updateAuction.request());
    const form = yield select(getFormAuctions);
    const body = {
      id: form.id,
      code: form.code,
      companyId: form.companyId,
      categoryId: form.categoryId,
      offererId: form.offererId,
      cityId: form.cityId,
      auctionType: form.auctionType,
      name: form.name,
      description: form.description,
      shortDescription: form.shortDescription,
      minimumPrice: form.minimumPrice,
      minimumIncrease: form.minimumIncrease,
      reservePrice: form.reservePrice,
      startAt: form.startAt,
      closeAt: form.closeAt,
      endAt: form.endAt,
      urlImage: form.urlImage,
      address: form.address,
      commission: form.commission,
      requirementsLink: form.requirementsLink,
      removeImages: form.removeImages,
    };
    const response = yield call(
      callApiAxios,
      "PUT",
      `auctions/${body.id}`,
      tokenAccess,
      {},
      body
    );
    let hasNewImages = form.files.length > 0;
    const data = new FormData();
    for (let index = 0; index < form.files.length; index++) {
      const file = form.files[index];
      data.append("files[]", file, file["name"]);
    }
    if (hasNewImages) {
      const params =
        form.defaultImage !== "" ? `?index=${form.defaultImage}` : "";
      const url = `${REACT_APP_API_BASE_URL}/api/auctions/${form.id}/setImagesAuction${params}`;

      const responseImages = yield call(fetch, url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
        },
        body: data,
      });
    }
    yield put(updateAuction.success(response));
  } catch (e) {
    yield put(
      updateAuction.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(updateAuction.fulfill());
  }
}

export default function* auctionsSaga() {
  yield takeEvery(requestOffererProcess.TRIGGER, requestOffererProcessSaga);
  yield takeEvery(getActiveAuctions.TRIGGER, getActiveAuctionsSaga);
  // yield takeEvery(
  //   requestOffererProcess.SUCCESS,
  //   getCurrentStatusByAuctionIdSaga
  // );
  yield takeEvery(requestAssociateAuction.TRIGGER, requestAssociateAuctionSaga);
  yield takeEvery(getAuctions.TRIGGER, getAuctionsSaga);
  yield takeEvery(getCurrentAuctionById.TRIGGER, getCurrentAuctionByIdSaga);
  yield takeEvery(requestAssociateAuction.SUCCESS, getCurrentAuctionByIdSaga);

  yield takeEvery(
    changeStatusAssociatedAuction.TRIGGER,
    changeStatusAssociatedAuctionSaga
  );
  yield takeEvery(createAuction.TRIGGER, createAuctionSaga);
  yield takeEvery(updateAuction.TRIGGER, updateAuctionSaga);
  yield takeEvery(deleteAuction.TRIGGER, deleteAuctionSaga);
  yield takeEvery(changeStatusAuction.TRIGGER, changeStatusAuctionSaga);
  yield takeEvery(deleteAuction.SUCCESS, getAuctionsSaga);
  yield takeEvery(updateAuction.SUCCESS, getAuctionsSaga);
  yield takeEvery(changeStatusAuction.SUCCESS, getAuctionsSaga);

  yield takeEvery(changeStatusAssociatedAuction.SUCCESS, getAuctionsSaga);
}
