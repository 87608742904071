import { initialState } from "./states";
import {
  createOfferer,
  getOfferers,
  changeStatusOfferer,
  clearResponseOfferers,
  updateOfferer,
} from "./actions";
import * as types from "./types";
import produce from "immer";

export const offerers = produce((draft, { type, payload }) => {
  switch (type) {
    case getOfferers.TRIGGER:
      draft.loading = true;
      break;
    case getOfferers.REQUEST:
      break;
    case getOfferers.SUCCESS:
      draft.offerers = payload;
      break;
    case getOfferers.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getOfferers.FULFILL:
      draft.loading = false;
      break;
    case types.LOAD_FORM_OFFERER:
      draft.form.id = payload.id;
      draft.form.fullName = payload.fullName;
      draft.form.offererId = payload.offererId;
      draft.form.countryId = payload.countryId;
      draft.form.departmentId = payload.departmentId;
      draft.form.cityId = payload.cityId;
      draft.form.documentType = payload.documentType;
      draft.form.document = payload.document;
      draft.form.phone = payload.phone;
      draft.form.cellPhone = payload.cellPhone;
      draft.form.address = payload.address;
      draft.form.userName = payload.userName;
      draft.form.userId = payload.userId;
      draft.form.email = payload.email;
      draft.form.password = "#PASSWORD!";
      draft.form.isNew = false;
      break;
    case createOfferer.TRIGGER:
      draft.loading = true;
      break;
    case createOfferer.REQUEST:
      break;
    case createOfferer.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      break;
    case createOfferer.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case createOfferer.FULFILL:
      draft.loading = false;
      break;

    case updateOfferer.TRIGGER:
      draft.loading = true;
      break;
    case updateOfferer.REQUEST:
      break;
    case updateOfferer.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      draft.form = initialState.form;
      break;
    case updateOfferer.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      draft.form = initialState.form;
      break;
    case updateOfferer.FULFILL:
      draft.loading = false;
      break;

    case changeStatusOfferer.TRIGGER:
      draft.loading = true;
      break;
    case changeStatusOfferer.REQUEST:
      break;
    case changeStatusOfferer.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      break;
    case changeStatusOfferer.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case changeStatusOfferer.FULFILL:
      draft.loading = false;
      break;

    case types.CHANGE_FORM_OFFERER:
      draft.form[payload.name] = payload.value;
      break;

    case types.RESET_FORM_OFFERER:
      draft.form = initialState.form;
      break;

    case types.SET_MODAL_OFFERERS_CONFIG:
      draft.modal = payload;
      break;
    case types.SET_OFFERERS_FILTER:
      draft.filters[payload.propertyName] = payload.propertyValue;
      break;
    case clearResponseOfferers.toString():
      draft.response = initialState.response;
      break;
  }
}, initialState);
