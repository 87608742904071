export const locationsState = state => state.locations;

export const getLoadingLocations = state => locationsState(state).loading;

export const getCountriesLoaded = state => locationsState(state).countries;
export const getDepartmentsLoaded = state => locationsState(state).departments;
export const getCitiesLoaded = state => locationsState(state).cities;

export const getResponseLocations = state => locationsState(state).response;


