import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  changeFormSignIn,
  clearResponseSignIn,
  doSignInUser,
  doVerificationCode,
  resetFormSignIn
} from "../../../redux/modules/sign-in/actions";
import {
  getFormSignIn,
  getResponseSignIn,
} from "../../../redux/modules/sign-in/selectors";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  getCitiesByDepartment,
  getCountries,
  getDepartmentsByCountry,
} from "../../../redux/modules/locations/actions";
import {
  getCitiesLoaded,
  getCountriesLoaded,
  getDepartmentsLoaded,
} from "../../../redux/modules/locations/selectors";
import { MESSAGES } from "../../../utils/messages";
import CommonModal from "../../ui/CommonModal";

const { REACT_APP_ASSETS_URL } = process.env;

function SignIn() {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const response = useSelector(getResponseSignIn);
  const form = useSelector(getFormSignIn);
  const countries = useSelector(getCountriesLoaded);
  const departments = useSelector(getDepartmentsLoaded);
  const cities = useSelector(getCitiesLoaded);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    title: "Validación de correo electronico",
    message: "",
    type: "code",
    data: "",
  });

  useEffect(() => {
    dispatch(resetFormSignIn());
  }, []);


  useEffect(() => {
    dispatch(getCountries.trigger());
  }, []);

  useEffect(() => {
    switch (response.result) {
      case "OK":
        setModalConfig({ ...modalConfig, show: false });
        navigate("/login");
        dispatch(clearResponseSignIn());
        break;
      case "ValidationCode":
        setModalConfig({ ...modalConfig, show: true });
        break;
    }
  }, [response.result]);

  const handleSubmitForm = (e) => {
    if (isValidForm()) {
      dispatch(doSignInUser.trigger());
    } else {
      toast(MESSAGES.REQUIRED_FIELDS, {
        type: "error",
      });
    }
  };

  const handleChangeInput = (propertyValue, propertyName) => {
    dispatch(changeFormSignIn({ value: propertyValue, name: propertyName }));
  };

  const handleModalClose = () => {
    setModalConfig({ ...modalConfig, show: false });
  };

  const handleModalOk = () => {
    switch (modalConfig.type) {
      case "code":
        dispatch(doVerificationCode.trigger());
        break;
    }
  };

  const isValidForm = () => {
    return (
      form?.fullName &&
      form?.document &&
      form?.documentType &&
      form?.address &&
      form?.cellPhone &&
      form?.email &&
      form?.userName &&
      form?.password &&
      form?.password2 &&
      form?.password === form?.password2 &&
      form?.cityId &&
      form?.acceptPDT
    );
  };

  const handleChangeCountry = (e) => {
    dispatch(changeFormSignIn({ value: e.target.value, name: "countryId" }));
    dispatch(changeFormSignIn({ value: "", name: "departmentId" }));
    dispatch(changeFormSignIn({ value: "", name: "cityId" }));
    dispatch(getDepartmentsByCountry.trigger(e.target.value));
  };

  const handleChangeDepartment = (e) => {
    dispatch(changeFormSignIn({ value: e.target.value, name: "departmentId" }));
    dispatch(changeFormSignIn({ value: "", name: "cityId" }));
    dispatch(getCitiesByDepartment.trigger(e.target.value));
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8 col-md-offset-4">
          <div className="panel panel-border panel-default mt-30 mb-30 shadow-sm">
            <div className="panel-header panel-heading">
              <div className="row   justify-content-center">
                <div className="col-auto">
                  <img
                    src={`${REACT_APP_ASSETS_URL}/assets/images/logo-blanco.png`}
                    width="250px"
                  />
                </div>
              </div>
            </div>
            <div className="row panel-body">
              <div className="text-center">
                <span style={{ fontSize: "1.3rem", fontWeight: 600 }}>
                  Registrarme
                </span>
              </div>
              <div className="col-md-12 form-group">
                <label>
                  <span className="text-danger">*</span>Nombre Completo o Razón
                  social
                </label>
                <input
                  className="form-control"
                  name="fullName"
                  type="text"
                  maxLength="110"
                  required
                  value={form?.fullName}
                  onChange={(e) =>
                    handleChangeInput(e.target.value, "fullName")
                  }
                />
              </div>
              <div className="col-md-6 form-group">
                <label>
                  <span className="text-danger">*</span>Tipo de Documento
                </label>
                <select
                  className="form-select"
                  name="documentType"
                  maxLength="5"
                  required
                  value={form?.documentType}
                  onChange={(e) =>
                    handleChangeInput(e.target.value, "documentType")
                  }
                >
                  <option value="CC">Cedula de ciudadanía</option>
                  <option value="NT">Nit</option>
                  <option value="CE">Cedula de extranjería</option>
                  <option value="PS">Pasaporte</option>
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label>
                  <span className="text-danger">*</span>Número de Documento
                </label>
                <input
                  className="form-control"
                  name="document"
                  required
                  type="text"
                  maxLength="20"
                  value={form?.document}
                  onChange={(e) =>
                    handleChangeInput(e.target.value, "document")
                  }
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Teléfono </label>
                <input
                  className="form-control"
                  name="phone"
                  maxLength="20"
                  onChange={(e) => handleChangeInput(e.target.value, "phone")}
                  value={form?.phone}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>
                  <span className="text-danger">*</span>Celular
                </label>
                <input
                  className="form-control"
                  required
                  name="cellPhone"
                  maxLength="20"
                  onChange={(e) =>
                    handleChangeInput(e.target.value, "cellPhone")
                  }
                  value={form?.cellPhone}
                />
              </div>

              <div className="col-md-6 form-group">
                <label>
                  <span className="text-danger">*</span>Pais
                </label>
                <select
                  className="form-select"
                  name="countryId"
                  maxLength="36"
                  value={form?.countryId}
                  required
                  onChange={handleChangeCountry}
                >
                  <option value="">--Seleccionar--</option>
                  {countries &&
                    countries.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label>
                  <span className="text-danger">*</span>Departamento
                </label>
                <select
                  className="form-select"
                  name="departmentId"
                  maxLength="36"
                  required
                  value={form?.departmentId}
                  onChange={handleChangeDepartment}
                  disabled={departments && departments.length === 0}
                >
                  <option value="">--Seleccionar--</option>
                  {departments &&
                    departments.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label>
                  <span className="text-danger">*</span>Ciudad
                </label>
                <select
                  className="form-select"
                  name="cityId"
                  maxLength="36"
                  required
                  value={form?.cityId}
                  onChange={(e) => handleChangeInput(e.target.value, "cityId")}
                  disabled={cities && cities.length === 0}
                >
                  <option value="">--Seleccionar--</option>
                  {cities &&
                    cities.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label>
                  <span className="text-danger">*</span>Dirección
                </label>
                <input
                  className="form-control"
                  required
                  name="address"
                  maxLength="250"
                  onChange={(e) => handleChangeInput(e.target.value, "address")}
                  value={form?.address}
                />
              </div>
              <div className="col-md-12">
                <h5>Opciones de Usuario</h5>
              </div>
              <div className="col-md-6 form-group">
                <label>
                  <span className="text-danger">*</span>Usuario
                </label>
                <input
                  className="form-control"
                  required
                  name="userName"
                  type="text"
                  maxLength="255"
                  value={form?.userName}
                  onChange={(e) =>
                    handleChangeInput(e.target.value, "userName")
                  }
                />
              </div>
              <div className="col-md-6 form-group">
                <label>
                  <span className="text-danger">*</span>Correo
                </label>
                <input
                  className="form-control"
                  required
                  name="email"
                  type="email"
                  maxLength="255"
                  value={form?.email}
                  onChange={(e) => handleChangeInput(e.target.value, "email")}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>
                  <span className="text-danger">*</span>Contraseña
                </label>
                <input
                  className="form-control"
                  required
                  name="password"
                  type="password"
                  maxLength="255"
                  value={form?.password}
                  onChange={(e) =>
                    handleChangeInput(e.target.value, "password")
                  }
                />
              </div>

              <div className="col-md-6 form-group">
                <label>
                  <span className="text-danger">*</span>Confirmar contraseña
                </label>
                <input
                  className="form-control"
                  required
                  name="password2"
                  type="password"
                  maxLength="255"
                  value={form?.password2}
                  onChange={(e) =>
                    handleChangeInput(e.target.value, "password2")
                  }
                />
              </div>

              <div className="col-md-12 pt-2">
                <h5 className="font-bold"> Términos y condiciones de uso </h5>
              </div>
              <div className="col-md-12 form-group">
                <label>
                  <input
                    className="p-2 m-2"
                    required
                    checked={form?.acceptPDT}
                    onChange={(e) =>
                      handleChangeInput(!form?.acceptPDT, "acceptPDT")
                    }
                    name="acceptPDT"
                    type="checkbox"
                  />{" "}
                  <span className="text-danger">*</span>He leído atentamente la{" "}
                  <a
                    className="text-success"
                    target="_blank"
                    href="assets/files/POLÍTICA_DE_PRIVACIDAD_Y_TRATAMIENTO DE DATOS PERSONALES.pdf"
                  >
                    política de privacidad y tratamiento de datos personales
                  </a>{" "}
                  y{" "}
                  <a
                    className="text-success"
                    target="_blank"
                    href="assets/files/TERMINOS_Y_CONDICIONES_DE_USO.pdf"
                  >
                    terminos y condiciones de uso de la plataforma
                  </a>{" "}
                  y autorizo el uso de mis datos
                </label>
              </div>
              <div className="col-md-12 form-group">
                <label>
                  <input
                    className="p-2 m-2"
                    name="acceptSMS"
                    checked={form?.acceptSMS}
                    onChange={(e) =>
                      handleChangeInput(!form?.acceptSMS, "acceptSMS")
                    }
                    type="checkbox"
                  />{" "}
                  Deseo recibir información publicitaria de Central de Subastas
                  y Remates.{" "}
                </label>
              </div>

              <button
                className="btn btn-primary btn-block"
                disabled={!isValidForm()}
                onClick={handleSubmitForm}
              >
                Registrarme
              </button>
              <p>
                ¿Ya estas registrado?{" "}
                <NavLink to="/login"> Inicia sesión aquí</NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
      <CommonModal
        config={modalConfig}
        handleModalClose={handleModalClose}
        handleModalOk={handleModalOk}
        isOkDisabled={!form?.verifyCode}
        backdrop="static"
        keyboard={false}
      >
        <div className="flex flex-column pt-2">
          <div className="w-full">
            Ingrese el codigo de verificación enviado al correo electronico. Si no te ha llegado, revisa en la bandeja de spam o dale click en Reenviar.{" "}
            <p className="text-center font-bold">{form?.email}</p>
          </div>
          <div className="flex flex-row justify-center p-2">
            <div>
              <label className="px-2">Código</label>
            </div>
            <div>
              <input
                className="form-control w-80 font-bold  text-lg"
                type="text"
                name="verifyCode"
                value={form?.verifyCode}
                onChange={(e) =>
                  handleChangeInput(e.target.value, "verifyCode")
                }
              />
            </div>
          </div>
        </div>
      </CommonModal>
    </div>
  );
}

export default SignIn;
