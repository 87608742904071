export const loadImage = async (file) => {
  let promise = new Promise((resolve, reject) => {
    const isJPG = file.type === "image/jpeg" ;
    const isPng = file.type === "image/png";

    if (!isJPG) {
      if (!isPng) {
        return false;
      }
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      return false;
    }
    getBase64(file, (base64) => {
      if (base64) {
        resolve(base64);
      }
    });
  });
  return promise;
};

export const beforeImageUpload = async (file) => {
  const result = await loadImage(file);
  return result;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
