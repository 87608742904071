import { Link } from "react-router-dom";
import { CommonTooltip } from "./CommonTooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
export const AdditionalButton = ({ title, linkTo, iconClass }) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250 }}
      overlay={CommonTooltip(title)}
    >
      <Link
        style={{ backgroundColor: "#005493", width: "35px", height: "35px" }}
        className="rounded-circle text-white text-center"
        to={linkTo}
      >
        <i className={`bi ${iconClass} fs-5`}></i>
      </Link>
    </OverlayTrigger>
  );
};
