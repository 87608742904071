export const initialState = {
  loading: false,
  response: { message: null, error: null, result:null },
  companies: [],
  company: null,
  filters: {
    search: "",
    status: "Todos",
  },
  form: {
    isNew: true,
    documentType: "NIT",
    document: "",
    name: "",
    description: "",
    phone: "",
    cellPhone: "",
    countryId: "",
    departmentId: "",
    cityId: "",
    address: "",
    images:[],
    files:[]
  },
  
  modal: {
    show: false,
    title: "",
    message: "",
    type: "",
    data: "",
    size: null,
  },
};
