import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { getToken } from "../auth/selectors";
import {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  changeStatusCategory
} from "./actions";
import { getFormCategories } from "./selectors";

function* getCategoriesSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(getCategories.request());
    const response = yield call(
      callApiAxios,
      "GET",
      "categories",
      tokenAccess,
      {},
      {}
    );
    yield put(getCategories.success(response));
  } catch (e) {
    yield put(
      getCategories.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getCategories.fulfill());
  }
}

function* createCategorySaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(createCategory.request());
    const form = yield select(getFormCategories);
    const body = {
      code: form.code,
      name: form.name,
      description: form.description,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      "categories",
      tokenAccess,
      {},
      body
    );
    yield put(createCategory.success(response));
  } catch (e) {
    yield put(
      createCategory.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(createCategory.fulfill());
  }
}

function* updateCategorySaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(updateCategory.request());
    const form = yield select(getFormCategories);
    const body = {
      id: form.id,
      code: form.code,
      name: form.name,
      description: form.description,
    };
    const response = yield call(
      callApiAxios,
      "PUT",
      `categories/${body.id}`,
      tokenAccess,
      {},
      body
    );
    yield put(updateCategory.success(response));
  } catch (e) {
    yield put(
      updateCategory.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(updateCategory.fulfill());
  }
}

function* deleteCategorySaga({payload}) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(deleteCategory.request());
    const response = yield call(
      callApiAxios,
      "DELETE",
      `categories/${payload.id}`,
      tokenAccess,
      {},
      {}
    );
    yield put(deleteCategory.success(response));
  } catch (e) {
    yield put(
      deleteCategory.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(deleteCategory.fulfill());
  }
}

function* changeStatusCategorySaga({payload}) {
  try {
    const tokenAccess = yield select(getToken);
    yield put(changeStatusCategory.request());
    const response = yield call(
      callApiAxios,      
      "PATCH",
      `categories/${payload.id}/changeStatus/${payload.path}`,
      tokenAccess,
      {},
      {}
    );
    yield put(changeStatusCategory.success(response));
  } catch (e) {
    yield put(
      changeStatusCategory.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(changeStatusCategory.fulfill());
  }
}

export default function* categoriesSaga() {
  yield takeEvery(getCategories.TRIGGER, getCategoriesSaga);
  yield takeEvery(createCategory.TRIGGER, createCategorySaga);
  yield takeEvery(updateCategory.TRIGGER, updateCategorySaga);
  yield takeEvery(deleteCategory.TRIGGER, deleteCategorySaga);
  yield takeEvery(changeStatusCategory.TRIGGER, changeStatusCategorySaga);
  yield takeEvery(updateCategory.SUCCESS, getCategoriesSaga);
  yield takeEvery(deleteCategory.SUCCESS, getCategoriesSaga);
  yield takeEvery(changeStatusCategory.SUCCESS, getCategoriesSaga);
}
