import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddImagesButton } from "../../ui/AddImagesButton";
import {
  changeFormSetup,
  getInitialImages,
  setInitialImages,
} from "../../../redux/modules/setup/actions";

import CommonPanel from "../../ui/CommonPanel";
import { UploadedImageCard } from "../../ui/UploadedImageCard";
import { getInitialImagesLoaded } from "../../../redux/modules/setup/selectors";

const { REACT_APP_ASSETS_URL } = process.env;
function Setup() {
  const dispatch = useDispatch();
  const imagesLoaded = useSelector(getInitialImagesLoaded);
  const [removeImages, setRemoveImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [base64Files, setBase64Files] = useState([]);

  const images =
    imagesLoaded && imagesLoaded.filter((x) => !removeImages.includes(x.id));

  const handleRemoveImage = ({ index, id }, type) => {
    if (type === "base64") {
      if (base64Files.length > 0) {
        if (base64Files.length == 1) {
          setBase64Files([]);
        } else {
          let newFiles = base64Files.filter((value, i) => i !== index);
          setBase64Files(newFiles);
        }
      }
    } else {
      let currentImages = imagesLoaded;
      let items = [];
      if (currentImages.length > 0) {
        if (currentImages.length == 1) {
          items = [];
        } else {
          items = currentImages.filter((x) => x.id !== id);
        }
      }
      setRemoveImages([...removeImages, id]);
    }
  };

  const handleSelectDefaultImage = ({ index, id }, type) => {};
  const handleSaveChanges = () => {
    dispatch(setInitialImages.trigger({ newImages, removeImages }));
  };
  const handleCancelChanges = () => {
    setRemoveImages([]);
    setBase64Files([]);
  };

  useEffect(() => {
    dispatch(getInitialImages.trigger());

    return () => {};
  }, []);

  const handleChangeInput = async (propertyValue, propertyName) => {
    const files = propertyValue;
    let newFiles = [];
    let base64Temp = [];
    for (let index = 0; index < files.length; index++) {
      const { fileName, base64, file } = files[index];
      let foundImg = base64Files.find((x) => x["name"] === fileName);
      if (!foundImg) {
        base64Temp.push({
          name: fileName,
          base64: base64,
        });
        newFiles.push(file);
      }
    }
    setNewImages(newFiles);
    setBase64Files(base64Files.concat(base64Temp));
  };

  return (
    <CommonPanel
      title="Configuración"
      endButtons={
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <button className={`btn btn-secondary`} onClick={handleCancelChanges}>
            Cancelar
          </button>
          <button
            disabled={removeImages.length === 0 && newImages.length === 0}
            className={`btn btn-primary`}
            onClick={handleSaveChanges}
          >
            Guardar
          </button>
        </div>
      }
    >
      <div className="row">
        <legend>
          Imagenes de Inicio{" "}
          <AddImagesButton
            limit={25}
            onHandleChangeInput={(files) => handleChangeInput(files, "files")}
          ></AddImagesButton>{" "}
        </legend>
        {base64Files?.map((imgBase64, index) => (
          <UploadedImageCard
            onHandleRemoveImage={(file) => handleRemoveImage(file, "base64")}
            index={index}
            notDefault={true}
            base64={imgBase64.base64}
          ></UploadedImageCard>
        ))}
        {images?.map((file, index) => (
          <UploadedImageCard
            isActive={false}
            notDefault={true}
            onHandleRemoveImage={(file) => handleRemoveImage(file, "url")}
            index={index}
            id={file.id}
            url={`${REACT_APP_ASSETS_URL}/${file.urlFile}`}
          ></UploadedImageCard>
        ))}
      </div>
    </CommonPanel>
  );
}

export default Setup;
