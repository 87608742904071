import produce from "immer";
import * as types from "./types";
import {
  doChangePassword,
  doForgotPassword,
  doLoginUser,
  tryVerificationCode,
} from "./actions";
import { initialState } from "./states";

export const auth = produce((draft, { type, payload }) => {
  switch (type) {
    case doLoginUser.TRIGGER:
      draft.loading = true;
      break;
    case doLoginUser.SUCCESS:
      draft.isAuth = true;
      draft.token = payload.accessToken;
      break;
    case doLoginUser.FAILURE:
      draft.response.error = payload.error;
      draft.response.result = payload.result;
      draft.isAuth = false;
      break;
    case doLoginUser.FULFILL:
      draft.loading = false;
      break;
    case doForgotPassword.TRIGGER:
      draft.loading = true;
    case doForgotPassword.REQUEST:
      break;
    case doForgotPassword.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      draft.response.result = payload.data;
      break;
    case doForgotPassword.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case doForgotPassword.FULFILL:
      draft.loading = false;
      break;
    case types.REMEMBER_USER:
      draft.remember = true;
      draft.userName = payload.user;
      draft.password = payload.password;
      break;
    case doChangePassword.TRIGGER:
      draft.loading = true;
      break;
    case doChangePassword.REQUEST:
      break;
    case doChangePassword.SUCCESS:
      draft.response.result = payload.data;
      draft.response.message = payload.message;
      draft.form.showModal = false;
      break;
    case doChangePassword.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case doChangePassword.FULFILL:
      draft.loading = false;
      break;
    case tryVerificationCode.TRIGGER:
      draft.loading = true;
    case tryVerificationCode.REQUEST:
      break;
    case tryVerificationCode.SUCCESS:
      draft.response.result = payload.data;
      draft.response.message = payload.message;
      draft.form.showModal = false;
      draft.form = initialState.form;
      break;
    case tryVerificationCode.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case tryVerificationCode.FULFILL:
      draft.loading = false;
      break;
    case types.CHANGE_FORM_LOGIN:
      draft.form[payload.name] = payload.value;
      break;
    case types.DO_LOGOUT_USER:
      return initialState;
      break;
    case types.RESET_FORM_LOGIN:
      draft.form = initialState.form;
      draft.loading = false;
      break;
    case types.CLEAR_RESPONSE_LOGIN:
      draft.response.message = null;
      draft.response.error = null;
      draft.response.result = null;
      break;
  }
}, initialState);
