import React from "react";
import { Button } from "react-bootstrap";
import { requestAssociateAuction } from "../../../redux/modules/auctions/actions";
import Countdown from "./Countdown";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CountBadge, StatusBadge } from "../../ui/CommonBadges";
import OfferPanel from "./OfferingProcess/OfferPanel";

const { REACT_APP_ASSETS_URL } = process.env;
const BoxDetailAuction = ({ item, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dateCloseAuction = new Date(item.closeAt);
  const dateOpenAuction = new Date(item.startAt);

  const handleRequestClick = (id) => {
    dispatch(requestAssociateAuction.trigger(id));
  };

  const getMainButton = (type, auctionStatus) => {
    auctionStatus =
      dateCloseAuction.getTime() > new Date().getTime() ? "Activa" : "Cerrada";
    if (!user) {
      return auctionStatus === "Activa" ? (
        <div className="w-full px-5">
          <Button
            bsPrefix="w-full rounded-lg text-white p-3"
            style={{ background: "#005493" }}
            variant="primary"
            onClick={(e) => navigate("/signIn")}
          >
            REGISTRARSE
          </Button>
          <div className="flex p-2">
            <span>¿Ya estas registrado?</span>
            <span
              style={{
                marginLeft: "8px",
                cursor: "pointer",
                color: "orange",
              }}
              onClick={(e) => navigate("/login")}
            >
              Inicia sesión aquí
            </span>
          </div>
        </div>
      ) : (
        <></>
      );
    } else {
      switch (auctionStatus) {
        case "Activa":
          switch (type) {
            case "Activo":
              const lastPrice =
                parseInt(item.maxOfferPrice) === 0
                  ? parseInt(item.minimumPrice)
                  : parseInt(item.maxOfferPrice);

              return (
                <OfferPanel
                  user={user}
                  id={item.id}
                  lastPrice={lastPrice}
                  lastOfferer={item.maxOffererName}
                  minPrice={item.nextOfferPrice}
                  minimumIncrease={parseInt(item.minimumIncrease)}
                ></OfferPanel>
              );
              break;
            case "Pendiente":
              return (
                <Button
                  bsPrefix="w-full rounded-lg text-white p-3"
                  style={{ background: "gray" }}
                  variant="primary"
                  disabled
                >
                  Pendiente Aprobación
                </Button>
              );
              break;
            case "Archivos Pendientes":
              return (
                <Button
                  bsPrefix="w-full rounded-lg text-white p-3"
                  style={{ background: "gray" }}
                  variant="primary"
                  disabled
                >
                  Debes subir los archivos requeridos para la Aprobación
                </Button>
              );
              break;
            case "No Aprobado":
              return (
                <div>
                  No has cumplido los requisitos para participar. Si tienes
                  dudas envía un correo a
                  comercial@centraldesubastasyremates.com
                </div>
              );
              break;
            case "No Oferente":
              return (
                <Button
                  bsPrefix="w-full rounded-lg text-white p-3"
                  style={{ background: "#005493" }}
                  variant="primary"
                  onClick={(e) => handleRequestClick(item.id)}
                >
                  Participar
                </Button>
              );
              break;
          }
          break;
        case "Cerrada":
          return (
            <div>
              Esta subasta ya se cerró, ya no puedes participar en esta subasta.
            </div>
          );
          break;
      }
    }
  };

  return (
    <div className="row p-3">
      <div className="col-md-12  border-1 rounded shadow-sm">
        <div className="w-full text-black-50 font-semibold text-center border-b">
          INFORMACIÓN
        </div>
        <div className="flex flex-column">
          <div className="w-full flex flex-row py-2">
            {item.companyUrlImage ? (
              <img
                style={{ height: "120px", width: "120px", marginRight: "10px" }}
                className="img-thumbnail rounded-full"
                src={`${REACT_APP_ASSETS_URL}/${item.companyUrlImage}`}
                alt=".."
              />
            ) : (
              <div
                style={{
                  height: "100px",
                  width: "130px",
                  marginRight: "10px",
                  backgroundColor: "#e3dede",
                }}
                className="img-thumbnail rounded-full"
              ></div>
            )}
            <div className="w-full flex flex-column">
              <span className="text-black-50 font-semibold">
                EMPRESA:{" "}
                <span className="text-black font-semibold text-lg">
                  {item.companyName}
                </span>
              </span>
              <span className="text-black-50 font-semibold">
                NOMBRE:{" "}
                <span className="text-black font-semibold text-base">
                  {item.name}
                </span>
              </span>
              <span className="italic ">{item.shortDescription}</span>
              <span className="text-black-50 font-semibold">
                {"UBICACIÓN: "}
                <span className="text-black font-semibold text-base">{`${item.countryName}/${item.departmentName}/${item.cityName}`}</span>
              </span>
            </div>
          </div>
          <div className="text-black-50 font-semibold border-y py-1">
            {"FECHA INICIO: "}
            <span className="text-black font-semibold">
              {dateOpenAuction && dateOpenAuction.toLocaleString()}
            </span>
          </div>
          <div className="text-black-50 font-semibold border-y py-1">
            {"FECHA CIERRE: "}
            <span className="text-black font-semibold">
              {dateCloseAuction && dateCloseAuction.toLocaleString()}
            </span>
          </div>

          <div className="text-black-50 font-semibold border-y py-1">
            ESTADO{" "}
            <StatusBadge
              className="text-sm"
              status={
                dateCloseAuction.getTime() > new Date().getTime()
                  ? "Activa"
                  : "Cerrada"
              }
            ></StatusBadge>
          </div>
          <div
            style={{ minHeight: "30px", width: "100%" }}
            className="text-black-50 font-semibold text-center border-y"
          >
            {dateCloseAuction > new Date() ? (
              <span
                style={{ minHeight: "30px", width: "100%" }}
                className="text-black font-semibold"
              >
                <Countdown
                  key={item.id}
                  auctionId={item.id}
                  countdownDate={new Date(item.closeAt).getTime()}
                />
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="text-black-50 font-semibold text-center border-y">
            PROCESO
          </div>
          <div className="flex py-1 border-b mb-3">
            <span className="basis-1/2 text-black-50 font-semibold ">
              PARTICIPANTES :{" "}
              <CountBadge
                style={{ backgroundColor: "#0ea5e9" }}
                className="text-sm"
                count={item.offerersCount}
              />
            </span>
            <span className="basis-1/2 text-black-50 font-semibold ">
              OFERTAS{" "}
              <CountBadge className="text-sm" count={item.offersCount} />
            </span>
          </div>
          <div className="flex py-1">
            <span className="basis-1/2 text-black-50 text-sm font-semibold ">
              MAYOR OFERENTE:{" "}
              <span className="text-black font-semibold text-base">
                {item.maxOffererName}
              </span>
            </span>

            <span className="basis-1/2 text-black-50 font-semibold text-sm ">
              MAYOR OFERTA:{" "}
              <span className="text-black font-semibold text-base">
                $ {new Intl.NumberFormat("es-CO").format(item.maxOfferPrice)}
              </span>
            </span>
          </div>
        </div>

        <div className="w-full py-3 px-2 ">
          {((user && user.userType !== "admin") || !user) &&
            getMainButton(item.associatedAuctionStatus, item.status)}
        </div>
      </div>
    </div>
  );
};

export default BoxDetailAuction;
