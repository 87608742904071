import { combineReducers } from "redux";
import { app } from "./modules/app/reducer";
import { setup } from "./modules/setup/reducer";
import { auth } from "./modules/auth/reducer";
import { layout } from "./modules/layout/reducer";
import { users } from "./modules/users/reducer";
import { categories } from "./modules/categories/reducer";
import { auctions } from "./modules/auctions/reducer";
import { signIn } from "./modules/sign-in/reducer";
import { locations } from "./modules/locations/reducer";
import { companies } from "./modules/companies/reducer";
import { offerers } from "./modules/offerers/reducer";
import { profile } from "./modules/profile/reducer";
import { associatedAuctions } from "./modules/associated-auctions/reducer";

const rootReducer = combineReducers({
  app: app,
  setup: setup,
  locations: locations,
  auth: auth,
  signIn: signIn,
  layout: layout,
  users: users,
  categories: categories,
  auctions: auctions,
  associatedAuctions: associatedAuctions,
  companies: companies,
  offerers: offerers,
  profile: profile,
});

export default rootReducer;
