import { initialState } from "./states";
import { updateProfile, getCurrentProfile } from "./actions";
import * as types from "./types";
import produce from "immer";

export const profile = produce((draft, { type, payload }) => {
  switch (type) {
    case getCurrentProfile.TRIGGER:
      draft.loading = true;
      break;
    case getCurrentProfile.REQUEST:
      break;
    case getCurrentProfile.SUCCESS:
      draft.form.id = payload.id;
      draft.form.fullName = payload.fullName;
      draft.form.documentType = payload.documentType;
      draft.form.document = payload.document;
      draft.form.phone = payload.phone;
      draft.form.cellPhone = payload.cellPhone;
      draft.form.status = payload.status;
      draft.form.email = payload.email;
      draft.form.address = payload.address;
      draft.form.userName = payload.userName;
      draft.form.departmentId = payload.departmentId;
      draft.form.countryId = payload.countryId;
      draft.form.cityId = payload.cityId;
      draft.form.password = "#PASSWORD!";
      draft.form.password2 = "#PASSWORD!";
      draft.form.isNew = false;
      break;
    case getCurrentProfile.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getCurrentProfile.FULFILL:
      draft.loading = false;
      break;

    case updateProfile.TRIGGER:
      draft.loading = true;
      break;
    case updateProfile.REQUEST:
      break;
    case updateProfile.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      draft.response.data = payload.data;
      break;
    case updateProfile.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case updateProfile.FULFILL:
      draft.loading = false;
      break;

    case types.CHANGE_FORM_PROFILE:
      draft.form[payload.name] = payload.value;
      break;
  }
}, initialState);
