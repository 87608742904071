export const initialState = {
  loading: false,
  form: {
    isNew: true,
    fullName: "",
    documetType: "",
    document: "",
    phone: "",
    cellPhone: "",
    status: "",
    address: "",
    cityId: "",
  },
  response: { message: null, error: null },
  offerers: [],
  filters: {
    search: "",
    status: "Todos",
  },
  modal: {
    show: false,
    title: "",
    message: "",
    type: "",
    data: "",
    size: null,
  },
};
