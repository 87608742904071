import produce from "immer";
import moment from "moment-timezone";

import {
  changeStatusAuction,
  createAuction,
  deleteAuction,
  getActiveAuctions,
  getAuctionById,
  getAuctions,
  getCurrentAuctionById,
  requestAssociateAuction,
  requestOffererProcess,
  updateActiveAuction,
  updateAuction,
  updateAuctionById,
} from "./actions";
import { initialState } from "./states";
import * as types from "./types";

export const auctions = produce((draft, { type, payload }) => {
  let auctionExist = null;
  switch (type) {
    case getActiveAuctions.TRIGGER:
      draft.loading = true;
      break;
    case getActiveAuctions.REQUEST:
      break;
    case getActiveAuctions.SUCCESS:
      draft.auctions = payload;
      break;
    case getActiveAuctions.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getActiveAuctions.FULFILL:
      draft.loading = false;
      break;

    case getCurrentAuctionById.TRIGGER:
      draft.loading = true;
      break;
    case getCurrentAuctionById.REQUEST:
      break;
    case getCurrentAuctionById.SUCCESS:
      draft.auction = payload;

      let auctionExist = null;
      auctionExist = draft.auctions.find(
        (auction) => auction.id.toUpperCase() === payload.id.toUpperCase()
      );
      if (auctionExist) {
        auctionExist.associatedAuctionStatus = payload.associatedAuctionStatus;
      }
      const minPrice =
        payload.maxOffer === 0
          ? Number(payload.reservePrice) + Number(payload.minimumIncrease)
          : Number(payload.maxOffer) + Number(payload.minimumIncrease);
      draft.newOffer.price = minPrice;
      draft.newOffer.minPrice = minPrice;
      draft.newOffer.lastPrice = Number(payload.maxOffer);
      draft.newOffer.lastOfferer = payload.maxOfferer;
      draft.newOffer.minimumIncrease = Number(payload.minimumIncrease);
      draft.auctionId = payload.id;
      break;
    case getCurrentAuctionById.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getCurrentAuctionById.FULFILL:
      draft.loading = false;
      break;

    case updateAuctionById.toString():
      let indexId = draft.auctions.findIndex(
        (auction) => auction.id === payload.id
      );
      if (indexId !== -1) {
        draft.auctions[indexId] = payload;
      }
      break;

    case types.SET_ACTIVE_AUCTIONS_FILTER:
      draft.filters[payload.propertyName] = payload.propertyValue;
      break;

    case types.SET_TEMP_OFFER_PRICE:
      draft.newOffer.price = payload;
      break;

    case requestAssociateAuction.TRIGGER:
      draft.loading = true;
      break;
    case requestAssociateAuction.REQUEST:
      break;
    case requestAssociateAuction.SUCCESS:
      draft.response.message = payload.message;
      break;
    case requestAssociateAuction.FAILURE:
      draft.response.error = payload.error;
      break;
    case requestAssociateAuction.FULFILL:
      draft.loading = false;
      break;

    case requestOffererProcess.TRIGGER:
      draft.loading = true;
      break;
    case requestOffererProcess.REQUEST:
      break;
    case requestOffererProcess.SUCCESS:
      draft.response.message = payload.message;
      break;
    case requestOffererProcess.FAILURE:
      draft.response.error = payload.error;
      break;
    case requestOffererProcess.FULFILL:
      draft.loading = false;
      break;

    case getAuctions.TRIGGER:
      draft.loading = true;
      break;
    case getAuctions.REQUEST:
      break;
    case getAuctions.SUCCESS:
      draft.auctions = payload;
      draft.filters = initialState.filters;
      break;
    case getAuctions.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getAuctions.FULFILL:
      draft.loading = false;
      break;

    case getAuctionById.TRIGGER:
      draft.loading = true;
      break;
    case getAuctionById.REQUEST:
      break;
    case getAuctionById.SUCCESS:
      draft.auction = payload;
      break;
    case getAuctionById.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getAuctionById.FULFILL:
      draft.loading = false;
      break;

    case createAuction.TRIGGER:
      draft.loading = true;
      break;
    case createAuction.REQUEST:
      break;
    case createAuction.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      draft.form = initialState.form;
      break;
    case createAuction.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case createAuction.FULFILL:
      draft.loading = false;
      break;

    case updateAuction.TRIGGER:
      draft.loading = true;
      break;
    case updateAuction.REQUEST:
      break;
    case updateAuction.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      draft.form = initialState.form;
      break;
    case updateAuction.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      draft.form = initialState.form;
      break;
    case updateAuction.FULFILL:
      draft.loading = false;
      break;

    case deleteAuction.TRIGGER:
      draft.loading = true;
      break;
    case deleteAuction.REQUEST:
      break;
    case deleteAuction.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      break;
    case deleteAuction.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case deleteAuction.FULFILL:
      draft.loading = false;
      break;

    case changeStatusAuction.TRIGGER:
      draft.loading = true;
      break;
    case changeStatusAuction.REQUEST:
      break;
    case changeStatusAuction.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      break;
    case changeStatusAuction.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case changeStatusAuction.FULFILL:
      draft.loading = false;
      break;

    case types.CLEAR_RESPONSE_AUCTIONS:
      draft.response = initialState.response;
      break;

    case types.LOAD_FORM_AUCTION:
      const dateStartAuction = `${moment
        .tz(new Date(payload.startAt), "America/Bogota")
        .format()}`;
      const dateCloseAuction = `${moment
        .tz(new Date(payload.closeAt), "America/Bogota")
        .format()}`;

      draft.auctionId = payload.id;
      draft.form.id = payload.id;
      draft.form.code = payload.code;
      draft.form.companyId = payload.companyId;
      draft.form.categoryId = payload.categoryId;
      draft.form.offererId = payload.offererId;
      draft.form.countryId = payload.countryId;
      draft.form.departmentId = payload.departmentId;
      draft.form.cityId = payload.cityId;
      draft.form.auctionType = payload.auctionType;
      draft.form.name = payload.name;
      draft.form.description = payload.description;
      draft.form.shortDescription = payload.shortDescription;
      draft.form.minimumPrice = payload.minimumPrice;
      draft.form.minimumIncrease = payload.minimumIncrease;
      draft.form.reservePrice = payload.reservePrice;
      draft.form.startAt = dateStartAuction.substring(0, 19);
      draft.form.closeAt = dateCloseAuction.substring(0, 19);
      draft.form.urlImage = payload.urlImage;
      draft.form.address = payload.address;
      draft.form.commission = payload.commission;
      draft.form.images = payload.images;
      draft.form.requirementsLink = payload.requirementsLink;
      draft.form.isNew = false;
      break;

    case types.CHANGE_FORM_AUCTION:
      if (payload.name === "files") {
        draft.form.files = draft.form.files.concat(payload.value);
      } else if (payload.name === "removeImages") {
        draft.form.removeImages = draft.form.removeImages.concat(payload.value);
      } else {
        draft.form[payload.name] = payload.value;
      }

      break;

    case types.RESET_FORM_AUCTION:
      draft.form = initialState.form;
      break;

    case types.SET_MODAL_AUCTIONS_CONFIG:
      draft.modal = payload;
      break;
    case types.SET_AUCTIONS_FILTER:
      draft.filters[payload.propertyName] = payload.propertyValue;
      break;
    case types.SET_OFFERS_FILTER:
      draft.filters[payload.propertyName] = payload.propertyValue;
      break;
    case types.SET_OFFERERS_FILTER:
      draft.filters[payload.propertyName] = payload.propertyValue;
      break;
    case types.SET_PENDING_OFFERERS_FILTER:
      draft.filters[payload.propertyName] = payload.propertyValue;
      break;

    case types.SET_SELECTED_AUCTION_ID:
      draft.auctionId = payload;
      break;

    case types.ADD_ACTIVE_AUCTION:
      draft.auctions.push(payload);
      break;
    case types.FINISH_ACTIVE_AUCTION:
      let auctionExistTemp = draft.auctions.find((x) => x.id === payload);
      if (auctionExistTemp) {
        auctionExistTemp.status = "Cerrada";
      }
      if (draft.auction && draft.auction.id === payload) {
        draft.auction.status = payload.data.status;
      }
      break;
    case updateActiveAuction.toString():
      const currentAuctions = draft.auctions;
      const auctionIndex = currentAuctions.findIndex(
        (x) => x.id === payload.data.id || x.id === payload.data.auctionId
      );
      if (auctionIndex !== -1) {
        const auctionExistA = currentAuctions[auctionIndex];
        switch (payload.type) {
          case "requestStatus":
            let request = auctionExistA.offerers.find(
              (x) => x.id == payload.data.id
            );
            request.status = payload.data.statusChanged;
            break;
          case "auction":
            const newAuction = payload.data;
            draft.auctions[auctionIndex] = { ...newAuction };
            break;
          case "auctionStatus":
            draft.auctions[auctionIndex].status = payload.data.status;
            if (draft.auction && draft.auction.id === auctionExistA.id) {
              draft.auction.status = payload.data.status;
            }
            break;
        }
      }
      break;
  }
}, initialState);
