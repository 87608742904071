import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStatusCompany,
  deleteCompany, getCompanies, loadFormCompany, setCompaniesFilter, setModalCompaniesConfig, updateCompany
} from "../../../redux/modules/companies/actions";
import {
  getCompaniesList, getModalCompaniesConfig
} from "../../../redux/modules/companies/selectors";
import { AdditionalButton } from "../../ui/AdditionalButton";
import CommonModal from "../../ui/CommonModal";
import CommonPanel from "../../ui/CommonPanel";
import FilterBar from "../../ui/FilterBar";
import SearchBar from "../../ui/SearchBar";
import FormCompany from "./FormCompany";
import TableCompanies from "./TableCompanies";

function Companies() {
  const dispatch = useDispatch();
  const companies = useSelector(getCompaniesList);
  const modalConfig = useSelector(getModalCompaniesConfig);

  useEffect(() => {
    dispatch(getCompanies.trigger());
  }, []);

  const handleModalClose = () => {
    dispatch(
      setModalCompaniesConfig({ ...modalConfig, show: false, data: null })
    );
  };

  const handleModalOk = () => {
    switch (modalConfig.type) {
      case "delete":
        dispatch(deleteCompany.trigger(modalConfig.data));
        dispatch(
          setModalCompaniesConfig({ ...modalConfig, show: false, data: null })
        );
        break;
      case "changeStatus":
        dispatch(changeStatusCompany.trigger(modalConfig.data));
        dispatch(
          setModalCompaniesConfig({ ...modalConfig, show: false, data: null })
        );
        break;
      case "edit":
        dispatch(updateCompany.trigger());
        dispatch(
          setModalCompaniesConfig({ ...modalConfig, show: false, data: null })
        );
        break;
    }
  };

  const handleSearch = (e) => {
    dispatch(setCompaniesFilter({ propertyName: "search", propertyValue: e }));
  };

  const handleStatusFilter = (e) => {
    dispatch(setCompaniesFilter({ propertyName: "status", propertyValue: e }));
  };

  const handleClickRow = ({ data, item }) => {
    switch (data.action) {
      case "edit":
        dispatch(
          setModalCompaniesConfig({
            show: true,
            type: "form",
            size: "xl",
            message: "",
            data: { id: item.id },
          })
        );
        dispatch(loadFormCompany(item));
        break;
      case "delete":
        dispatch(
          setModalCompaniesConfig({
            show: true,
            type: data.action,
            title: "Eliminar Empresa",
            message: (
              <div className="w-full">
                <p className="text-center">
                  ¿Desea eliminar la empresa? <br></br>
                  <span style={{ fontWeight: 700 }}>{item.name}</span>
                </p>
              </div>
            ),
            data: { id: item.id },
          })
        );
        break;
      case "changeStatus":
        dispatch(
          setModalCompaniesConfig({
            show: true,
            data: { path: data.value, id: item.id },
            type: data.action,
            title: `${data.title} Empresa`,
            message: (
              <div className="w-full">
                <p className="text-center">
                  {`¿Desea ${data.title.toLowerCase()} la empresa?`}
                  <br></br>
                  <span style={{ fontWeight: 700 }}>{item.name}</span>
                </p>
              </div>
            ),
          })
        );

        break;
    }
  };
  const additionalButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <AdditionalButton
        title="Agregar Empresa"
        linkTo="/companies/form"
        iconClass="bi-plus"
      />
    </div>
  );

  const filterBarOptions = [
    { name: "Todos", value: "Todos" },
    { name: "Activo", value: "Activo" },
    { name: "Inactivo", value: "Inactivo" },
  ];

  return (
    <CommonPanel
      title="Lista de Empresas"
      count={companies.length}
      additionalButtons={additionalButtons}
      searchBar={<SearchBar onHandleSearch={handleSearch}></SearchBar>}
      filterBar={
        <FilterBar
          name="Estado"
          options={filterBarOptions}
          onHandleFilter={handleStatusFilter}
        ></FilterBar>
      }
    >
      <TableCompanies
        columns={["Documento", "Nombre", "Descripción", "Estado", "Opciones"]}
        onHandleClickRow={handleClickRow}
        items={companies}
      ></TableCompanies>
      <CommonModal
        config={modalConfig}
        handleModalClose={handleModalClose}
        handleModalOk={handleModalOk}
      >
        {modalConfig.type === "form" && <FormCompany />}
      </CommonModal>
    </CommonPanel>
  );
}

export default Companies;
