import * as type from './types';
import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";

export const doLoginUser = createRoutine(type.DO_LOGIN_USER);
export const doForgotPassword = createRoutine(type.DO_FORGOT_PASSWORD);
export const doChangePassword = createRoutine(type.DO_CHANGE_PASSWORD);
export const tryVerificationCode = createRoutine(type.TRY_VERIFICATION_CODE);
export const rememberUser = createAction(type.REMEMBER_USER);
export const doLogoutUser = createAction(type.DO_LOGOUT_USER);
export const changeFormLogin = createAction(type.CHANGE_FORM_LOGIN);
export const resetFormLogin = createAction(type.RESET_FORM_LOGIN);
export const clearResponseLogin = createAction(type.CLEAR_RESPONSE_LOGIN);
