export const initialState = {
  loading: false,
  form: {
    fullName: "",
    documentType: "CC",
    document: "",
    phone: "",
    cellPhone: "",
    address: "",
    email: "",
    password: "",
    password2: "",
    userName: "",
    countryId: "",
    departmentId: "",
    cityId: "",
    acceptPDT: false,
    acceptSMS: false,
    showModal: false,
    verifyCode: "",
  },
  response: { message: null, error: null, result: null },
};
