import React from "react";
import { Badge } from "react-bootstrap";
import { theme } from "../../utils/theme";

const styleBadge = {
  default: {
    fontWeight: 500,
    backgroundColor: theme.colors.third,
  },
  Activo: {
    fontWeight: 500,
    backgroundColor: theme.colors.primary,
  },
  Inactivo: {
    fontWeight: 500,
    backgroundColor: theme.colors.secondary,
  },
  Pendiente: {
    fontWeight: 500,
    backgroundColor: theme.colors.third,
  },
  Rechazado: {
    fontWeight: 500,
    backgroundColor: theme.colors.danger,
  },
};

export const CountBadge = ({ count, ...rest }) => {
  return (
    <Badge style={{ ...rest.style, ...styleBadge["default"] }} {...rest}>
      {count}
    </Badge>
  );
};

export const StatusBadge = ({ style, status, ...rest }) => {
  if (status && styleBadge[status]) {
    return (
      <Badge style={{ ...style, ...styleBadge[status] }} {...rest}>
        {status}
      </Badge>
    );
  } else {
    return (
      <Badge style={styleBadge["default"]} {...rest}>
        {status}
      </Badge>
    );
  }
};
