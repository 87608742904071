import produce from "immer";
import * as types from "./types";
import { doSignInUser, doVerificationCode } from "./actions";
import { initialState } from "./states";

export const signIn = produce((draft, { type, payload }) => {
  switch (type) {
    case doSignInUser.TRIGGER:
      draft.loading = true;
    case doSignInUser.REQUEST:
      break;
    case doSignInUser.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      draft.response.result = payload.data;
      break;
    case doSignInUser.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case doSignInUser.FULFILL:
      draft.loading = false;
      break;

    case doVerificationCode.TRIGGER:
      draft.loading = true;
    case doVerificationCode.REQUEST:
      break;
    case doVerificationCode.SUCCESS:
      draft.response.result = payload.data;
      draft.response.message = payload.message;
      draft.form.showModal = false;
      draft.form = initialState.form;
      break;
    case doVerificationCode.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case doVerificationCode.FULFILL:
      draft.loading = false;
      break;
    case types.CLEAR_RESPONSE_SIGN_IN:
      draft.response.message = null;
      draft.response.error = null;
      draft.response.result = null;
      break;
    case types.RESET_FORM_SIGN_IN:
      draft.form = initialState.form;
      break;
    case types.CHANGE_FORM_SIGN_IN:
      draft.form[payload.name] = payload.value;
      break;
  }
}, initialState);
