export const UploadedImageCard = ({
  isActive = false,
  index,
  id,
  url,
  base64,
  cols = 3,
  onHandleRemoveImage,
  onHandleSelectDefaultImage,
  notDefault = false,
}) => {
  const handleRemoveImage = (index, id) => {
    onHandleRemoveImage({ id, index });
  };

  const handleSelectDefaultImage = (index, id) => {
    onHandleSelectDefaultImage({ index, id });
  };

  return (
    <div
      key={url ? `url-${index}` : `b64-${index}`}
      className={`col-md-${cols} ${
        isActive ? "active" : ""
      } p-0 m-0 text-center`}
    >
      <div className="imgContenedor">
        <img className="img-thumbnail  p-1" src={url ? url : base64} />
        <a
          onClick={() => handleRemoveImage(index, id)}
          className="imgRemove cursor-pointer"
        >
          <i className="bi bi-x"></i>
        </a>
        {!notDefault && (
          <a
            onClick={() => handleSelectDefaultImage(index, id)}
            className="imgDefault cursor-pointer"
          >
            <i className={`bi bi-${isActive ? "star-fill" : "star"}`}></i>
          </a>
        )}
      </div>
    </div>
  );
};
