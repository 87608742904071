export const initialState = {
  loading: false,
  isAuth: false,
  remember: false,
  form: {
    email: "",
    password: "",
    password2: "",
    verifyCode: "",
  },
  response: { message: null, error: null, result: null },
  token: "",
};
