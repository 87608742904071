import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { doLogoutUserApp } from "../../../../redux/modules/app/actions";
import { doLogoutUser } from "../../../../redux/modules/auth/actions";
import { theme } from "../../../../utils/theme";

const HeaderCss = {
  backgroundColor: theme.colors.primary,
  position: "fixed",
  zIndex:1000,
  width:"100%"
};

const { REACT_APP_ASSETS_URL } = process.env;

const Header = ({ menuLinks, user }) => {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickLogout = (e) => {
    navigate("/");
    dispatch(doLogoutUser());
    dispatch(doLogoutUserApp());
  };
  return (
    <Navbar style={HeaderCss} collapseOnSelect expand="lg" variant="dark">
      <Container>
        <Navbar.Brand href="#home">
          <img
            style={{ height: "60px" }}
            src={`${REACT_APP_ASSETS_URL}/assets/images/logo-blanco.png`}
            alt="CSR"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto text-center">
            {menuLinks && user ? (
              menuLinks.map((link) => (
                <NavLink
                  className="header-link active"
                  key={link.path}
                  to={link.path}
                >
                  <i className={link.icon}></i> {link.name}
                </NavLink>
              ))
            ) : (
              <>
                <li className="nav-item">
                  <a className="nav-link" href="/#services">
                    Servicios
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#sales">
                    Ventas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#auctions">
                    Subastas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#about">
                    Nosotros
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#contact">
                    Contáctenos
                  </a>
                </li>
                <NavLink to="/login" className="nav-link">
                  Ingresar
                </NavLink>
              </>
            )}
          </Nav>
          {user && (
            <Nav>
              <NavDropdown style={{minWidth:"150px", textAlign:"right"}} title={user.fullName} id="collasible-nav-dropdown">
                {user.userType === "offerer" && (
                  <NavDropdown.Item href="/profile">
                    {t("header_component.user_menu.my_profile")}
                  </NavDropdown.Item>
                )}
                {user.userType === "offerer" && <NavDropdown.Divider />}
                <NavDropdown.Item onClick={handleClickLogout}>
                  {t("header_component.user_menu.logout")}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
