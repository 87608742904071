import produce from "immer";
import { initialState } from "./states";
import { getConfig } from "./actions";
import * as types from "./types";
export const app = produce((draft, { type, payload }) => {
  switch (type) {
    case getConfig.TRIGGER:
      draft.loading = true;
      break;
    case getConfig.REQUEST:
      break;
    case getConfig.SUCCESS:
      break;
    case getConfig.FAILURE:
      break;
    case getConfig.FULFILL:
      draft.loading = false;
      break;
    case types.SET_USER_APP:
      const user = { ...payload };
      draft.user = user;
      break;
    case types.DO_LOGOUT_USER_APP:
      draft.user = null;

      break;
  }
}, initialState);
