import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { getResponseLogin } from "../../redux/modules/auth/selectors";
import { getResponseSignIn } from "../../redux/modules/sign-in/selectors";
import { useDispatch } from "react-redux";
import { clearResponseLogin } from "../../redux/modules/auth/actions";
import { clearResponseSignIn } from "../../redux/modules/sign-in/actions";
import { getResponseAuctions } from "../../redux/modules/auctions/selectors";
import { clearResponseAuctions } from "../../redux/modules/auctions/actions";
import { clearResponseCompanies } from "../../redux/modules/companies/actions";
import { getResponseCompanies } from "../../redux/modules/companies/selectors";
import { clearResponseCategories } from "../../redux/modules/categories/actions";
import { getResponseCategories } from "../../redux/modules/categories/selectors";
import { getResponseProfile } from "../../redux/modules/profile/selectors";
import { clearResponseProfile } from "../../redux/modules/profile/actions";

const GlobalToast = () => {
  const dispatch = useDispatch();
  const responseLogin = useSelector(getResponseLogin);
  const responseSignIn = useSelector(getResponseSignIn);
  const responseAuctions = useSelector(getResponseAuctions);
  const responseCompanies = useSelector(getResponseCompanies);
  const responseCategories = useSelector(getResponseCategories);
  const responseProfile = useSelector(getResponseProfile);

  const executeClear = (type) => {
    switch (type) {
      case "login":
        dispatch(clearResponseLogin());
        break;

      case "signIn":
        dispatch(clearResponseSignIn());
        break;
      case "auctions":
        dispatch(clearResponseAuctions());
        break;
      case "companies":
        dispatch(clearResponseCompanies());
        break;
      case "categories":
        dispatch(clearResponseCategories());
        break;
      case "profile":
        dispatch(clearResponseProfile());
        break;
    }
  };

  const shouldShowMessage = (response, type) => {
    if (response.error || response.message) {
      toast(response.error ? response.error : response.message, {
        type: response.error ? "error" : "success",
      });
      executeClear(type);
    }
  };

  useEffect(() => {
    shouldShowMessage(responseLogin, "login");
  }, [responseLogin]);

  useEffect(() => {
    shouldShowMessage(responseSignIn, "signIn");
  }, [responseSignIn]);

  useEffect(() => {
    shouldShowMessage(responseAuctions, "auctions");
  }, [responseAuctions]);

  useEffect(() => {
    shouldShowMessage(responseCompanies, "companies");
  }, [responseCompanies]);
  useEffect(() => {
    shouldShowMessage(responseCategories, "categories");
  }, [responseCategories]);
  useEffect(() => {
    shouldShowMessage(responseProfile, "profile");
  }, [responseProfile]);

  return <ToastContainer />;
};

export default GlobalToast;
