import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";
import * as type from "./types";

export const changeFormCategory = createAction(type.CHANGE_FORM_CATEGORY);
export const loadFormCategory = createAction(type.LOAD_FORM_CATEGORY);
export const resetFormCategory = createAction(type.RESET_FORM_CATEGORY);
export const getCategories = createRoutine(type.GET_CATEGORIES);
export const createCategory = createRoutine(type.CREATE_CATEGORY);
export const updateCategory = createRoutine(type.UPDATE_CATEGORY);
export const deleteCategory = createRoutine(type.DELETE_CATEGORY);
export const changeStatusCategory = createRoutine(type.CHANGE_STATUS_CATEGORY);
export const setCategoriesFilter = createAction(type.SET_CATEGORIES_FILTER);
export const clearResponseCategories = createAction(type.CLEAR_RESPONSE_CATEGORIES);