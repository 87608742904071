export const GET_USERS = "GET_USERS";
export const CREATE_USER = "CREATE_USER";
export const CHANGE_FORM_USER = "CHANGE_FORM_USER";
export const GET_USERS_BY_STATUS = "GET_USERS_BY_STATUS";
export const GET_USERS_BY_TYPE = "GET_USERS_BY_TYPE";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const CHANGE_STATUS_USER = "CHANGE_STATUS_USER";
export const CHANGE_STATUS_USER_PENDING = "CHANGE_STATUS_USER_PENDING";
export const GET_PENDING_USERS = "GET_PENDING_USERS";
export const CLEAR_RESPONSE_USERS = "CLEAR_RESPONSE_USERS";
export const LOAD_FORM_USER = "LOAD_FORM_USER";
export const RESET_FORM_USER = "RESET_FORM_USER";
export const SET_MODAL_USERS_CONFIG = "SET_MODAL_USERS_CONFIG";
export const SET_USERS_FILTER = "SET_USERS_FILTER";