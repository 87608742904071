import { useState } from "react";

export default function SearchBar({
  onHandleSearch,
  onSubmitSearch,
}) {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    onHandleSearch(newValue);
  };

  return (
    <div>
      <input
        className="rounded border-1 placeholder:italic px-2 py-1"
        type="text"
        value={searchValue}
        onChange={(e) => handleSearch(e)}
        placeholder="Buscar..."
      />
      {/* <button
        onClick={(e) => onSubmitSearch(e)}
        className="bg text-white ml-1 px-3 py-1 rounded"
        style={{ backgroundColor: "#005493" }}
      >
        Buscar
      </button> */}
    </div>
  );
}
