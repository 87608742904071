import React from "react";
import { StatusBadge } from "../../../ui/CommonBadges";
import CommonTable from "../../../ui/CommonTable";

const TableOffers = ({ columns, items, isDetailed, onHandleClickRow }) => {
  const dateCreatedAt = (item) => {
    return new Date(item).toLocaleString();
  };

  const buildItems = (_items) => {
    return (
      _items &&
      _items.length > 0 &&
      _items.map((item) =>
        !isDetailed ? (
          <tr key={item.id}>
            <td>{dateCreatedAt(item.createdAt)}</td>
            <td>{item.offererUserName}</td>
            <td>{item.items.length}</td>
            <td>
              {new Intl.NumberFormat("es-CO").format(item.comissionPrice)}
            </td>
            <td>{new Intl.NumberFormat("es-CO").format(item.offerPrice)}</td>
          </tr>
        ) : (
          <tr key={item.id}>
            <td>{dateCreatedAt(item.createdAt)}</td>
            <td>{item.offererUserName}</td>
            <td>
              {new Intl.NumberFormat("es-CO").format(item.comissionPrice)}
            </td>
            <td>{new Intl.NumberFormat("es-CO").format(item.offerPrice)}</td>
          </tr>
        )
      )
    );
  };
  return (
    <div className="w-full px-6 rounded">
      <div className="flex flex-wrap">
        <CommonTable
          columns={columns}
          items={buildItems(items)}
          onHandleClickRow={onHandleClickRow}
        ></CommonTable>
      </div>
    </div>
  );
};

export default TableOffers;
