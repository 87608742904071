import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getLoadingLogin } from "../../redux/modules/auth/selectors";
import { getLoadingSignIn } from "../../redux/modules/sign-in/selectors";
import { getLoadingLocations } from "../../redux/modules/locations/selectors";
import { getLoadingCategories } from "../../redux/modules/categories/selectors";
import { getLoadingAuctions } from "../../redux/modules/auctions/selectors";
import { getLoadingUsers } from "../../redux/modules/users/selectors";
import { getLoadingProfile } from "../../redux/modules/profile/selectors";
import { getLoadingSetup } from "../../redux/modules/setup/selectors";
import { getLoadingAssociatedAuctions } from "../../redux/modules/associated-auctions/selectors";
const Loading = () => {
  const isLoadingAuth = useSelector(getLoadingLogin);
  const isLoadingSignIn = useSelector(getLoadingSignIn);
  const isLoadingCategories = useSelector(getLoadingCategories);
  const isLoadingLocations = useSelector(getLoadingLocations);
  const isLoadingAssociatedAuctions = useSelector(getLoadingAssociatedAuctions);
  const isLoadingAuctions = useSelector(getLoadingAuctions);
  const isLoadingUsers = useSelector(getLoadingUsers);
  const isLoadingProfile = useSelector(getLoadingProfile);
  const isLoadingSetup = useSelector(getLoadingSetup);
  const isLoading =
    isLoadingAuth ||
    isLoadingCategories ||
    isLoadingLocations ||
    isLoadingSignIn ||
    isLoadingAuctions ||
    isLoadingAssociatedAuctions ||
    isLoadingUsers ||
    isLoadingSetup ||
    isLoadingProfile;

  return isLoading ? (
    <div className="overlay">
      <div className="overlay__inner">
        <div className="overlay__content">
          <span className="spinner"></span>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Loading;
