import produce from "immer";
import {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  changeStatusCategory,
} from "./actions";
import { initialState } from "./states";
import * as types from "./types";

export const categories = produce((draft, { type, payload }) => {
  switch (type) {
    case getCategories.TRIGGER:
      draft.loading = true;
      break;
    case getCategories.REQUEST:
      break;
    case getCategories.SUCCESS:
      draft.categories = payload;
      break;
    case getCategories.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getCategories.FULFILL:
      draft.loading = false;
      break;

    case createCategory.TRIGGER:
      draft.loading = true;
      break;
    case createCategory.REQUEST:
      break;
    case createCategory.SUCCESS:
      draft.categories.push(payload.data);
      draft.categories = draft.categories.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      draft.form = initialState.form;
      break;
    case createCategory.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case createCategory.FULFILL:
      draft.loading = false;
      break;

    case updateCategory.TRIGGER:
      draft.loading = true;
      break;
    case updateCategory.REQUEST:
      break;
    case updateCategory.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      draft.form = initialState.form;
      break;
    case updateCategory.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      draft.form = initialState.form;
      break;
    case updateCategory.FULFILL:
      draft.loading = false;
      break;

    case deleteCategory.TRIGGER:
      draft.loading = true;
      break;
    case deleteCategory.REQUEST:
      break;
    case deleteCategory.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      break;
    case deleteCategory.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case deleteCategory.FULFILL:
      draft.loading = false;
      break;

    case changeStatusCategory.TRIGGER:
      draft.loading = true;
      break;
    case changeStatusCategory.REQUEST:
      break;
    case changeStatusCategory.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      break;
    case changeStatusCategory.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case changeStatusCategory.FULFILL:
      draft.loading = false;
      break;
    case types.CLEAR_RESPONSE_CATEGORIES:
      draft.response = initialState.response;
      break;
    case types.CHANGE_FORM_CATEGORY:
      draft.form[payload.name] = payload.value;

      break;
    case types.RESET_FORM_CATEGORY:
      draft.form = initialState.form;
      break;
    case types.LOAD_FORM_CATEGORY:
      draft.form.id = payload.id;
      draft.form.code = payload.code;
      draft.form.name = payload.name;
      draft.form.description = payload.description;
      draft.form.isNew = false;
      break;
    case types.SET_CATEGORIES_FILTER:
      draft.filters[payload.propertyName] = payload.propertyValue;
      break;
  }
}, initialState);
