import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  changeFormLogin,
  clearResponseLogin,
  doLoginUser,
  resetFormLogin,
} from "../../../redux/modules/auth/actions";
import { tryVerificationCode } from "../../../redux/modules/auth/actions";
import {
  getFormLogin,
  getIsAuth,
  getResponseLogin,
} from "../../../redux/modules/auth/selectors";

import Input from "../../ui/Input";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CommonModal from "../../ui/CommonModal";

const { REACT_APP_ASSETS_URL } = process.env;

function Login() {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const response = useSelector(getResponseLogin);
  const form = useSelector(getFormLogin);
  const isAuthUser = useSelector(getIsAuth);
  const [passVisibility, setPassVisibility] = useState(true);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    title: "Validación de correo electronico",
    message: "",
    type: "code",
    data: "",
  });
  useEffect(() => {
    dispatch(resetFormLogin());
  }, []);

  useEffect(() => {
    if (isAuthUser) {
      navigate("/auctions/grid");
    }
  }, [isAuthUser]);

  useEffect(() => {
    switch (response.result) {
      case "OK":
        setModalConfig({ ...modalConfig, show: false });
        navigate("/login");
        dispatch(clearResponseLogin());
        break;
      case "ValidationCode":
        setModalConfig({ ...modalConfig, show: true });
        break;
    }
  }, [form.shoModal]);

  useEffect(() => {
    switch (response.result) {
      case "OK":
        setModalConfig({ ...modalConfig, show: false });
        navigate("/login");
        dispatch(clearResponseLogin());
        break;
      case "ValidationCode":
        setModalConfig({ ...modalConfig, show: true });
        break;
    }
  }, [response.result]);

  useEffect(() => {
    switch (response.result) {
      case "OK":
        dispatch(clearResponseLogin());
        navigate("/login");
        break;
      case "ValidationCode":
        setModalConfig({ ...modalConfig, show: true });
        break;
    }
  }, [response.result]);

  const handleSubmitForm = (e) => {
    if (form.email && form.password) {
      dispatch(doLoginUser.trigger());
    } else {
      toast(t("global_messages.fields_required"), { type: "error" });
    }
  };
  const handleVisibility = () => {
    setPassVisibility(!passVisibility);
  };

  const isValidForm = () => {
    return form?.email && form?.password;
  };

  const handleChangeInput = (propertyValue, propertyName) => {
    dispatch(changeFormLogin({ value: propertyValue, name: propertyName }));
  };

  const handleModalClose = () => {
    setModalConfig({ ...modalConfig, show: false });
  };

  const handleModalOk = () => {
    switch (modalConfig.type) {
      case "code":
        dispatch(tryVerificationCode.trigger());
        break;
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-4 col-md-offset-4">
          <div className="panel panel-border panel-default mt-30 mb-30 ">
            <div className="panel-header panel-heading">
              <div className="row justify-content-center">
                <div className="col-auto">
                  <img
                    src={`${REACT_APP_ASSETS_URL}/assets/images/logo-blanco.png`}
                    width="250px"
                  />
                </div>
              </div>
            </div>
            <div className="panel-body">
              <div className="form-group">
                <label>
                  <span className="text-danger">*</span>
                  {t("login_page.email")}
                </label>
                <Input
                  maxLength="255"
                  required
                  className="form-control"
                  type="email"
                  label={t("login_page.email")}
                  variant="primary"
                  value={form?.email}
                  onChange={(e) => handleChangeInput(e.target.value, "email")}
                />
              </div>
              <div className="form-group">
                <label>
                  <span className="text-danger">*</span>
                  {t("login_page.password")}
                </label>
                <Input
                  className="form-control"
                  required
                  maxLength="255"
                  label={t("login_page.password")}
                  variant="primary"
                  value={form?.password}
                  type={passVisibility ? "password" : "text"}
                  onChange={(e) =>
                    handleChangeInput(e.target.value, "password")
                  }
                />
              </div>
              <button
                style={{ margin: "0.5em 0" }}
                className="btn btn-primary w-100"
                disabled={!isValidForm()}
                onClick={handleSubmitForm}
              >
                {t("login_page.login")}
              </button>
              <p>
                <NavLink to="/forgotPassword">
                  {" "}
                  {t("login_page.forgot_password")}
                </NavLink>
              </p>

              <p>
                {t("login_page.dont_have_an_account_yet")}
                <NavLink to="/signIn"> {t("login_page.sign_up")}</NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
      <CommonModal
        config={modalConfig}
        handleModalClose={handleModalClose}
        handleModalOk={handleModalOk}
        isOkDisabled={!form?.verifyCode}
        backdrop="static"
        keyboard={false}
      >
        <div className="flex flex-column pt-2">
          <div className="w-full">
            Ingrese el codigo de verificación enviado al correo electronico. Si
            no te ha llegado, revisa en la bandeja de spam o dale click en
            Reenviar. <p className="text-center font-bold">{form?.email}</p>
          </div>
          <div className="flex flex-row justify-center p-2">
            <div>
              <label className="px-2">Código</label>
            </div>
            <div>
              <input
                className="form-control w-80 font-bold  text-lg"
                type="text"
                name="verifyCode"
                value={form?.verifyCode}
                onChange={(e) =>
                  handleChangeInput(e.target.value, "verifyCode")
                }
              />
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
}

export default Login;
