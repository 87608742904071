export const initialState = {
  loading: false,
  form: {
    id:"",
    isNew:false,
    fullName: "",
    documentType: "",
    document: "",
    phone: "",
    cellPhone: "",
    countryId: "",
    departmentId: "",
    cityId: "",
    status: "",
    address: "",
    userName:""
  },
  response: { message: null, error: null },
};
