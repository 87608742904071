import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { getAssociatedAuctionPending } from "../../../redux/modules/associated-auctions/actions";
import { getAssociatedAuctionsPendingLoaded } from "../../../redux/modules/associated-auctions/selectors";
import {
  changeStatusAuction,
  clearResponseAuctions,
  deleteAuction,
  getAuctions,
  loadFormAuction,
  setAuctionsFilter,
  setModalAuctionsConfig,
  updateAuction,
} from "../../../redux/modules/auctions/actions";
import {
  getAuctionsList,
  getModalAuctionsConfig,
  getResponseAuctions,
} from "../../../redux/modules/auctions/selectors";
import { AdditionalButton } from "../../ui/AdditionalButton";
import { CountBadge } from "../../ui/CommonBadges";
import CommonModal from "../../ui/CommonModal";
import CommonPanel from "../../ui/CommonPanel";
import FilterBar from "../../ui/FilterBar";
import SearchBar from "../../ui/SearchBar";
import FormAuction from "./FormAuction";
import TableAuctions from "./TableAuctions";
import { toast } from "react-toastify";

function Auctions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const response = useSelector(getResponseAuctions);
  const auctions = useSelector(getAuctionsList);
  const modalConfig = useSelector(getModalAuctionsConfig);
  const associatedAuctionsPending = useSelector(
    getAssociatedAuctionsPendingLoaded
  );

  useEffect(() => {
    if (response.error || response.message) {
      toast(response.error ? response.error : response.message, {
        type: response.error ? "error" : "success",
      });
      dispatch(clearResponseAuctions());
    }
  }, [response]);
  useEffect(() => {
    dispatch(getAuctions.trigger());
    dispatch(getAssociatedAuctionPending.trigger());
  }, []);

  const handleModalClose = () => {
    dispatch(
      setModalAuctionsConfig({ ...modalConfig, show: false, data: null })
    );
  };

  const handleStatusFilter = (e) => {
    dispatch(setAuctionsFilter({ propertyName: "status", propertyValue: e }));
  };
  const handleStatusFilterType = (e) => {
    dispatch(
      setAuctionsFilter({ propertyName: "auctionType", propertyValue: e })
    );
  };

  const handleModalOk = () => {
    switch (modalConfig.type) {
      case "delete":
        dispatch(deleteAuction.trigger(modalConfig.data));
        dispatch(
          setModalAuctionsConfig({ ...modalConfig, show: false, data: null })
        );
        break;
      case "changeStatus":
        dispatch(changeStatusAuction.trigger(modalConfig.data));
        dispatch(
          setModalAuctionsConfig({ ...modalConfig, show: false, data: null })
        );
        break;
      case "edit":
        dispatch(updateAuction.trigger());
        dispatch(
          setModalAuctionsConfig({ ...modalConfig, show: false, data: null })
        );
        break;
    }
  };

  const handleClickRow = ({ data, item }) => {
    switch (data.action) {
      case "view":
        navigate(`/${item.auctionType==='Venta'?'sale':'auction'}/detail/${item.id}`);
        break;
      case "edit":
        dispatch(
          setModalAuctionsConfig({
            show: true,
            type: "form",
            size: "xl",
            message: "",
            data: { id: item.id },
          })
        );
        dispatch(loadFormAuction(item));
        break;
      case "delete":
        dispatch(
          setModalAuctionsConfig({
            show: true,
            type: data.action,
            title: "Eliminar Subasta",
            message: (
              <div className="w-full">
                <p className="text-center">
                  ¿Desea eliminar la subasta? <br></br>
                  <span style={{ fontWeight: 700 }}>{item.name}</span>
                </p>
              </div>
            ),
            data: { id: item.id },
          })
        );
        break;
      case "changeStatus":
        dispatch(
          setModalAuctionsConfig({
            show: true,
            data: { path: data.value, id: item.id },
            type: data.action,
            title: `${data.title} Subasta`,
            message: (
              <div className="w-full">
                <p className="text-center">
                  {`¿Desea ${data.title.toLowerCase()} la subasta?`}
                  <br></br>
                  <span style={{ fontWeight: 700 }}>{item.name}</span>
                </p>
              </div>
            ),
          })
        );
        break;
    }
  };

  const additionalButtons = (
    <div style={{ display: "flex", margin: "3px" }}>
      <AdditionalButton
        title="Agregar Subasta"
        linkTo="/auctions/form"
        iconClass="bi-plus"
      />
    </div>
  );

  const filterBarOptions = [
    { name: "Todos", value: "Todos" },
    { name: "Activo", value: "Activo" },
    { name: "Cerrada", value: "Cerrada" },
    { name: "Inactivo", value: "Inactivo" },
  ];
  const filterBarTypeOptions = [
    { name: "Todos", value: "Todos" },
    { name: "Venta", value: "Venta" },
    { name: "Subasta", value: "Subasta" },
  ];

  const handleSearch = (e) => {
    dispatch(setAuctionsFilter({ propertyName: "search", propertyValue: e }));
  };

  return (
    <CommonPanel
      title={"Lista de Subastas"}
      count={auctions.length}
      additionalButtons={additionalButtons}
      searchBar={<SearchBar onHandleSearch={handleSearch}></SearchBar>}
      filterBar={
        <>
          <FilterBar
            name="Tipo"
            options={filterBarTypeOptions}
            onHandleFilter={handleStatusFilterType}
          ></FilterBar>
          <FilterBar
            name="Estado"
            options={filterBarOptions}
            onHandleFilter={handleStatusFilter}
          ></FilterBar>
        </>
      }
    >
      <div className="row p-2">
        {associatedAuctionsPending.length > 0 && (
          <div className="flex flex-end">
            <NavLink to="/auctions/requests">
              Solicitudes de participación en Subastas{" "}
              <CountBadge count={associatedAuctionsPending.length}></CountBadge>
            </NavLink>
          </div>
        )}
      </div>
      <TableAuctions
        columns={[
          "Subasta",
          "Precio Minimo",
          "Precio Reserva",
          "Oferentes",
          "Ofertas",
          "Max Oferente",
          "Max Oferta",
          "Estado",
          "Opciones",
        ]}
        onHandleClickRow={handleClickRow}
        items={auctions}
      ></TableAuctions>
      <CommonModal
        config={modalConfig}
        handleModalClose={handleModalClose}
        handleModalOk={handleModalOk}
      >
        {modalConfig.type === "form" && <FormAuction />}
      </CommonModal>
    </CommonPanel>
  );
}

export default Auctions;
