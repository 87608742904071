import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
  changeStatusUserPending,
  clearResponseUsers,
  setModalUsersConfig,
  setUsersFilter,
} from "../../../redux/modules/users/actions";
import {
  getModalUsersConfig,
  getPendingUsersLoaded,
  getResponseUsers,
} from "../../../redux/modules/users/selectors";
import { StatusBadge } from "../../ui/CommonBadges";
import CommonModal from "../../ui/CommonModal";
import CommonOptionsRowTable from "../../ui/CommonOptionsRowTable";
import CommonPanel from "../../ui/CommonPanel";
import CommonTable from "../../ui/CommonTable";
import SearchBar from "../../ui/SearchBar";

const TablePendingUsers = ({ items }) => {
  const dispatch = useDispatch();
  const pendingUsers = useSelector(getPendingUsersLoaded);
  const response = useSelector(getResponseUsers);
  const modalConfig = useSelector(getModalUsersConfig);

  useEffect(() => {
    if (response.error || response.message) {
      toast(response.error ? response.error : response.message, {
        type: response.error ? "error" : "success",
      });
      dispatch(clearResponseUsers());
    }
  }, [response]);

  const handleModalClose = () => {
    dispatch(setModalUsersConfig({ ...modalConfig, show: false, data: null }));
  };

  const handleModalOk = () => {
    dispatch(changeStatusUserPending.trigger(modalConfig.data));
    dispatch(setModalUsersConfig({ ...modalConfig, show: false, data: null }));
  };

  const handleClickRow = ({ data, item }) => {
    dispatch(
      setModalUsersConfig({
        show: true,
        data: { path: data.action, id: item.id },
        type: data.action,
        title: `${data.title} Usuario`,
        message: (
          <div className="w-full">
            <p className="text-center">
              {`¿Desea ${data.title.toLowerCase()} el Usuario?`}
              <br></br>
              <span style={{ fontWeight: 700 }}>{item.email}</span>
            </p>
          </div>
        ),
      })
    );
  };

  const buildItems = (_items) => {
    return (
      _items &&
      _items.length > 0 &&
      _items.map((item) => (
        <tr key={item.id}>
          <td>{item.fullName}</td>
          <td>{item.userName}</td>
          <td>{item.email}</td>
          <td>{new Date(item.createdAt).toLocaleString()}</td>
          <td>
            <StatusBadge status={item.status} />
          </td>
          <td>
            <CommonOptionsRowTable
              key={item.id}
              item={item}
              buttonsType="secondary"
              onHandleClickRow={handleClickRow}
            ></CommonOptionsRowTable>
          </td>
        </tr>
      ))
    );
  };

  const handleSearch = (e) => {
    dispatch(setUsersFilter({ propertyName: "search", propertyValue: e }));
  };

  const startButtons = (
    <div style={{ display: "flex", margin: "5px 20px 0px 10px" }}>
      <NavLink to="/users">Atrás</NavLink>
    </div>
  );

  return (
    <div>
      <CommonPanel
        title={"Lista de Usuarios Pendientes por Aprobación"}
        count={buildItems(pendingUsers).length}
        startButtons={startButtons}
        searchBar={<SearchBar onHandleSearch={handleSearch}></SearchBar>}
      >
        <div className="w-full px-6 rounded">
          <CommonTable
            columns={[
              "Nombre / Razón",
              "Usuario",
              "Correo",
              "Fecha solicitud",
              "Estado",
              "Opciones",
            ]}
            handleClickRow={handleClickRow}
            items={buildItems(pendingUsers)}
            isSecondaryButton={true}
          ></CommonTable>
        </div>
        <CommonModal
          config={modalConfig}
          handleModalClose={handleModalClose}
          handleModalOk={handleModalOk}
        ></CommonModal>
      </CommonPanel>
    </div>
  );
};

export default TablePendingUsers;
