import produce from "immer";
import {
  changeStatusAssociatedAuction,
  getAssociatedAuctionPending,
  getAssociatedAuctions,
  uploadAssociatedAuctionFiles,
} from "./actions";
import { initialState } from "./states";
import * as types from "./types";

export const associatedAuctions = produce((draft, { type, payload }) => {
  switch (type) {
    case getAssociatedAuctions.TRIGGER:
      draft.loading = true;
      break;
    case getAssociatedAuctions.REQUEST:
      break;
    case getAssociatedAuctions.SUCCESS:
      draft.associatedAuctions = payload;
      break;
    case getAssociatedAuctions.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getAssociatedAuctions.FULFILL:
      draft.loading = false;
      break;
    case uploadAssociatedAuctionFiles.TRIGGER:
      draft.loading = true;
      break;
    case uploadAssociatedAuctionFiles.REQUEST:
      break;
    case uploadAssociatedAuctionFiles.SUCCESS:
      // draft.associatedAuctions = payload;
      break;
    case uploadAssociatedAuctionFiles.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case uploadAssociatedAuctionFiles.FULFILL:
      draft.loading = false;
      break;
    case types.CLEAR_RESPONSE_OFFERS:
      draft.response = initialState.response;
      break;
    case types.SET_OFFERS_FILTER:
      draft.filters[payload.propertyName] = payload.propertyValue;
      break;
    case types.SET_ASSOCIATED_AUCTION:
      if (payload.type === "status") {
        let associatedAuctionExist = draft.associatedAuctions.find(
          (x) => x.associatedAuctionId === payload.data.id
        );
        if (associatedAuctionExist) {
          associatedAuctionExist.requestStatus = payload.data.statusChanged;
        }
      }

      break;
    case getAssociatedAuctionPending.TRIGGER:
      draft.loading = true;
      break;
    case getAssociatedAuctionPending.REQUEST:
      break;
    case getAssociatedAuctionPending.SUCCESS:
      draft.associatedAuctionsPending = payload;
      break;
    case getAssociatedAuctionPending.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case getAssociatedAuctionPending.FULFILL:
      draft.loading = false;
      break;
    case changeStatusAssociatedAuction.TRIGGER:
      draft.loading = true;
      break;
    case changeStatusAssociatedAuction.REQUEST:
      break;
    case changeStatusAssociatedAuction.SUCCESS:
      draft.response.message = payload.message;
      draft.response.error = payload.error;
      break;
    case changeStatusAssociatedAuction.FAILURE:
      draft.response.message = null;
      draft.response.error = payload.error;
      break;
    case changeStatusAssociatedAuction.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);
