import React from "react";

export function ApproveIcon(props) {
  const classApproveIcon = {
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5px",
    ...props.style,
  };

  return (
    <svg {...props} viewBox="0 0 24 24">
      <path
        d="M18,7l-6.38,8.66a1,1,0,0,1-.68.4,1,1,0,0,1-.75-.21L6,12.5"
        style={classApproveIcon}
      />
      <circle cx="12" cy="12" r="11.5" style={classApproveIcon}></circle>
    </svg>
  );
}
