import * as type from './types';
import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";

export const getCountries = createRoutine(type.GET_COUNTRIES);
export const getDepartments = createRoutine(type.GET_DEPARTMENTS);
export const getDepartmentsByCountry = createRoutine(type.GET_DEPARTMENTS_BY_COUNTRY);
export const getCities = createRoutine(type.GET_CITIES);
export const getCitiesByDepartment = createRoutine(type.GET_CITIES_BY_DEPARTMENT);
export const clearResponseLocation = createAction(type.CLEAR_RESPONSE_LOCATION);
