import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { getToken } from "../auth/selectors";
import { getCurrentProfile, updateProfile } from "./actions";
import { getFormProfile } from "./selectors";

function* getProfileSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(getCurrentProfile.request());
    const response = yield call(
      callApiAxios,
      "GET",
      "offerers/getProfile",
      tokenAccess,
      {},
      {}
    );
    yield put(getCurrentProfile.success(response));
  } catch (e) {
    yield put(
      getCurrentProfile.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getCurrentProfile.fulfill());
  }
}

function* updateProfileSaga() {
  try {
    const tokenAccess = yield select(getToken);
    yield put(updateProfile.request());
    const form = yield select(getFormProfile);
    const body = {
      fullName: form.fullName,
      documentType: form.documentType,
      document: form.document,
      phone: form.phone,
      cellPhone: form.cellPhone,
      address: form.address,
      cityId: form.cityId,
      userName: form.userName,
      password: form.password,
    };
    const response = yield call(
      callApiAxios,
      "PUT",
      "offerers/updateProfile",
      tokenAccess,
      {},
      body
    );
    yield put(updateProfile.success(response));
  } catch (e) {
    yield put(
      updateProfile.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(updateProfile.fulfill());
  }
}

export default function* profileSaga() {
  yield takeEvery(getCurrentProfile.TRIGGER, getProfileSaga);
  yield takeEvery(updateProfile.TRIGGER, updateProfileSaga);
  yield takeEvery(updateProfile.SUCCESS, getProfileSaga);
}
