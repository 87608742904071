import produce from "immer";
import * as types from "./types";
import {
  getCitiesByDepartment,
  getCountries,
  getDepartmentsByCountry,
} from "./actions";
import { initialState } from "./states";

export const locations = produce((draft, { type, payload }) => {
  switch (type) {
    case getCountries.TRIGGER:
      draft.loading = true;
      draft.countries = [];
      break;
    case getDepartmentsByCountry.TRIGGER:
      draft.loading = true;
      draft.departments = [];
      draft.cities = [];
      break;
    case getCitiesByDepartment.TRIGGER:
      draft.loading = true;
      draft.cities = [];
      break;

      break;
    case getCountries.SUCCESS:
      draft.countries = payload;
      break;
    case getDepartmentsByCountry.SUCCESS:
      draft.departments = payload;
      break;
    case getCitiesByDepartment.SUCCESS:
      draft.cities = payload;
      break;
    case getCountries.FULFILL:
    case getDepartmentsByCountry.FULFILL:
    case getCitiesByDepartment.FULFILL:
      draft.loading = false;
      break;
    case types.CLEAR_RESPONSE_LOCATION:
      draft.response.message = null;
      draft.response.error = null;
      break;
  }
}, initialState);
