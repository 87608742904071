export const usersState = (state) => state.users;

export const getLoadingUsers = (state) => usersState(state).loading;

export const getUsersLoaded = (state) => usersState(state).users;

export const getResponseUsers = (state) => usersState(state).response;

export const getUsersList = (state) => {
  const users = usersState(state).users;
  const userType = 'admin';
  const status = usersState(state).filters.status;
  const search = usersState(state).filters.search;
  let itemsFiltered = users;
  if (status && status != "Todos") {
    itemsFiltered = itemsFiltered.filter((user) => user.status === status);
  }
  if (userType && userType != "Todos") {
    itemsFiltered = itemsFiltered.filter((user) => user.userType === userType);
  }
  if (search) {
    itemsFiltered = itemsFiltered.filter(
      (user) => user.userName.toUpperCase().indexOf(search.toUpperCase()) != -1
    );
  }

  return itemsFiltered;
};

export const getFormUsers = (state) => usersState(state).form;

export const getPendingUsersLoaded = (state) => {
  const search = usersState(state).filters.search;
  const pendingUsers = usersState(state).pendingUsers;
  let itemsFiltered = pendingUsers;
  if (search) {
    itemsFiltered = itemsFiltered.filter(
      (user) => user.userName.toUpperCase().indexOf(search.toUpperCase()) != -1 || user.fullName.toUpperCase().indexOf(search.toUpperCase()) != -1
    );
  }
  return itemsFiltered;
};

export const getModalUsersConfig = (state) => usersState(state).modal;
