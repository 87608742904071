import React from "react";
import { StatusBadge } from "../../ui/CommonBadges";
import CommonOptionsRowTable from "../../ui/CommonOptionsRowTable";
import CommonTable from "../../ui/CommonTable";

const TableUsers = ({ columns, items, onHandleClickRow }) => {
  const buildItems = (_items) => {
    return (
      _items &&
      _items.length > 0 &&
      _items.map((item) => (
        <tr key={item.id}>
          <td>{item.userType}</td>
          <td>{item.userName}</td>
          <td>{item.email}</td>
          <td>{item.description}</td>
          <td>
            <StatusBadge status={item.status} />
          </td>
          <td>
            <CommonOptionsRowTable
              key={item.id}
              item={item}
              onHandleClickRow={onHandleClickRow}
            ></CommonOptionsRowTable>
          </td>
        </tr>
      ))
    );
  };
  return (
    <div className="w-full px-6 rounded">
      <div className="flex flex-wrap">
        <CommonTable
          columns={columns}
          items={buildItems(items)}
          onHandleClickRow={onHandleClickRow}
        ></CommonTable>
      </div>
    </div>
  );
};

export default TableUsers;
