export const associatedAuctions = (state) => state.associatedAuctions;

export const getLoadingAssociatedAuctions = (state) =>
  associatedAuctions(state).loading;

export const getResponseAssociatedAuctions = (state) =>
  associatedAuctions(state).response;

export const getAssociatedAuctionsPendingLoaded = (state) => {
  const search = associatedAuctions(state).filters.search;
  const associatedAuctionsPending =
    associatedAuctions(state).associatedAuctionsPending;
  let itemsFiltered = associatedAuctionsPending;
  if (search) {
    itemsFiltered = itemsFiltered.filter(
      (auction) =>
        auction.auctionName.toUpperCase().indexOf(search.toUpperCase()) != -1 ||
        auction.offererName.toUpperCase().indexOf(search.toUpperCase()) != -1
    );
  }
  return itemsFiltered;
};

export const getAssociatedAuctionsLoaded = (state) => {
  const search = associatedAuctions(state).filters.search;
  const items = associatedAuctions(state).associatedAuctions;
  let itemsFiltered = items;
  if (search) {
    itemsFiltered = itemsFiltered.filter(
      (auction) =>
        auction.auctionName.toUpperCase().indexOf(search.toUpperCase()) != -1 ||
        auction.offererName.toUpperCase().indexOf(search.toUpperCase()) != -1
    );
  }
  return itemsFiltered;
};
